import { useState } from "react";
import { ReactComponent as NoImageUploaded } from "Assets/Icons/image-unavailable.svg";
import { ReactComponent as UploadPictureIcon } from "Assets/Icons/uploadPicture.svg";
import "./UploadPicture.scss";
import ClassNames from "Helpers/Common";
import { UploadButton } from "../UploadButton/UploadButton";

export const UploadPicture = ({
  id,
  Icon,
  label,
  className,
  onImageUpload,
}) => {
  const [imgUrl, setImgUrl] = useState("");
  const [uploadFileError, setUploadFileError] = useState(false);

  return (
    <div id={id} className={ClassNames("UploadPicture", "light", className)}>
      <label htmlFor="upload-input" className="UploadPicture_Label">
        {label}
      </label>
      <div
        className={ClassNames(
          "PhotoDropzone relative",
          !imgUrl && "PhotoDropzone-default"
        )}
      >
        <UploadButton
          id="upload-input"
          icon={<UploadPictureIcon className="UploadImageIcon" />}
          className="UploadImageBtn Button-iconOnly"
          setUploadFileError={setUploadFileError}
          onUpload={(photo) => {
            onImageUpload(photo);
            setImgUrl(photo);
          }}
        />
        {imgUrl && <img src={imgUrl} />}
        {!imgUrl && Icon && <Icon className="PhotoDropzoneIcon" />}
        {!imgUrl && !Icon && <NoImageUploaded className="PhotoDropzoneIcon" />}
      </div>
      {uploadFileError && (
        <div className="flex text-red-400 text-sm font-normal">
          Image can't be upload try again 5mb limit
        </div>
      )}
    </div>
  );
};
