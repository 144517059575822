import { useState } from "react";
import { ReactComponent as BackIcon } from "Assets/Icons/BackArrow.svg";
import { HeaderSteps } from "./HeaderSteps";
import { Button } from "Components/Common/Button/Button";
import { IntresetForm } from "./IntresetForm";
import { EventForm } from "./EventForm";
import { Settings } from "./Settings";
import {
  INTRESET_FORM,
  EVENT_FORM,
  SETTINGS_FORM,
  REORDER_FORM,
} from "./constants";
import { usePickemContext } from "Context/PickemCreation";
import "./CreatePickem.css";
import { Reorder } from "./Reorder";
import { useGlobalContext } from "Context/Global";
import ClassNames from "Helpers/Common";
import { PostPickem } from "Services/API/CreatePickem.service";
import { BASE_PICKEMS_PATH } from "Constants/View";

export const PickemCreation = () => {
  const { setActiveView } = useGlobalContext();
  const [currentForm, setCurrentForm] = useState(INTRESET_FORM);
  const { interest, event, settings, orderedItems } = usePickemContext();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  return (
    <div className="flex gap-4 h-full">
      <div className="flex flex-col gap-3 p-4 bg-white First_Coulnm">
        <span className="flex gap-3 items-center h-4">
          <BackIcon onClick={() => setActiveView(BASE_PICKEMS_PATH)} className="cursor-pointer"/> Create a Contest
        </span>
        <span className="text-gray-400">
          Contests created will be viewable under the Rankpage Pick’ems.
        </span>
      </div>
      <div className="flex flex-col  gap-3 bg-white Second_Coulnm">
        <div className="flex">
          <HeaderSteps setCurrentForm={setCurrentForm} currentForm={currentForm}/>
        </div>
        <div className="flex flex-col gap-3 p-4 Form_Height overflow-y-auto">
          {currentForm === INTRESET_FORM ? (
            <IntresetForm setIsDisabled={setIsDisabled} />
          ) : currentForm === EVENT_FORM ? (
            <EventForm setIsDisabled={setIsDisabled} />
          ) : currentForm === SETTINGS_FORM ? (
            <Settings setIsDisabled={setIsDisabled} />
          ) : (
            <Reorder setIsDisabled={setIsDisabled} />
          )}
        </div>
        <div className="flex justify-between border-t-2 border-gray-200  p-4 xl:p-6">
          <Button
            btnText="Back"
            className={"w-24 py-2 bg-white  border-gray-300"}
            isDisabled={false}
            isLoading={false}
            onClick={() => {
              if (currentForm === EVENT_FORM) {
                setCurrentForm(INTRESET_FORM);
              } else if (currentForm === SETTINGS_FORM) {
                setCurrentForm(EVENT_FORM);
              } else if (currentForm === REORDER_FORM) {
                setCurrentForm(SETTINGS_FORM);
              }
            }}
          />
          <Button
            btnText={currentForm !== REORDER_FORM ? "Continue" : "Publish"}
            className={ClassNames(
              "w-24 py-2",
              !isDisabled && "bg-blue-400 text-white"
            )}
            isDisabled={isDisabled}
            isLoading={isBtnLoading}
            onClick={() => {
              if (currentForm === INTRESET_FORM) {
                setCurrentForm(EVENT_FORM);
              } else if (currentForm === EVENT_FORM) {
                setCurrentForm(SETTINGS_FORM);
              } else if (currentForm === SETTINGS_FORM) {
                setCurrentForm(REORDER_FORM);
              } else if (currentForm === REORDER_FORM) {
                setIsBtnLoading(true);
                PostPickem({
                  title: settings.title,
                  category: interest.mainCategory.toUpperCase(),
                  sub_category: interest.subCategory.toUpperCase(),
                  event_id: event.id,
                  ordered_items: orderedItems.map((item) => item.id),
                  end_date: settings.endDate,
                  start_date: settings.startDate,
                  visibility: settings.visiblity.toUpperCase(),
                  participants_limit: settings.participantsLimit,
                  add_items: !!settings.canParticipantsAdd,
                  criteria: interest.selectedCriteria.toUpperCase(),
                  criteria_operator: interest.criteriaOperator.toUpperCase(),
                  admin_pickem: true,
                })
                  .then((res) => {
                    if (res) {
                      setIsBtnLoading(false);
                    }
                  })
                  .catch((err) => {
                    console.error("[PostPickem][API_ERROR] - ", err);
                    setIsBtnLoading(false);
                  });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
