export const Dividers = ({ text }) => {
  return (
    <div className="relative">
      <div
        className="absolute inset-0 flex items-center pr-80"
        aria-hidden="true"
      >
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-start">
        <span className="pr-3 bg-white text-md font-medium text-gray-900">
          {text}
        </span>
      </div>
    </div>
  );
};
