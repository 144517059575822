import React, { useEffect, useState } from "react";
import ClassNames from "Helpers/Common";
import Table from "Components/Common/Table/Table";
import { ViewDetails } from "./Common/ViewDetails";
import { trendingPickemsFormatter } from "./APIFormatter";
import { GetTrendingPickems } from "Services/API/Pickem.service";
import { EmptyData } from "Components/Common/EmptyData/EmptyData";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import "./Pickems.css";

const HEADINGS = [
  "Title",
  "Status",
  "Reposts",
  "Reactions",
  "Kudos Points",
  "",
];
const TABLE_CSS = "px-6 py-4 text-sm cursor-pointer";

export const TrendingPickems = () => {
  const [isSideView, setIsSideView] = useState(false);
  const [pickems, setPickems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(7);
  const [selectedPickem, setSelectedPickem] = useState({
    title: "",
    category: "",
    eventList: "",
    eventTitle: "",
    uploadedBy: "",
    subCategory: "",
    startingDate: "",
  });
  useEffect(() => {
    setIsLoading(true);
    GetTrendingPickems()
      .then((res) => {
        setPickems(trendingPickemsFormatter(res.data));
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("[GetTrendingPickems][API_ERROR] - ", err);
        setIsLoading(false);
      });
    return () => {
      setPickems([]);
    };
  }, [pageNumber]);
  return (
    <>
      <div
        className={`flex flex-col gap-4 ${
          isSideView ? "Table" : "Orignal_Table_Width"
        }`}
      >
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={
            pickems.length > 0 &&
            pickems.map((pickem, index) => (
              <tr key={index}>
                <td className={TABLE_CSS}>
                  <span className="font-bold">{pickem.title}</span>
                </td>
                <td className={TABLE_CSS}>
                  <div
                    className={ClassNames(
                      "flex items-center justify-center py-1 rounded-xl",
                      pickem.status === "Live"
                        ? "bg-green-300 text-green-800"
                        : "bg-red-300 text-red-800"
                    )}
                  >
                    {pickem.status}
                  </div>
                </td>
                <td className={TABLE_CSS}>
                  <div className="text-sm">{pickem.reposts}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div className="text-sm">{pickem.reactions}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div className="text-sm text-blue-dark">Edit</div>
                </td>
                <td className={TABLE_CSS}>
                  <span
                    onClick={() => {
                      setSelectedPickem({
                        title: pickem.title,
                        category: pickem.category,
                        eventList: pickem.eventList,
                        eventTitle: pickem.eventTitle,
                        uploadedBy: pickem.uploadedBy,
                        subCategory: pickem.subCategory,
                        startingDate: pickem.startingDate,
                      });
                      setIsSideView((x) => !x);
                    }}
                    className="cursor-pointer text-blue-dark"
                  >
                    View
                  </span>
                </td>
              </tr>
            ))
          }
        />
        {isLoading ? <Spinner /> : pickems.length < 0 && <EmptyData />}
        {!isLoading && pickems.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
      </div>
      {isSideView && (
         <ViewDetails
         setIsSideView={setIsSideView}
         picekmDetails={selectedPickem}
       />
      )}
    </>
  );
};
