import { forwardRef } from "react";
import { MentionsInput } from "react-mentions";
import mentionsCSSGenerator from "./MentionsCss";

export const WithMentions = forwardRef(
  ({ value, onEnter, styleGenerator, children, ...props }, ref) => {
    const style = styleGenerator
      ? styleGenerator("light")
      : mentionsCSSGenerator("light");

    return (
      <MentionsInput
        inputRef={ref}
        value={value}
        style={style}
        allowSuggestionsAboveCursor={true}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            if (onEnter) {
              onEnter?.(event);
              event.preventDefault();
            }
          }
        }}
        {...props}
      >
        {children}
      </MentionsInput>
    );
  }
);
