import { useEffect, useState } from "react";
import { Dropdown } from "Components/Common/Dropdown/Dropdown";
import { FilterUsers } from "Services/API/SearchFilter.service";
import { ReactComponent as XIcon } from "Assets/Icons/Cross.svg";
import { GetMainCategories } from "Services/API/Categories.service";
import { ReactComponent as ArrowDown } from "Assets/Icons/ChevronDown.svg";
import { ReactComponent as DownloadIcon } from "Assets/Icons/ArrowSmDown.svg";
import {
  AGE_FILTER,
  CONTENT_FILTER,
  FilterBtn,
  formattedCategories,
  GENDER_FILTER,
  formattedFilteredUsers,
} from "./Common";

export const UsersFilters = ({
  searchQuery,
  setUserData,
  setIsLoading,
  setHasPrevPage,
  setHasNextPage,
  pageNumber,
  contentFilter,
  setContentFilter,
}) => {
  const [categories, setCategories] = useState([]);
  const [ageFilter, setAgeFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const [categoriesFiter, setCategoriesFiter] = useState("");
  useEffect(() => {
    GetMainCategories()
      .then((res) => {
        setCategories([
          { id: "All", description: "All", image: "", title: "ALL" },
          ...formattedCategories(res?.data),
        ]);
      })
      .catch((err) => {
        console.error("[GetMainCategories][API_ERROR] =>", err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    FilterUsers(
      {
        content_type: "Users",
        partialText: searchQuery || "",
        gender: genderFilter || null,
        max_age: ageFilter?.split("-")?.[1]?.trim() || null,
        min_age: ageFilter?.split("-")?.[0]?.trim() || null,
        category_id: categoriesFiter ? [categoriesFiter] : null,
      },
      pageNumber,
    )
      .then((res) => {
        if (res) {
          setUserData([...formattedFilteredUsers(res?.data)]);
          setHasNextPage(res?.pagination?.hasNext);
          setHasPrevPage(res?.pagination?.hasPrevious);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error("[FilterUsers][API_ERROR] =>", err);
        setIsLoading(false);
      });
  }, [ageFilter, genderFilter, categoriesFiter, contentFilter, pageNumber]);
  console.log("Age filter is", ageFilter);
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center w-8/12 gap-3">
        <Dropdown
          id="content-filter"
          label="Content"
          Icon={ArrowDown}
          onChange={(value) => setContentFilter(value)}
        >
          <Dropdown.Option
            id={-1}
            value={"-1"}
            label={contentFilter}
            isDefaultOption={true}
          />
          {CONTENT_FILTER.map((pickemType, index) => (
            <Dropdown.Option
              key={index}
              id={index}
              value={pickemType}
              label={pickemType}
            />
          ))}
        </Dropdown>
        <Dropdown
          id="gender-filter"
          label="Gender"
          Icon={ArrowDown}
          onChange={(value) => {
            if (value === "All") {
              setGenderFilter("");
            } else setGenderFilter(value);
          }}
        >
          <Dropdown.Option
            id={-1}
            value={"-1"}
            label={genderFilter || "Gender"}
            isDefaultOption={true}
          />
          {GENDER_FILTER.map((gender, index) => (
            <Dropdown.Option
              key={index}
              id={index}
              value={gender}
              label={gender}
            />
          ))}
        </Dropdown>
        <Dropdown
          id="age-filter"
          label="Age"
          Icon={ArrowDown}
          onChange={(value) => {
            if (value === "All") {
              setAgeFilter("");
            } else if (value === "65+") {
              setAgeFilter("65-");
            } else setAgeFilter(value);
          }}
        >
          <Dropdown.Option
            id={-1}
            value={"-1"}
            label={ageFilter !== "" ?  ageFilter:"Age"}
            isDefaultOption={true}
          />
          {AGE_FILTER.map((gender, index) => (
            <Dropdown.Option
              key={index}
              id={index}
              value={gender}
              label={gender}
            />
          ))}
        </Dropdown>
        <Dropdown
          id="category"
          label="Category"
          Icon={ArrowDown}
          onChange={(value) => {
            if (value === "All") {
              setCategoriesFiter("");
            } else setCategoriesFiter(value);
          }}
        >
          <Dropdown.Option
            id={-1}
            value={"-1"}
            label={categoriesFiter || "Interests"}
            isDefaultOption={true}
          />
          {categories?.map((gender, index) => (
            <Dropdown.Option
              key={index}
              id={gender?.id}
              value={gender?.id}
              label={gender?.title}
            />
          ))}
        </Dropdown>
        <FilterBtn
          Icon={XIcon}
          text="Clear Filter"
          onClick={() => {
            setAgeFilter("");
            // setCategoriesFiter("Interests");
            setGenderFilter("");
          }}
        />
      </div>
      <FilterBtn Icon={DownloadIcon} text="Download" />
    </div>
  );
};
