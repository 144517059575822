import { useState, useEffect } from "react";
import { Accordian } from "Components/Common/Accordian/Accordian";
import { GetInterests } from "Services/API/Ranklists.service";
import { CustomSuspense } from "Components/Common/Suspense/CustomSuspense";
import { EmptyDataBar } from "../EmptyDataBar";

const resetSelectedStates = (interests) => {
  return interests.map((interest) => {
    return {
      ...interest,
      selected: false,
      subInterests: interest.subInterests.map((subInterest) => {
        return {
          ...subInterest,
          selected: false,
        };
      }),
    };
  });
};

export const SelectInterests = ({
  onSelectInterest,
  onChange,
  clearInitialSelection,
  ...props
}) => {
  const [interests, setInterests] = useState([]);
  const [selectedInterest, setSelectedInterest] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    GetInterests()
      .then((response) => {
        setLoading(false);
        setInterests(
          clearInitialSelection
            ? resetSelectedStates(response.interests)
            : response.interests
        );
      })
      .catch((error) => {
        console.error("[GetInterests][API_Error] - ", error);
        setInterests([]);
        setLoading(false);
      });
  }, []);
  const subInterests = selectedInterest?.subInterests || [];
  return (
    <>
      <CustomSuspense isLoading={loading}>
        {interests?.length > 0 ? (
          interests.map((interest, index) => {
            const propInterest = props.interests.find(
              ({ id }) => id === interest.id
            );
            return (
              interest && (
                <Accordian
                  key={index}
                  titleEl={
                    <>
                      <svg
                        className="AccordianTitle_Icon object-cover text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                      <span className="AccordianTitle_Title">
                        {interest.title}
                      </span>
                    </>
                  }
                  onClick={() => {
                    onSelectInterest(interest);
                    setSelectedInterest(interest);
                  }}
                >
                  <>
                    {subInterests?.length > 0 ? (
                      subInterests.map((subInterest) => {
                        let checked = false;
                        if (propInterest) {
                          const propSubInterests = propInterest.subInterests;
                          checked =
                            propSubInterests.find(
                              ({ id }) => id === subInterest.id
                            )?.selected || false;
                        }

                        return (
                          <div
                            key={subInterest.id}
                            className="AccordianListItem"
                          >
                            <div className="AccordianListItem_LS">
                              {subInterest.title}
                            </div>
                            <input
                              id="candidates"
                              aria-describedby="candidates-description"
                              name="candidates"
                              type="checkbox"
                              checked={checked}
                              onClick={({ target: { checked } }) =>
                                onChange({
                                  mainInterest: selectedInterest,
                                  subInterest,
                                  checked,
                                })
                              }
                              className="AccordianListItem_Control focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex items-center justify-left h-full">
                        <span>No record found</span>
                      </div>
                    )}
                  </>
                </Accordian>
              )
            );
          })
        ) : (
          <EmptyDataBar>
            <span>No interests found</span>
          </EmptyDataBar>
        )}
      </CustomSuspense>
    </>
  );
};
