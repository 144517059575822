import { RankItemsMainView } from "Components/RankItems/RankItemsMainView";
import { AppLayout } from "Layouts/AppLayout/AppLayout";

export const RankItems = () => {
  return (
    <AppLayout>
      <RankItemsMainView />
    </AppLayout>
  );
};
