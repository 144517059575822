import { useState } from "react";
import ClassNames from "Helpers/Common";
import { ReactComponent as MinusIcon } from "Assets/Icons/minus.svg";
import { ReactComponent as PlusIcon } from "Assets/Icons/plus.svg";
import "./Accordian.scss";

export const Accordian = ({
  titleEl,
  CollapseIcon,
  CloseIcon,
  children,
  className,
  onClick,
  onOpen,
  onClose,
}) => {
  const themes = "light";
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div
      className={ClassNames(
        "Accordian",
        isCollapsed && "CollapsedAccordian",
        themes,
        className
      )}
    >
      <div
        onClick={() => {
          if (!isCollapsed) {
            setIsCollapsed(true);
            onOpen?.();
          } else {
            setIsCollapsed(false);
            onClose?.();
          }
          onClick?.();
        }}
        className="Accordian_Heading"
      >
        <div className="AccordianHeading_LeftSection">{titleEl}</div>
        <span className="AccordianHeading_ControlIcon">
          {isCollapsed ? (
            CloseIcon ? (
              <CloseIcon />
            ) : (
              <MinusIcon />
            )
          ) : CollapseIcon ? (
            <CollapseIcon />
          ) : (
            <PlusIcon />
          )}
        </span>
      </div>
      <div className="Accordian_List">{children}</div>
    </div>
  );
};
