import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for creating an admin
 * @param {Object} payload user data to make an admin
 * Payload_Example :{
 * "first_name": "The Street",
	"date_of_birth": "2000-01-01",
	"gender": "Other",
	"address": "",
	"email": "thestreet@rankpage.com",
	"username": "theStreet",
	"device": "Web",
	"role": "Rankpage_Expert",
	"password": "12345678"
 * }
 * @returns 
 */
export const AdminCreation = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}admin/admin-register-admin`, payload);
  console.log(`[AdminCreation][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for listing all admins
 * @param {Number} pageNo current page number
 * @param {Number} perPage no of records to retrieve
 * @returns
 */
export const ListingAdmins = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/list/all?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[ListingAdmins][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for deleting an admin
 * @param {String} admin_Id
 * @returns
 */
export const DeleteAdmin = async (admin_Id) => {
  const {
    data: { result },
  } = await Axios.delete(`${API_BASE}admin/${admin_Id}`);
  console.log(`[DeleteAdmin][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for updating the user profile information
 * @param {Object} payload object to update the iser information
 * @returns
 */
export const UpdateAdmin = async (admin_Id,payload) => {
  const {
    data: { result },
  } = await Axios.patch(`${API_BASE}admin/edit/${admin_Id}`, payload);
  console.log(`[UpdateAdmin][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting an admin info by it's id
 * @param {String} admin_Id
 * @returns
 */
export const GetAdminById = async (admin_Id) => {
  const {
    data: { result },
  } = await Axios.get(`${API_BASE}user/account/user/${admin_Id}`);
  console.log(`[GetAdminById][API_RESPONSE]`, result);
  return result;
};
