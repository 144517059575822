import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

export const UploadImage = async (img) => {
    const {
      data: { result },
    } = await Axios.post(
      `${API_BASE}image/upload`,img
    );
    console.log(`[UploadImage][API_RESPONSE]`, result);
    return result;
  };