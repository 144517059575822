import { useEffect, useState } from "react";
import { ReactComponent as DashboardIcon1 } from "Assets/Icons/Dashboard1.svg";
import { ReactComponent as DashboardIcon2 } from "Assets/Icons/Dashboard2.svg";
import { ReactComponent as DashboardIcon3 } from "Assets/Icons/Dashboard3.svg";
import { ReactComponent as DashboardIcon4 } from "Assets/Icons/Group.svg";
import { ReactComponent as DashboardIcon5 } from "Assets/Icons/Polls.svg";

import { PickemsGraph } from "./PickemsGraph";
import { GenderGraph } from "./GenderGraph";
import { AgeGraph } from "./AgeGraph";
import { HEADING } from "Constants/Css";
import { GetAdminDashboard } from "Services/API/Dashboard.service";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import "./Dashboard.css";

const DashboardApiFormatter = (dashboardData) => {
	const genderStatsFormatter = (genderData) => {
		return Object.entries(genderData).map((entry) => {
			return {
				name: entry[0],
				value: entry[1],
			};
		});
	};
	const ageStatsFormatter = (ageData) => {
		return ageData.map((entry) => {
			return {
				name: entry.age_category,
				value: entry.count,
			};
		});
	};
	const categoryStatsFormatter = (pickemData) => {
		return Object.entries(pickemData).map((entry) => {
			return {
				name: entry[0],
				completed: entry[1],
			};
		});
	};

	return {
		genderStats: genderStatsFormatter(dashboardData.userGenderStats[0]),
		ageStats: ageStatsFormatter(dashboardData.userAgeStats),
		pickemStats: categoryStatsFormatter(dashboardData.pickemCategoryStats[0]),
		rankpageStats: categoryStatsFormatter(
			dashboardData.rankpageCategoryStats[0],
		),
		totalStats: {
			users: dashboardData.activeUsers || "",
			rankpages: dashboardData.totalRankpages || "",
			pickems: dashboardData.totalPickems || "",
			polls: dashboardData.totalPolls || "",
			groups: dashboardData.totalGroups || "",
		},
	};
};
export const Dashboard = () => {
	const [dashBoardStats, setDashboardStats] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		setIsLoading(true);
		GetAdminDashboard()
			.then((res) => {
				setDashboardStats(DashboardApiFormatter(res));
				setIsLoading(false);
			})
			.catch((err) => {
				console.error("[GetAdminDashboard][API_ERROR] - ", err);
				setIsLoading(false);
			});
	}, []);
	return (
		<div className="flex flex-col gap-6">
			<div className={HEADING}>Dashboard</div>
			{isLoading && <Spinner />}

			{!isLoading && Object.keys(dashBoardStats).length !== 0 && (
				<div className="flex  Dashboard_Wrapper">
					<div className="Dashboard_First_col">
						<Statistics stats={dashBoardStats?.totalStats} />
						<PickemsGraph pickemStats={dashBoardStats?.pickemStats} rankpageStats={dashBoardStats?.rankpageStats}/>
					</div>
					<div className=" flex flex-col gap-3 pl-10 pr-10">
						<GenderGraph genderStats={dashBoardStats?.genderStats} />
						<AgeGraph ageStats={dashBoardStats?.ageStats} />
						<GenderGraph genderStats={dashBoardStats?.genderStats} />
					</div>
				</div>
			)}
		</div>
	);
};

const Statistics = ({ stats }) => {
	const { users, rankpages, pickems, groups, polls } = stats;
	return (
		<div className="bg-white rounded-md h-48">
			<div className="flex flex-col gap-3 2xl:gap-6 p-4 2xl:p-8">
				<div className="text-sm font-medium">Today’s Statistics</div>
				<div className="flex items-center xl:gap-5 2xl:gap-6">
					<span className="flex gap-4">
						<DashboardIcon1 className="text-blue-dark" />
						<span className="flex-col">
							Rankpages<div>{rankpages ?? ""}</div>
						</span>
					</span>
					<span className="flex gap-4">
						<DashboardIcon2 className="text-blue-dark" />
						<span className="flex-col">
							Pick’ems<div>{pickems ?? ""}</div>
						</span>
					</span>
					<span className="flex gap-4 w-52">
						<DashboardIcon3 className="text-blue-dark" />
						<span className="flex-col">
							Registered Users<div>{users ?? ""}</div>
						</span>
					</span>
					<span className="flex gap-4">
						<DashboardIcon4 className="text-blue-dark h-12 w-12" />
						<span className="flex-col">
							Groups<div>{groups ?? ""}</div>
						</span>
					</span>
					<span className="flex gap-4">
						<DashboardIcon5 className="text-blue-dark h-12 w-12" />
						<span className="flex-col">
							Polls<div>{polls ?? ""}</div>
						</span>
					</span>
				</div>
			</div>
		</div>
	);
};
