import { AnnouncementCreation } from "Components/Announcements/AnnouncementCreation";
import { AppLayout } from "Layouts/AppLayout/AppLayout";

export const CreateAnnouncement = () => {
  return (
    <AppLayout>
      <AnnouncementCreation />
    </AppLayout>
  );
};
