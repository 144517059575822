import { AppLayout } from "Layouts/AppLayout/AppLayout";
import { PickemsMain } from "Components/ViewPickems/PickemsMain";

export const ViewPickems = () => {
  return (
    <AppLayout>
      <PickemsMain />
    </AppLayout>
  );
};
