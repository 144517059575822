import "./Table.css";
export default function Table({ heading, body }) {
  return (
    <div
      className={` flex-col bg-white align-middle inline-block min-w-full shadow overflow-hidden border border-coolGray sm:rounded-lg ${
        body.length > 0 ? "Table_Wrapper overflow-x-auto overflow-y-auto" : ""
      }`}
    >
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>{heading}</tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">{body}</tbody>
      </table>
    </div>
  );
}
