import { createContext, useReducer, useContext } from "react";

const initialPickemState = {
  interest: {
    mainCategory: "",
    subCategory: "",
    selectedCriteria: "",
    criteriaOperator: "",
  },
  settings: {
    title: "",
    visiblity: "",
    participantsLimit: "",
    canParticipantsAdd: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
  },
  ordereditems: [],
  event: {
    id: "",
    startDate: "",
    startTime: "",
  },
};

const SET_INTERESTS = "interest";
const SET_EVENT = "event";
const SET_SETTINGS = "settings";
const SET_ITEMS = "ordereditems";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_INTERESTS:
      return { ...state, intreset: payload };
    case SET_EVENT:
      return { ...state, event: payload };
    case SET_SETTINGS:
      return { ...state, settings: payload };
    case SET_ITEMS:
      return { ...state, orderedItems: payload };
    default:
      return state;
  }
};

const AppContext = createContext();
const PickemFormCTX = ({ children }) => {
  const [picekmState, setPickemState] = useReducer(reducer, initialPickemState);

  const setValToProviderCxt = (type, payload) => {
    setPickemState({ type, payload });
  };

  const setInterest = (intreset) =>
    setValToProviderCxt(SET_INTERESTS, intreset);
  const setSettings = (settings) => setValToProviderCxt(SET_SETTINGS, settings);
  const setEvent = (event) => setValToProviderCxt(SET_EVENT, event);
  const setOrderedItems = (orderedItems) =>
    setValToProviderCxt(SET_ITEMS, orderedItems);
  return (
    <AppContext.Provider
      value={{
        interest: picekmState.intreset,
        settings: picekmState.settings,
        setInterest,
        setSettings,
        event: picekmState.event,
        setEvent,
        orderedItems: picekmState.orderedItems,
        setOrderedItems,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const usePickemContext = () => {
  return useContext(AppContext);
};
export { AppContext, PickemFormCTX };
