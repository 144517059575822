import React from "react";
import { ReactComponent as SadFace } from "Assets/Icons/SadEmoji.svg";

export const EmptyData = () => {
  return (
    <div className="Spinner flex flex-col items-center justify-center">
      <SadFace />
      <div>Can't retrieve the data at the moment Please try again later</div>
    </div>
  );
};
