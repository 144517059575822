import Axios from "axios";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for sending a login request
 * @param {Object} payload User Credentails
 * Payload Example {
 *  "email": "xyz@gmail.com",
    "password": "sd5545sd4f5",
     "device":"Web"
 * }
 * @returns an authcode for verification
 */
export const SendLogInReq = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}user/auth/login`, payload);
  console.log(`[SendLogInReq][API_RESPONSE]`, result);
  return result;
};

/**
 * @description  An API for sending a forgot-password request
 * @param {Object} payload User email address
 * @returns an object containing the otp and a reset token
 */
export const ForgotPasswordReq = async (payload) => {
  const {
    data: { result },
  } = await Axios.put(`${API_BASE}user/auth/password/forgot`, payload);
  console.log(`[ForgotPassword][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for resetting the password
 * @param {String} resetToken 
 * @param {Object} payload User new password
 * @returns verification of the reset password
 */
export const PasswordResetReq = async (resetToken,payload) => {
  const {
    data: { result },
  } = await Axios.put(`${API_BASE}user/auth/password/reset?reset_token=${resetToken}`, payload);
  console.log(`[PasswordResetReq][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting the new  token
 * @param {Object} payload  the refresh token
 * Payload Example {
    "refreshToken": "fdlskfklamfkmdakmngfjakngjafknvkmacjcanfjdnakodfmajnsfkmasgjnuinarjkfnkdnfjgnako"
 * }
 * @returns
 */
export const RefreshTokenReq = async (refreshToken) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}user/auth/token/refresh${refreshToken}`);
  return result;
};
