import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

export const TagOtherUsers = async () => {
  // TODO: Update this api after the backend is updated
  const {
    data: { result },
  } = await Axios.get(`${API_BASE}user/connection/followings`);
  console.log(`[TagOtherUsers][API_RESPONSE]`, result);
  return result;
};
