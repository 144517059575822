import { CreatePoll } from "Components/Widget/CreatePoll/CreatePoll";
import { AppLayout } from "Layouts/AppLayout/AppLayout";

export const CreateWidget = () => {
  return (
    <AppLayout>
      <CreatePoll />
    </AppLayout>
  );
};
