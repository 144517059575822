import { useEffect, useState } from "react";
import { UsersTable } from "./UsersTable";
import { useParams } from "react-router-dom";
import { UsersFilters } from "./UsersFilters";
import { RankpageTable } from "./RankpageTable";
import { formattedFilteredUsers } from "./Common";
import { RankpageFilters } from "./RankpageFilters";
import { BaseSearch } from "Services/API/SearchFilter.service";


export const SearchMainView = () => {
  const { query } = useParams();
  const [contentFilter, setContentFilter] = useState("User Profiles");
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (query) {
      setIsLoading(true);
      BaseSearch(query, pageNumber)
        .then((res) => {
          setUserData([...formattedFilteredUsers(res?.data)]);
          setHasNextPage(res?.pagination?.hasNext);
          setHasPrevPage(res?.pagination?.hasPrevious);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("[BaseSearch][API_ERROR]", err);
          setIsLoading(false);
        });
    }
  }, [query]);
  
  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center text-lg font-medium">Explore</div>
      {contentFilter === "User Profiles" ? (
        <>
          <UsersFilters
            setUserData={setUserData}
            setIsLoading={setIsLoading}
            searchQuery={query}
            setHasNextPage={setHasNextPage}
            setHasPrevPage={setHasPrevPage}
            pageNumber={pageNumber}
            setContentFilter={setContentFilter}
            contentFilter={contentFilter}
          />
          <UsersTable
            usersData={userData}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPrevPage}
            isLoading={isLoading}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </>
      ) : (
        <>
          <RankpageFilters
            setUserData={setUserData}
            setIsLoading={setIsLoading}
            searchQuery={query}
            setHasNextPage={setHasNextPage}
            setHasPrevPage={setHasPrevPage}
            pageNumber={pageNumber}
            setContentFilter={setContentFilter}
            contentFilter={contentFilter}
          />
          <RankpageTable
            usersData={userData}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPrevPage}
            isLoading={isLoading}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </>
      )}
    </div>
  );
};
