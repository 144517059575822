import { ReactComponent as XIcon } from "Assets/Icons/Cross.svg";
import { Button } from "Components/Common/Button/Button";
import ModalBasic from "Components/Common/ModalBasic";

export const ConfirmationModal = ({
  isOpen,
  onClose,
  children,
  isBtnLoading = false,
  onBtnClick,
  btnText ="Delete",
  heading = "Delete Rankpage",
}) => {
  return (
    <ModalBasic
      open={isOpen}
      setOpen={onClose}
      className="TheAddInterestsModal"
    >
      <div className="ModalHeading">
        <XIcon
          className="h-6 w-6 dark:text-gray-400"
          aria-hidden="true"
          onClick={() => onClose?.()}
        />
        <span className="w-full flex justify-center">{heading}</span>
      </div>
      <div className="py-2">{children}</div>
      <div className="flex w-full justify-between">
        <Button
          btnText="Cancel"
          onClick={() => onClose?.()}
          className="bg-white border border-gray-200 px-14 py-2 rounded-md text-black text-base font-medium"
          isDisabled={false}
        />
        <Button
          btnText={btnText}
          isLoading={isBtnLoading}
          onClick={() => onBtnClick?.()}
          className="bg-red-500 px-14 py-2 rounded-md text-white text-base font-medium"
          isDisabled={false}
        />
      </div>
    </ModalBasic>
  );
};
