const DARK = 'dark';
const TOP = 8;
const LEFT = 16;
const FONT_SIZE = 16;

export const mentionsCSSGenerator = (theme) => {
  return {
    control: {
      fontSize: FONT_SIZE,
      fontWeight: 'normal',
      borderRadius: '8px',
      borderWidth: theme === DARK ? '0px' : '1px',
    },
    '&multiLine': {
      control: {
        fontFamily: 'Roboto',
        minHeight: 120,
        width: "100%",
        backgroundColor: theme === DARK ? '': 'white',
        paddingTop: TOP+'px',
        paddingLeft: LEFT+'px',
      },
      /**
       * highlighter: The div that contains the formatted <div> with <spans>.
       * Each span contains a single word which is either styled or defaulted
       * Words with have mentions are in styled spans
       * Other Words are unstyled spans
       */
      highlighter: {
        display: 'inline-block',
        position: 'absolute',
        left: LEFT,
        top: TOP,
        zIndex: 1,
        color: '#0EA5E9',
        fontSize: FONT_SIZE
      },
      input: {
        left: 16,
        top: 8,
        width: `calc(100% - ${LEFT}px)`,
        height: `calc(100% - ${TOP}px)`,
        border: '1px solid transparent',
        outline: 'none',
        color: theme === DARK ? '#FFFFFF' : '#374151',
        boxSizing: "unset"
      },
    },
    '&singleLine': {
      control: {
        fontFamily: 'Roboto',
        minHeight: 40,
      },
      highlighter: {
        padding: 9,
        border: '1px solid transparent',
      },
      input: {
        padding: 9,
        border: '1px solid blue',
      },
    },
    suggestions: {
      top: "-10.5rem",
      width: "300px",
      height:'150px',
      maxHeight: '150px',
      padding: "1rem 0.7rem",
      backgroundColor: theme === DARK ? "" : "white",
      border: "2px solid #D1D5DB",
      borderRadius: "8px",
      boxSizing: "border-box",
      boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
      overflow: "auto",

      list: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: 'transparent'
      },
      item: {
        width: "100%",
        padding: "5px 10px",
        color: theme === DARK ? '#FFFFFF' : '#374151',
        backgroundColor: 'transparent',
        fontSize: 20,
        fontFamily:'Roboto',
        "&focused": {
          backgroundColor: "#FFFFFF"
        }
      },
    }
  }
};
export default mentionsCSSGenerator;