export const TableHeading = ({TableHeader}) => {
    return TableHeader.map((head, index) => (
      <th
        key={index}
        scope="col"
        className="text-gray-raven px-6 py-3 text-left text-xs font-medium"
      >
        {head}
      </th>
    ));
  };