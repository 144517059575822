import { useEffect, useState } from "react";
import ClassNames from "Helpers/Common";
import { rankpagePickems } from "../MockData";
import Table from "Components/Common/Table/Table";
import { ViewDetails } from "../Common/ViewDetails";
import { Button } from "Components/Common/Button/Button";
import { rankpagePickemsFormatter } from "../APIFormatter";
import { GetRankpagePickem } from "Services/API/Pickem.service";
import { EmptyData } from "Components/Common/EmptyData/EmptyData";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { InputField } from "Components/Common/InputField/InputField";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import { ReactComponent as DefaultImage } from "Assets/Icons/Profile.svg";
import "../Pickems.css";

const HEADINGS = ["Title", "Status", "Leaderboard", "Kudos Points", "Details"];
const TABLE_CSS = "px-6 py-4 text-sm font-medium cursor-pointer";
const VIEW_MODES = {
  EDIT_MODE: "editMode",
  VIEW_MODE: "viewMode",
  DETAILS_MODE: "detailsMode",
};
const SELECTED_PICKEM_INIT_STATE = {
  title: "",
  category: "",
  eventList: "",
  eventTitle: "",
  uploadedBy: "",
  subCategory: "",
  startingDate: "",
};

export const RankpagePickems = ({ setCurrentTableView, view }) => {
  const [isSideView, setIsSideView] = useState(false);
  const [pickems, setPickems] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewMode, setViewMode] = useState(VIEW_MODES.VIEW_MODE);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(7);
  const [selectedPickem, setSelectedPickem] = useState(
    SELECTED_PICKEM_INIT_STATE
  );

  useEffect(() => {
    setIsLoading(true);
    GetRankpagePickem(pageNumber, perPage)
      .then((res) => {
        setPickems(rankpagePickemsFormatter(res.data));
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("[GetRankpagePickem][API_ERROR] - ", err);
        setIsLoading(false);
      });
    return () => {
      setPickems([]);
    };
  }, [pageNumber]);
  
  const LoadingData = () => {
    if (isLoading) {
      return <Spinner />;
    } else if (pickems.length < 0) {
      return <EmptyData />;
    }
  };
  return (
    <>
      <div
        className={`flex flex-col gap-4 ${
          isSideView ? "Table" : "Orignal_Table_Width"
        }`}
      >
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={
            pickems.length > 0 &&
            pickems.map((pickem, index) => (
              <tr key={index}>
                <td className={TABLE_CSS}>
                  <span>{pickem.title}</span>
                </td>
                <td className={TABLE_CSS}>
                  <div
                    className={ClassNames(
                      "flex items-center justify-center py-1 rounded-xl",
                      pickem.status === "Live"
                        ? "bg-green-300 text-green-800"
                        : "bg-red-300 text-red-800"
                    )}
                  >
                    {pickem.status}
                  </div>
                </td>
                <td className={TABLE_CSS}>
                  <div
                    onClick={() => {
                      if (pickem.status !== "Live") {
                        setViewMode(VIEW_MODES.VIEW_MODE);
                        setIsSideView((prevState) => !prevState);
                      }
                    }}
                    className={`${
                      pickem.status !== "Live"
                        ? "text-blue-400"
                        : "text-gray-400 cursor-default"
                    }`}
                  >
                    View
                  </div>
                </td>
                <td className={TABLE_CSS}>
                  <div
                    className={`${
                      pickem.status === "Live"
                        ? "text-blue-400"
                        : "text-gray-400 cursor-default"
                    }`}
                    onClick={() => {
                      if (pickem.status === "Live") {
                        setViewMode(VIEW_MODES.EDIT_MODE);
                        setIsSideView((prevState) => !prevState);
                      }
                    }}
                  >
                    Edit
                  </div>
                </td>
                <td className={TABLE_CSS}>
                  <span
                    onClick={() => {
                      setSelectedPickem({
                            title: pickem.title,
                            category: pickem.category,  
                            eventList: pickem.eventList,
                            eventTitle: pickem.eventTitle,
                            uploadedBy: pickem.uploadedBy,
                            subCategory: pickem.subCategory,
                            startingDate: pickem.startingDate,
                          });
                          setIsSideView(true);
                          setViewMode(VIEW_MODES.DETAILS_MODE);
                    }}
                    className="cursor-pointer text-blue-dark"
                  >
                    View
                  </span>
                </td>
              </tr>
            ))
          }
        />
        {}
        {LoadingData()}
        {!isLoading && pickems.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
      </div>
      {isSideView && (
        <SideView
          showProfileImage={false}
          setIsSideView={setIsSideView}
          heading={selectedPickem.title}
          subHeading={
            <span className="text-blue-400">
              {viewMode === VIEW_MODES.DETAILS_MODE && selectedPickem.category}
            </span>
          }
        >
          {viewMode === VIEW_MODES.VIEW_MODE ? (
            <RankLists setCurrentTableView={setCurrentTableView} view={view} />
          ) : viewMode === VIEW_MODES.EDIT_MODE ? (
            <KudosPointsMultipler
              isDisabled={isDisabled}
              isLoading={isLoading}
            />
          ) : viewMode === VIEW_MODES.DETAILS_MODE? (
            <ViewDetails
              setIsSideView={setIsSideView}
              picekmDetails={selectedPickem}
              // setSelectedPickem={setSelectedPickem}
            />
          ):""}
        </SideView>
      )}
    </>
  );
};

const RankLists = ({ setCurrentTableView, view }) => {
  return (
    <div className="flex flex-col justify gap-4 pt-4">
      {rankpagePickems.map(
        (user, index) =>
          index < 5 && (
            <div
              key={index}
              className="flex justify-between items-center py-4 px-2 rounded-lg bg-white shadow-lg border border-gray-200"
            >
              <div className="flex  gap-4 items-center ">
                <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                  {user.img ? <img src={user.img} /> : <DefaultImage />}
                </span>
                <div className="flex flex-col">
                  <span className="text-sm">
                    {user.name}{" "}
                    <span className="text-blue-400">{user.rank}</span>
                  </span>{" "}
                  <span>{user.position}</span>
                </div>
              </div>
              <span
                className={`${index === 0 ? "flex flex-col" : "flex gap-2"}`}
              >
                Points <span>{user.points}</span>
              </span>
            </div>
          )
      )}
      <span
        className="flex justify-center text-blue-400 cursor-pointer"
        onClick={() => setCurrentTableView(view)}
      >
        View More
      </span>
    </div>
  );
};

const KudosPointsMultipler = (isDisabled, isLoading) => {
  return (
    <div className="flex flex-col justify gap-3 pt-4">
      <div className="text-gray-400">
        To increase the amount of Kudos Points for this Pick’em, enter a digit
        greater than 1. The value entered will be multiplied with the
        participants points.
      </div>
      <InputField
        id="multiplier"
        label="Multiplier"
        type="number"
        isRequired={true}
        onBlur={(fieldValue) => {}}
      />
      <span className="text-gray-400">Enter a digit greater than 1</span>
      <Button
        btnText="Save"
        className="w-full bg-blue-400 py-2 text-white"
        isDisabled={false}
        isLoading={false}
        onClick={() => {}}
      />
    </div>
  );
};
