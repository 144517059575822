import React from "react";
export const RadioButton = ({
  label,
  id,
  value,
  onChange,
  isSelected = false,
}) => {
  return (
    <div className="flex items-start">
      <div className="flex items-center h-5">
        <input
          key={id}
          id={id}
          value={value}
          type="radio"
          checked={isSelected}
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
          onChange={onChange}
        />
      </div>
      <div className="ml-2 text-sm">
        <label htmlFor={id} className="font-normal light_grayish_navy">
          {label}
        </label>
      </div>
    </div>
  );
};
