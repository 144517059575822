import { useState } from "react";
import { Tabs } from "Components/Common/Tabs/Tabs";
import { Button } from "Components/Common/Button/Button";
import { ExpertTable } from "./Expert";

const tab = [{ name: "Rankpage Experts" }];

export const RankpageExperts = () => {
  const [activeTab, setActiveTab] = useState(tab[0].name);
  const [addExpert,setAddExpert] = useState(false)
  return (
    <>
      {" "}
      <span>Rankpage Experts</span>
      <div className="flex justify-between">
        <Tabs tabs={tab} activeTab={activeTab} onTab={(tabName) => {}} />
        <Button
          btnText="Add an Expert"
          className="w-36 h-8 bg-blue-400 text-white"
          isDisabled={false}
          isLoading={false}
          onClick={() => setAddExpert((prevState)=>!prevState)}
        />
      </div>
      <br />
      <ExpertTable addExpert={addExpert} setAddExpert={setAddExpert}/>
    </>
  );
};
