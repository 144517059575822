import { useEffect, useState } from "react";
import { Dividers } from "./Dividers";
import { Dropdown } from "Components/Common/Dropdown/Dropdown";
import { usePickemContext } from "Context/PickemCreation";

const MAIN_CATEGORY = ["SPORTS", "FINANCE"];
const SPORTS_SUB_CATEGORY = ["FOOTBALL", "BASKETBALL", "ICEHOCKEY"];
const FINANCE_SUB_CATEGORY = ["STOCKS", "FOREX", "CRYPTO"];
const OPERATOR = ["MOST", "LEAST"];
const BASKETBALL_CRITERIA = [
  "POINTS",
  "TWOPOINTER",
  "THREEPOINTER",
  "REBOUNDS",
  "ASSISTS",
];
const FOOTBALL_CRITERIA = [
  "PASS",
  "LONGBALLS",
  "SAVES",
  "TOUCHES",
  "POSSESSION",
  "DUELLOST",
  "DUELWON",
  "FOULS",
  "GOALS",
];
const ICEHOCKEY_CRITERIA = ["GOALS", "SHOTS", "ASSISTS", "SAVES"];
const CURRENCY_CRITERIA = ["EXCHANGE_RATE"];
const STOCKS_CRITERIA = ["PRICE"];

export const IntresetForm = ({ setIsDisabled = { setIsDisabled } }) => {
  const { interest, setInterest } = usePickemContext();
  const [mainCategory, setMainCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [criteriaOperator, setOperator] = useState("");
  const [pickemCriteria, setPickemCriteria] = useState(BASKETBALL_CRITERIA);
  const [selectedCriteria, setSelectedCriteria] = useState("");
  useEffect(() => {
    if (mainCategory && subCategory && selectedCriteria && criteriaOperator) {
      setInterest({
        mainCategory,
        subCategory,
        selectedCriteria,
        criteriaOperator,
      });
      setIsDisabled(false);
    }
  }, [mainCategory, subCategory, selectedCriteria, criteriaOperator]);
  useEffect(() => {
    if (interest?.mainCategory) {
      const {
        mainCategory: MainCategory,
        subCategory: SubCategory,
        selectedCriteria: SelectedCriteria,
        criteriaOperator: CriteriaOperator,
      } = interest;

      if (MainCategory && SubCategory && SelectedCriteria && CriteriaOperator) {
        setMainCategory(MainCategory);
        setSubCategory(SubCategory);
        setSelectedCriteria(SelectedCriteria);
        setOperator(CriteriaOperator);
      }
    }
  }, [interest]);
  return (
    <>
      <Dividers text="Interest" />
      <div className="w-96">
        <Dropdown
          id="selectIntrest"
          label="Category"
          isRequired={true}
          onChange={(value) => setMainCategory(value)}
        >
          <Dropdown.Option
            id={-1}
            value={"-1"}
            label={mainCategory}
            isDefaultOption={true}
          />
          {MAIN_CATEGORY.map((pickemType, index) => (
            <Dropdown.Option
              key={index}
              id={index}
              value={pickemType}
              label={pickemType}
            />
          ))}
        </Dropdown>
      </div>
      <Dividers text="Sub-category" />
      <div className="w-96">
        {mainCategory === "SPORTS" ? (
          <Dropdown
            id="selectSubCategory"
            label="Sub Category"
            isRequired={true}
            onChange={(value) => {
              if (value === "FOOTBALL") {
                setPickemCriteria(FOOTBALL_CRITERIA);
              } else if (value === "BASKETBALL") {
                setPickemCriteria(BASKETBALL_CRITERIA);
              } else if (value === "ICEHOCKEY") {
                setPickemCriteria(ICEHOCKEY_CRITERIA);
              }
              setSubCategory(value);
            }}
          >
            <Dropdown.Option
              id={-1}
              value={"-1"}
              label={subCategory}
              isDefaultOption={true}
            />
            {SPORTS_SUB_CATEGORY.map((pickemType, index) => (
              <Dropdown.Option
                key={index}
                id={index}
                value={pickemType}
                label={pickemType}
              />
            ))}
          </Dropdown>
        ) : (
          <Dropdown
            id="selectSubCategory"
            label="Sub Category"
            isRequired={true}
            onChange={(value) => {
              if (value === "STOCKS") {
                setPickemCriteria(STOCKS_CRITERIA);
              } else if (value === "FOREX" || value === "CRYPTO") {
                setPickemCriteria(CURRENCY_CRITERIA);
              }
              setSubCategory(value);
            }}
          >
            <Dropdown.Option
              id={-1}
              value={"-1"}
              label={subCategory}
              isDefaultOption={true}
            />
            {FINANCE_SUB_CATEGORY.map((pickemType, index) => (
              <Dropdown.Option
                key={index}
                id={index}
                value={pickemType}
                label={pickemType}
              />
            ))}
          </Dropdown>
        )}
      </div>
      <Dividers text="Pick’em Criteria" />
      <div className="w-96">
        {" "}
        <Dropdown
          id="pickemCriteria"
          label="Category"
          isRequired={true}
          onChange={(value) => setSelectedCriteria(value)}
        >
          <Dropdown.Option
            id={-1}
            value={"-1"}
            label={selectedCriteria}
            isDefaultOption={true}
          />
          {pickemCriteria.map((pickemType, index) => (
            <Dropdown.Option
              key={index}
              id={index}
              value={pickemType}
              label={pickemType}
            />
          ))}
        </Dropdown>
      </div>
      <Dividers text="Operator" />
      <div className="w-96">
        {" "}
        <Dropdown
          id="selectOperator"
          label="Category"
          isRequired={true}
          onChange={(value) => setOperator(value)}
        >
          <Dropdown.Option
            id={-1}
            value={"-1"}
            label={criteriaOperator}
            isDefaultOption={true}
          />
          {OPERATOR.map((pickemType, index) => (
            <Dropdown.Option
              key={index}
              id={index}
              value={pickemType}
              label={pickemType}
            />
          ))}
        </Dropdown>
      </div>
    </>
  );
};
