import React, { useEffect, useState } from "react";
import Table from "Components/Common/Table/Table";
import { ViewDetails } from "./Common/ViewDetails";
import { trendingListsFormatter } from "./APIFormatters";
import { GetTrendingLists } from "Services/API/Ranklists.service";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import "./Rankpage.css";

const HEADINGS = ["", "Title", "Reorders", "Reactions", "Comments", " "];
const TABLE_CSS = "px-6 py-4 cursor-pointer text-sm";

export const Trending = () => {
  const [isSideView, setIsSideView] = useState(false);
  const [trendingLists, setTrendingLists] = useState([]);
  const [trendingRankpageUser, setTrendingRankpageUser] = useState({
    id: "",
    hide: "",
    title: "",
    category: "",
    rankLists: "",
    uploadedBy: "",
    reposts: "",
    reactions: "",
    comments: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(7);
  const [hideDeleteuser, setHideDeleteUser] = useState(false);

  const TrendingLists =() => {
    setIsLoading(true);
    GetTrendingLists(pageNumber, perPage)
      .then((res) => {
        setTrendingLists(trendingListsFormatter(res.data));
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("[GetTrendingLists][API_ERROR] - ", err);
      });
  }
  useEffect(() => {
    TrendingLists()
    return () => {
      setTrendingLists([]);
    };
  }, [pageNumber, hideDeleteuser]);
  return (
    <>
      <div
        className={`flex flex-col gap-4 ${
          isSideView ? "Table" : "Orignal_Table_Width"
        }`}
      >
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={
            trendingLists?.length > 0 &&
            trendingLists.map((user, index) => (
              <tr
                key={index}
                className={user.hidden ? "bg-gray-100 text-gray-500" : ""}
              >
                <td className={TABLE_CSS}>
                  <div>{index+1}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div className="font-bold">{user.title}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div className="text-gray-secondary">{user.reposts}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div className="text-gray-secondary">{user.reactions}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div className="text-gray-secondary">{user.comments}</div>
                </td>
                <td className="px-4 py-4 text-left text-sm font-medium ">
                  <span
                    onClick={() => {
                      setTrendingRankpageUser({
                        id: user.id,
                        hide: user.hidden,
                        title: user.title,
                        uploadedBy: user.uploadedBy,
                        reposts: user.reposts,
                        reactions: user.reactions,
                        comments: user.comments,
                        rankLists: user.rankLists,
                        category: user.category,
                      });
                      setIsSideView((x) => !x);
                    }}
                    className="cursor-pointer text-blue-dark"
                  >
                    {isSideView ? "" : "View "}
                  </span>
                </td>
              </tr>
            ))
          }
        />
        {!isLoading &&trendingLists?.length <= 0 &&<div className="flex bg-white w-full justify-center items-center h-96">No Trending Rankpages found at the moment</div>}

        {isLoading && <Spinner />}
        {!isLoading && trendingLists.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
      </div>
      {isSideView && (
        <ViewDetails
          setIsSideView={setIsSideView}
          rankpageDetails={trendingRankpageUser}
          setHideDeleteUser={setHideDeleteUser}
          onDelete={() => {TrendingLists()}}
        >
          <div className="text-gray-400">
            Uploaded By{" "}
            <div className="text-black mt-1">
              {trendingRankpageUser.uploadedBy}
            </div>
          </div>
          <div className="text-gray-400">
            Reposts{" "}
            <div className="text-black mt-1">
              {trendingRankpageUser.reposts}
            </div>
          </div>
          <div className="text-gray-400">
            Reactions{" "}
            <div className="text-black mt-1">
              {trendingRankpageUser.reactions}
            </div>
          </div>
          <div className="text-gray-400">
            Comments{" "}
            <div className="text-black mt-1">
              {trendingRankpageUser.comments}
            </div>
          </div>
        </ViewDetails>
      )}
    </>
  );
};
