import React, { useState } from "react";
import { LeaderBoard } from "./LeaderBoard";
import { RankpagePickems } from "./RankpagePickems";

const USERS_VIEW = "usersTable";
const LEADERBOARD_VIEW = "leaderBoardTable";

export const MainRankpageView = () => {
  const [currentTableView, setCurrentTableView] = useState(USERS_VIEW);
  return currentTableView === USERS_VIEW ? (
    <RankpagePickems setCurrentTableView={setCurrentTableView} view={LEADERBOARD_VIEW}/>
  ) : (
    <LeaderBoard setCurrentTableView={setCurrentTableView} view={USERS_VIEW}/>
  );
};
