import React from "react";
import ClassNames from "Helpers/Common";
import { ReactComponent as BackIcon } from "Assets/Icons/BackArrow.svg";
import { ReactComponent as ForwardIcon } from "Assets/Icons/ForwardArrow.svg";

export const PaginationBtns = ({
  onNext,
  onPrevious,
  isNextDisabled,
  isPrevDisabled,
}) => {
  return (
    <div className="flex justify-between text-sm font-medium cursor-pointer">
      <span
        className={ClassNames(
          "flex gap-2 items-center",
          isPrevDisabled ? "text-gray-400" : "text-blue-400"
        )}
        onClick={onPrevious}
      >
        <BackIcon className="h-4 w-4" />
        Previous
      </span>
      <span
        className={ClassNames(
          "flex gap-2 items-center",
          isNextDisabled ? "text-gray-400" : "text-blue-400"
        )}
        onClick={onNext}
      >
        Next
        <ForwardIcon className="h-4 w-4" />
      </span>
    </div>
  );
};
