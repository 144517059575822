import ClassNames, { getBase64 } from "Helpers/Common";
import { useEffect, useRef, useState } from "react";
import { UploadImage } from "Services/API/UploadImage.service";
import "./UploadButton.scss";

export const UploadButton = ({
  id,
  icon,
  children,
  className,
  onUpload,
  setUploadFileError,
}) => {
  const inputRef = useRef();
  useEffect(() => {
    return () => {
      inputRef.current = null;
    };
  }, []);
  return (
    <div className={ClassNames("UploadButton", "light", className)}>
      {icon && (
        <span
          className="UploadBtn_IconWrapper"
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          {icon}
        </span>
      )}
      <input
        id={id}
        ref={inputRef}
        type="file"
        onChange={async ({ target }) => {
          getBase64(target.files[0]).then((data) => {
            UploadImage({
              image: data.replace("data:", "").replace(/^.+,/, ""),
            })
              .then(({ url }) => {
                setUploadFileError(false);
                onUpload(url);
              })
              .catch((err) => {
                console.error("[UploadImage][API_ERROR] - ", err);
                setUploadFileError(true);
              });
          });
        }}
      />
      {children}
    </div>
  );
};
