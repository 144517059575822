import { useState } from "react";
import ClassNames from "Helpers/Common";
import Table from "Components/Common/Table/Table";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import { ReactComponent as SadFaceIcon } from "Assets/Icons/SadFace.svg";
import { Collapse } from "Components/Common/Collapseable/Collapse";

const HEADINGS = ["Title", "Uploaded By", "Date of Upload", " "];
const TABLE_CSS = "px-6 py-4 cursor-pointer text-sm font-normal";

export const RankpageTable = ({
  isLoading = true,
  usersData,
  hasNextPage,
  hasPreviousPage,
  setPageNumber,
  pageNumber,
}) => {
  const [rankpageDetails, setRankpageUserDetails] = useState({
    id: "",
    hide: "",
    title: "",
    category: "",
    rankLists: "",
    uploadedBy: "",
    uploadDate: "",
  });
  const [isSideView, setIsSideView] = useState(false);
  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && usersData?.length > 0 ? (
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={usersData?.map((item, index) => (
            <tr key={index}>
              <td className={TABLE_CSS}>
                <div>
                  {item.title} {item.lastName}
                </div>
              </td>
              <td className={ClassNames("text-gray-secondary", TABLE_CSS)}>
                <div>{item.uploadedBy}</div>
              </td>
              <td className={ClassNames("text-gray-secondary", TABLE_CSS)}>
                <div>{item.uploadDate}</div>
              </td>

              <td className={TABLE_CSS}>
                <span
                  className="flex justify-center text-blue-dark"
                  onClick={() => {
                    setRankpageUserDetails({
                      id: item.id,
                      title: item.title,
                      uploadedBy: item.uploadedBy,
                      uploadDate: item.uploadDate,
                      rankLists: item.orderedItems,
                      category: item.category,
                    });
                    setIsSideView((prevState) => !prevState);
                  }}
                >
                  View
                </span>
              </td>
            </tr>
          ))}
        />
      ) : (
        !isLoading && (
          <div className="flex flex-col gap-2 items-center justify-center md:h-96">
            <SadFaceIcon className="h-16 w-16 animate-bounce text-gray-secondary" />
            <span className="text-sm font-medium animate-pulse">
              No data found.Reset the filters
            </span>
          </div>
        )
      )}
      {!isLoading && usersData?.length > 0 && (
        <PaginationBtns
          isNextDisabled={!hasNextPage}
          isPrevDisabled={!hasPreviousPage}
          onNext={() => {
            if (hasNextPage) {
              setPageNumber(pageNumber + 1);
            }
          }}
          onPrevious={() => {
            if (hasPreviousPage) {
              setPageNumber(pageNumber - 1);
            }
          }}
        />
      )}
      {isSideView && (
        <SideView
          showProfileImage={false}
          setIsSideView={setIsSideView}
          heading={rankpageDetails.title}
          subHeading={
            <span className="text-blue-400">{rankpageDetails.category}</span>
          }
        >
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <div className="text-gray-400">
                Uploaded By{" "}
                <div className="text-black mt-1">
                  {rankpageDetails.uploadedBy}
                </div>
              </div>
              <div className="text-gray-400">
                Date{" "}
                <div className="text-black mt-1">
                  {rankpageDetails.uploadDate}
                </div>
              </div>
            </div>
            <Collapse listData={rankpageDetails.rankLists} />
          </div>
        </SideView>
      )}
    </>
  );
};
