import { Trending } from "./Trending";
import { Rankpages } from "./Rankpages";
import { ReportedRankpages } from "./Reported";
import { Tabs } from "Components/Common/Tabs/Tabs";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";
import { Button } from "Components/Common/Button/Button";
import { useGlobalContext } from "Context/Global";
import { CREATE_RANKPAGE_PATH } from "Constants/View";

const RANKPAGES = "Rankpages";
const REPORTED_RANKPAGES = "Reported Rankpages";
const TRENDING_RANKPAGES = "Trending Rankpages";
const tabs = [
  { name: "Rankpages" },
  { name: "Reported Rankpages" },
  { name: "Trending Rankpages" },
];

export const RankpagesMain = () => {
  const { setActiveView } = useGlobalContext();
  const [activeTab, setActiveTab] = useLocalStorage(
    "rankpage_current_tab",
    RANKPAGES
  );

  const handleTabClick = (tabName) => {
    if (tabName === REPORTED_RANKPAGES) {
      setActiveTab(REPORTED_RANKPAGES);
    } else if (tabName === TRENDING_RANKPAGES) {
      setActiveTab(TRENDING_RANKPAGES);
    } else {
      setActiveTab(RANKPAGES);
    }
  };

  return (
    <>
      <span>Rankpages</span>
      <div className="flex justify-between">
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onTab={(tabName) => handleTabClick(tabName)}
        />
        <Button
          btnText="Create a Rankpage"
          className="w-36 h-8 bg-blue-400 text-white"
          isDisabled={false}
          isLoading={false}
          onClick={() => {
            setActiveView(CREATE_RANKPAGE_PATH);
          }}
        />
      </div>
      <br />
      {activeTab === RANKPAGES ? (
        <Rankpages />
      ) : activeTab === REPORTED_RANKPAGES ? (
        <ReportedRankpages />
      ) : (
        <Trending />
      )}
    </>
  );
};
