import ClassNames from "Helpers/Common";
import { useState } from "react";
import TimePicker  from "react-time-picker";
import './TimePicker.css'
export const Timepicker = ({
  id,
  label,
  value,
  isRequired = false,
  onBlur,
  placeholder = "",
}) => {
  const [fieldValue, setFieldValue] = useState("");
  const [fieldError, setFieldError] = useState("");
  return (
    <>
      <label className="TimePicker_MainWrapper block sapphire font-semibold mb-1">
        {label}
      </label>

      <TimePicker
        className={ClassNames("TimePicker_Input w-full")}
        id={id}
        value={value}
        onChange={(selectedTime) => {
          setFieldValue(selectedTime);
          setFieldError(false);
        }}
        onBlur={() => {
          if (fieldValue) {
            onBlur?.(fieldValue, setFieldError);
          } else if (isRequired && !fieldValue) {
            setFieldError(`${label?? "Time"} is required`);
          }
        }}
      />
      {fieldError && (
        <div className="pt-2 text-sm text-red-600" id={`${label}-error`}>
          {fieldError}
        </div>
      )}
    </>
  );
};
