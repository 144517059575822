import { useState } from "react";
import { Preview } from "./Preview";
import ClassNames from "Helpers/Common";
import { DetailForm } from "./DetailForm";
import { RankList } from "./RankList/RankList";
import { BASE_WIDGET_PATH } from "Constants/View";
import { useGlobalContext } from "Context/Global";
import { Button } from "Components/Common/Button/Button";
import { CreateWidget } from "Services/API/Widget.service";
import { setLocalStorage } from "Components/Common/LocalStorage/LocalStoarage";
import "./Widget.css";

const DETAILS_FORM = "detailsForm";
const RANKLIST_FORM = "ranklistForm";
const PREVIEW_FORM = "previewForm";

export const CreatePoll = () => {
  const { setActiveView } = useGlobalContext();
  const [currentForm, setCurrentForm] = useState(DETAILS_FORM);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [detailForm, setDetailForm] = useState({
    title: "",
    numOfItems: "",
  });
  const [selectedItems, setSelectedItems] = useState([]);

  return (
    <div className="flex flex-col gap-8 bg-white p-4 Poll_Wrapper">
      {" "}
      <div className=" WidgetForms_Wrapper">
        {currentForm === DETAILS_FORM ? (
          <DetailForm detailForm={detailForm} setDetailForm={setDetailForm} />
        ) : currentForm === RANKLIST_FORM ? (
          <RankList
            setSelectedItems={setSelectedItems}
            itemsLimit={detailForm.numOfItems}
          />
        ) : (
          <Preview />
        )}
      </div>
      <div className="flex justify-between border-t-2 border-gray-200  p-4 xl:p-6">
        <Button
          btnText="Back"
          className={"w-24 py-2 bg-white  border-gray-300"}
          isDisabled={false}
          isLoading={false}
          onClick={() => {
            if(currentForm===DETAILS_FORM){
              setActiveView(BASE_WIDGET_PATH);
            }
           else if (currentForm === RANKLIST_FORM) {
              setCurrentForm(DETAILS_FORM);
            } else if (currentForm === PREVIEW_FORM) {
              setCurrentForm(RANKLIST_FORM);
            }
          }}
        />
        <Button
          btnText={"Next"}
          className={ClassNames(
            "w-24 py-2",
            !isDisabled && "bg-blue-400 text-white"
          )}
          isDisabled={isDisabled}
          isLoading={isBtnLoading}
          onClick={() => {
            if (currentForm === DETAILS_FORM) {
              setCurrentForm(RANKLIST_FORM);
            } else if (currentForm === RANKLIST_FORM) {
              setIsBtnLoading(true);
              CreateWidget({
                title: detailForm.title,
                size: detailForm.numOfItems,
                ordered_items: selectedItems.map((item) => item.id),
              })
                .then(({ title, id, code, ordered_items }) => {
                  if ((title, id, code, ordered_items)) {
                    setIsBtnLoading(false);
                    setLocalStorage("CREATE_WIDGET", {
                      title,
                      id,
                      code,
                      ordered_items,
                    });
                    setCurrentForm(PREVIEW_FORM);
                  }
                })
                .catch((err) => {
                  console.error("[CreateWidget][API_ERROR] - ", err);
                  setIsBtnLoading(false);
                });
            } else if (currentForm === PREVIEW_FORM) {
              setActiveView(BASE_WIDGET_PATH);
            }
          }}
        />
      </div>
    </div>
  );
};
