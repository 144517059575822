import React from "react";

export const Footer = () => {
  return (
    <div className="bg-gray-100 py-3 2xl:py-6 w-full rounded-bl-md rounded-br-md p-4 text-xs font-normal">
      <span>
        By signing in, you agree to our Terms, Data Policy and Cookies Policy.
      </span>
    </div>
  );
};
