import { useEffect, useState } from "react";
import { EditLegalDocs } from "./legalDocs";
import Table from "Components/Common/Table/Table";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { FetchLegalDocs } from "Services/API/LegalDocs.service";

const HEADINGS = ["Name", " "];
const TABLE_CSS = "px-6 py-4 cursor-pointer text-sm font-medium";
const VIEW_MODE = "ViewMode";
const EDIT_MODE = "EditMode";

export const MainView = () => {
  const [activeView, setActiveView] = useLocalStorage(
    "legalDocs_view",
    VIEW_MODE
  );
  const [docName, setDocName] = useState({
    id: "",
    title: "",
    description: "",
  });
  return activeView === VIEW_MODE ? (
    <MainTableView setDocName={setDocName} setActiveView={setActiveView} />
  ) : (
    <EditLegalDocs docName={docName} setActiveView={setActiveView} />
  );
};

const formattedDocs = (Docs) => {
  return Docs.map((doc) => {
    return {
      id: doc.id,
      name: doc.title,
      description: doc.description,
    };
  });
};
const MainTableView = ({ setDocName, setActiveView }) => {
  const [legalDocs, setLegalDocs] = useState([]);

  useEffect(() => {
    FetchLegalDocs()
      .then((res) => {
        setLegalDocs(formattedDocs(res.data));
      })
      .catch((err) => {
        console.error("[FetchLegalDocs][API_ERROR] - ", err);
      });
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <span className="text-lg font-medium text-black-light">Legal Docs</span>
      <Table
        heading={<TableHeading TableHeader={HEADINGS} />}
        body={
          legalDocs.length > 0 &&
          legalDocs.map((item, index) => (
            <tr key={index}>
              <td className={TABLE_CSS}>
                <div>{item.name}</div>
              </td>
              <td className={TABLE_CSS}>
                <span
                  onClick={() => {
                    setDocName({
                      id: item.id,
                      title: item.name,
                      description: item.description,
                    });
                    setActiveView(EDIT_MODE);
                  }}
                  className="flex justify-center text-blue-dark"
                >
                  {item.name && "Edit"}
                </span>
              </td>
            </tr>
          ))
        }
      />
    </div>
  );
};
