import { useEffect, useState } from "react";
import ClassNames from "Helpers/Common";
import { useParams } from "react-router-dom";
import { Button } from "Components/Common/Button/Button";
import { ReactComponent as AppLogo } from "Assets/Icons/WidgetLogo.svg";
import { ReactComponent as DefaultImage } from "Assets/Icons/Profile.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { GetWidget, WidgetParticipation } from "Services/API/Widget.service";
import "Colors.css";
import "./Widget.css";

const formatItems = (items) => {
  return items.map((item) => {
    return {
      id: item._id,
      name: item.title,
      image: item.image_url,
    };
  });
};

export const PublicPoll = () => {
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [title, setTitle] = useState("");
  const [size, setSize] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      GetWidget(id)
        .then((res) => {
          setSize(res?.size);
          setTitle(res?.title);
          setItems(formatItems(res?.ordered_items));
        })
        .catch((err) => {
          console.error("[GetWidget][API_ERROR] - ", err);
        });
    }
  }, [id]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // reorder using index of source and destination.
    const schemaCopy = items.slice();
    const [removed] = schemaCopy.splice(result.source.index, 1);
    // put the removed one into destination.
    schemaCopy.splice(result.destination.index, 0, removed);


    setItems(schemaCopy);
  };
  return (
    items.length > 0 && (
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-between px-4 p-2 bg_solitude">
          <div className="flex flex-col gap-1">
            {" "}
            <span className="sapphire text-base font-medium">{title}</span>
            <span className="raven text-sm font-normal">
              Drag items to reorder
            </span>
          </div>
          <AppLogo className={`h-8 w-8 ${size === 5 ? "" : "pr-2"}`} />
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="column1">
            {(provided, snap) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex flex-col gap-2 px-4"
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snap) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          className={ClassNames(
                            "flex  bg-white border border-gray-200 shadow-md rounded-md py-2 px-2 items-center gap-3",
                            size === 5 ? "w-80" : "w-72"
                          )}
                        >
                          <span>{index + 1}</span>
                          <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                            {item.image ? (
                              <img src={item.image} />
                            ) : (
                              <DefaultImage />
                            )}
                          </span>
                          <span className="truncate text-xs font-medium">
                            {item.name}
                          </span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={`${size === 5 ? "px-2" : "px-4"}`}>
          <Button
            btnText="Submit"
            className={`w-full py-2 rounded-2xl bg-blue-400 text-white`}
            isDisabled={false}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              WidgetParticipation(id, {
                participant_choices: items.map((item) => item.id),
              })
                .then((res) => {
                  if (res) {
                    setIsLoading(false);
                    window.open(`https://staging.rankpage.com/poll/${id}`);
                  }
                })
                .catch((err) => {
                  console.error(`[WidgetParticipation][API_RESPONSE]`, err);
                  setIsLoading(false);
                });
            }}
          />
        </div>
      </div>
    )
  );
};
