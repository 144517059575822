import { Route, Switch } from "react-router";
import { ViewPickems } from "Pages/Pickems/ViewPickems";
import { CreatePickemMainPage } from "Pages/Pickems/CreatePickem";
import { BASE_PICKEMS_PATH, CREATE_PICKEM_PATH } from 'Constants/View';

export const Pickems = () => {
    return (
        <Switch>
          <Route exact path={BASE_PICKEMS_PATH} render={() => <ViewPickems />} />
          <Route exact path={CREATE_PICKEM_PATH} render={() => <CreatePickemMainPage />} />
        </Switch>
      );
};
