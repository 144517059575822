import { Route, Switch } from "react-router";
import { WidgetMainPage } from "Pages/Widget/Widget";
import { CreateWidget } from "Pages/Widget/CreateWidget";
import { BASE_WIDGET_PATH, CREATE_WIDGET_PATH } from "Constants/View";

export const Widget = () => {
  return (
    <Switch>
      <Route exact path={BASE_WIDGET_PATH} render={() => <WidgetMainPage />} />
      <Route exact path={CREATE_WIDGET_PATH} render={() => <CreateWidget />} />
    </Switch>
  );
};
