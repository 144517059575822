import { useEffect, useState } from "react";
import { usePickemContext } from "Context/PickemCreation";
import { GetPickemItems } from "Services/API/CreatePickem.service";
import { DND } from "Components/Common/DragandDrop/DND";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";

const itemsListFormatter = (items) => {
  return items.map((item) => {
    return {
      id: item.id,
      image: item.image_url,
      name: item.title,
      team: item.team,
    };
  });
};

export const Reorder = ({ setIsDisabled }) => {
  const {
    interest: { mainCategory, subCategory },
    event: { id },
  } = usePickemContext();
  const [itemType, setItemType] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mainCategory) {
      setLoading(true);
      setIsDisabled(true);
      if (mainCategory === "SPORTS") {
        setItemType("PLAYERS");
      } else if (mainCategory === "FINANCE") {
        if (subCategory === "STOCKS") {
          setItemType("ALL");
        } else if (subCategory === "FOREX" || subCategory === "CRYPTO") {
          setItemType("FROM");
        }
      }
    }
    if (mainCategory && id && itemType) {
      setLoading(true);
      GetPickemItems(mainCategory.toLowerCase(), id, itemType)
        .then((res) => {
          if (res) {
            setItemsList(itemsListFormatter(res));
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error("[GetPickemItems][API_ERROR] - ", err);
          setLoading(false);
        });
    }
  }, [mainCategory, id, itemType]);
  return itemsList.length > 0 ? (
    <DND list={itemsList} setIsDisabled={setIsDisabled} />
  ) : (
    !loading && <Spinner />
  );
};
