export const BASE_LOGIN_PATH = "/login";
export const BASE_FORGOT_PASSWORD_PATH = "/forgotpassword";
export const BASE_VERIFICATION_PATH = "/verification";
export const BASE_NEW_PASSWORD_PATH = "/newpassword";
export const BASE_DASHBOARD_PATH = "/dashboard";
export const BASE_RANKPAGE_PATH = "/rankpage";
export const CREATE_RANKPAGE_PATH = "/rankpage/create";
export const BASE_PICKEMS_PATH = "/pickems";
export const CREATE_PICKEM_PATH = "/pickems/create";
export const BASE_USER_PROFILES_PATH = "/userprofiles";
export const BASE_WIDGET_PATH = "/widget";
export const CREATE_WIDGET_PATH = "/widget/create";
export const CREATE_POLL_PATH = "/widget/poll/public";
export const BASE_EXPERTS_PATH = "/experts";
export const BASE_LEGAL_DOCS_PATH = "/legalDocs";
export const BASE_FAQS_PATH = "/faqs";
export const BASE_ANNOUNCEMENTS_PATH = "/announcements";
export const CREATE_ANNOUNCEMENTS_PATH = "/announcements/create";
export const BASE_RANKITEMS_PATH = "/rankItems";
export const BASE_ADMIN_PATH = "/admin";
export const CREATE_ADMIN_PATH = "/admin/create";
export const EDIT_ADMIN_PATH = "/admin/edit/:id";
export const BASE_SEARCH_FILTER_PATH = "/search&filter/:query";
