import { useState } from "react";
import ClassNames from "Helpers/Common";
import { ReactComponent as ArrowDownIcon } from "Assets/Icons/ArrowDown.svg";
import "./DropDown.css";

export const DropdownBasic = ({
  id,
  selectedOptionId = "-1",
  label,
  isRequired = false,
  children,
  className,
  showLabel = false,
  onBlur,
  onChange,
  Icon = ArrowDownIcon,
  width = "",
}) => {
  const [fieldValue, setFieldValue] = useState("");
  const [fieldError, setFieldError] = useState("");

  return (
    <div className={ClassNames("Dropdown_MainWrapper flex-1", width && `w-${width}`)}>
      {showLabel && (
        <label htmlFor={id} className="block text-sm font-semibold sapphire">
          {label}
        </label>
      )}
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 right-0 pr-3 flex items-center">
          <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <select
          id={id}
          name={label}
          defaultValue={selectedOptionId}
          onChange={({ target }) => {
            setFieldError("");
            setFieldValue(target.value);
            onChange?.(target.value);
          }}
          onBlur={() => {
            if (fieldValue) {
              onBlur?.(fieldValue, setFieldError);
            } else if (isRequired && !fieldValue) {
              setFieldError(`${label} is required`);
            }
          }}
          className={ClassNames(
            className
              ? className
              : "mt-1 appearance-none block w-full pl-2 px-3 py-2 border rounded-md shadow-sm bg-white focus:outline-none sm:text-sm",
            fieldError
              ? "border-red-500 focus:ring-red-500 focus:border-red-500"
              : "border-gray-300  focus:ring-blue-400 focus:border-blue-400"
          )}
        >
          {children}
        </select>
      </div>
      {fieldError && (
        <div className="pt-2 text-sm text-red-600" id={`${label}-error`}>
          {fieldError}
        </div>
      )}
    </div>
  );
};
