import React, { useEffect, useState } from "react";
import { TableHeading } from "./TableHeading";
import Table from "Components/Common/Table/Table";
import { formatDate } from "Helpers/DateFormaater";
import { GetFeedback } from "Services/API/Users.service";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import { ReactComponent as StarIcon } from "Assets/Icons/Star.svg";
import "./Profile.css";

const HEADINGS = [
	"Name",
	"Username",
	"Email",
	"Phone Number",
	"Date of Birth",
	"Gender",
	" ",
];
const userFeedbackFormatter = (data) => {
	return data.map((feedback) => {
		return {
			id: feedback.id,
			userId: feedback.user_id,
			email: feedback.user_detail.email,
			gender: feedback.user_detail.gender,
			feedBackDate: formatDate(feedback.createdAt, "MM/DD/YYYY"),
			userName: feedback.user_detail.username?.replaceAll("null", ""),
			DOB: formatDate(feedback.user_detail.date_of_birth, "MM/DD/YYYY") || "",
			userImage: feedback.user_detail.profile_image_url,
			feedback: feedback.about || "-",
			phoneNumber: feedback.user_detail?.phone || "",
			rating: feedback?.rating || "",
			name: (
				feedback.user_detail.first_name +
				" " +
				feedback.user_detail.last_name
			)?.replaceAll("null", ""),
		};
	});
};

export const UserFeedback = () => {
	const [isSideView, setIsSideView] = useState(false);
	const [userFeedback, setUserFeedback] = useState([]);
	const [selectedUserDetails, setSelectedUserDetails] = useState({
		name: "",
		email: "",
		feedBackDate: "",
		userName: "",
		feedback: "",
		userImage: "",
		rating: "",
	});
	const [isLoading, setIsLoading] = useState(false);
	const [hasNextPage, setHasNextPage] = useState(false);
	const [hasPreviousPage, setHasPreviousPage] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(7);

	useEffect(() => {
		setIsLoading(true);
		GetFeedback(pageNumber, perPage)
			.then((res) => {
				setUserFeedback(userFeedbackFormatter(res.data));
				setHasNextPage(res?.pagination?.hasNext);
				setHasPreviousPage(res?.pagination?.hasPrevious);
				setIsLoading(false);
			})
			.catch((err) => {
				console.error("[GetFeedback][API_ERROR] - ", err);
				setIsLoading(false);
			});
		return () => {
			setUserFeedback([]);
		};
	}, [pageNumber]);

	return (
		<>
			<div
				className={`flex flex-col gap-4 ${
					isSideView ? "Table" : "Orignal_Table_Width"
				}`}
			>
				<Table
					heading={<TableHeading TableHeader={HEADINGS} />}
					body={
						userFeedback?.length > 0 &&
						userFeedback.map((user, index) => (
							<tr key={index}>
								<td className="px-6 py-4 cursor-pointer">
									<div className="text-sm font-bold">{user.name}</div>
								</td>
								<td className="px-6 py-4 cursor-pointer">
									<div className="text-sm text-gray-secondary">
										{user.userName}
									</div>
								</td>
								<td className="px-6 py-4 cursor-pointer">
									<div className="text-sm text-gray-secondary">
										{user.email}
									</div>
								</td>
								<td className="px-6 py-4 cursor-pointer">
									<div className="text-sm text-gray-secondary">
										{user.phoneNumber}
									</div>
								</td>
								<td className="px-6 py-4 cursor-pointer">
									<div className="text-sm text-gray-secondary">{user.DOB}</div>
								</td>
								<td className="px-6 py-4 cursor-pointer">
									<div className="text-sm text-gray-secondary">
										{user.gender}
									</div>
								</td>
								<td className="px-4 py-4 text-left text-sm font-medium">
									<span
										onClick={() => {
											setSelectedUserDetails({
												userName: user.userName,
												name: user.name,
												feedBackDate: user.feedBackDate,
												email: user.email,
												feedback: user.feedback,
												userImage: user.userImage,
												rating: user?.rating,
											});
											setIsSideView((x) => !x);
										}}
										className="cursor-pointer text-blue-dark"
									>
										{isSideView ? "" : "View"}
									</span>
								</td>
							</tr>
						))
					}
				/>
				{!isLoading && userFeedback?.length <= 0 && (
					<div className="flex bg-white w-full justify-center items-center h-96">
						No Reported Rankpages found at the moment
					</div>
				)}

				{isLoading && <Spinner />}
				{!isLoading && userFeedback.length > 0 && (
					<PaginationBtns
						isNextDisabled={!hasNextPage}
						isPrevDisabled={!hasPreviousPage}
						onNext={() => {
							if (hasNextPage) {
								setPageNumber(pageNumber + 1);
							}
						}}
						onPrevious={() => {
							if (hasPreviousPage) {
								setPageNumber(pageNumber - 1);
							}
						}}
					/>
				)}
			</div>
			{isSideView && (
				<SideView
					setIsSideView={setIsSideView}
					heading={selectedUserDetails.name}
					subHeading={selectedUserDetails.email}
					profileImage={selectedUserDetails.userImage}
				>
					<div className="flex flex-col justify gap-6 pt-4">
						<div className="grid grid-cols-2 gap-5">
							<div className="text-gray-400">
								UserName{" "}
								<div className="text-black mt-1">
									{" "}
									{selectedUserDetails.userName}
								</div>
							</div>
							<div className="text-gray-400">
								Feedback Submitted{" "}
								<div className="text-black mt-1">
									{" "}
									{selectedUserDetails.feedBackDate}
								</div>
							</div>
						</div>
						<div className="text-gray-400">
							Feedback
							<div className="text-black mt-1">
								{selectedUserDetails.feedback}
								<div className="flex gap-2 items-center">
									{Array(5)
										.fill()
										.map((_, index) => (
											<StarIcon
												key={index}
												className={`h-5 w-5 ${
													index <= selectedUserDetails?.rating
														? "text-yellow-500"
														: "text-gray-300"
												}`}
											/>
										))}
								</div>
							</div>
						</div>
					</div>
				</SideView>
			)}
		</>
	);
};
