import { useCallback, useEffect, useReducer, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as DefaultImage } from "Assets/Icons/Profile.svg";
import { ReactComponent as XIcon } from "Assets/Icons/Cross.svg";
import { ReactComponent as DuplicateIcon } from "Assets/Icons/Duplicate.svg";

import { usePickemContext } from "Context/PickemCreation";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  result.splice(endIndex, 0, result.splice(startIndex, 1)[0]);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);

  destClone.splice(
    droppableDestination.index,
    0,
    sourceClone.splice(droppableSource.index, 1)[0]
  );

  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone,
  };
};

const grid = 10;

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  width: 250,
  background: "#F3F4F6",
});

const moveReducer = (state, action = {}) => {
  if (action.type === "DRAG_AND_DROP") {
    const { source, destination } = action;

    if (source.droppableId === destination.droppableId) {
      state[source.droppableId] = reorder(
        state[source.droppableId],
        source.index,
        destination.index
      );
    } else {
      state = move(
        state[source.droppableId],
        state[destination.droppableId],
        source,
        destination
      );
    }
  }
  if (action.type === "REMOVE") {
    state.items = [
      ...state.items,
      ...state.selected.filter((item) => item.id === action.id),
    ];
    state.selected = state.selected.filter((item) => item.id !== action.id);
  }
  if (action.type === "COPY") {
    state.selected = [...state?.items];
  }
  return { ...state };
};

export const DND = ({ list, setIsDisabled }) => {
  const { setOrderedItems } = usePickemContext();

  const [state, setState] = useReducer(moveReducer, {
    items: list,
    selected: [],
  });
  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;
      if (!destination) {
        return;
      }

      setState({
        type: "DRAG_AND_DROP",
        source,
        destination,
      });
    },
    [setState]
  );
  const removeItem = (id) => setState({ type: "REMOVE", id });

  useEffect(() => {
    if (state.selected.length > 0) {
      setOrderedItems([...state.selected]);
      setIsDisabled(false);
    }
  }, [state.selected]);
  return (
    <>
      <div className="flex gap-12">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="items">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className="flex flex-col gap-3">
                {state.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="flex w-80 bg-white border border-gray-200 shadow-md rounded-md py-2 px-2 items-center gap-3">
                          <span>{index + 1}</span>
                          <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                            {item.image ? (
                              <img src={item.image} />
                            ) : (
                              <DefaultImage />
                            )}
                          </span>
                          <span> {item.name}</span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="selected">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className="flex flex-col gap-2"
              >
                <div
                  className="flex items-center gap-2 text-xs font-normal text-gray-raven cursor-pointer"
                  onClick={() => {
                    setState({ type: "COPY" });
                  }}
                >
                  <DuplicateIcon className="h-5 w-5" />
                  Copy items
                </div>
                {state.selected.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="flex items-center justify-evenly w-56  bg-white border border-gray-200 shadow-md rounded-md py-1 px-2  gap-3">
                          <span>{index + 1}</span>
                          <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                            {item.image ? (
                              <img src={item.image} />
                            ) : (
                              <DefaultImage />
                            )}
                          </span>
                          <span className="truncate ..."> {item.name}</span>
                          <XIcon
                            className="h-4 w-4"
                            aria-hidden="true"
                            onClick={() => removeItem(item.id)}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};
