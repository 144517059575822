import { useEffect, useReducer } from "react";
import { Dividers } from "./Dividers";
import { formatDate } from "Helpers/DateFormaater";
import { usePickemContext } from "Context/PickemCreation";
import { InputField } from "Components/Common/InputField/InputField";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";
import { Timepicker } from "Components/Common/TimePicker/TimePicker";
import { RadioButton } from "Components/Common/RadioButton/RadioButton";

const PUBLIC = "public";
const PRIVATE = "private";
const ADD = true;
const ADD_NOT = "false";
const SET_CONTEST_TITLE = "SET_CONTEST_TITLE";
const SET_VISIBLITY = "SET_VISIBLITY";
const SET_PARTICIPANTS_LIMIT = "SET_PARTICIPANTS_LIMIT";
const SET_CAN_PARTICIPANTS_ADD = "SET_CAN_PARTICIPANTS_ADD";
const SET_START_DATE = "SET_START_DATE";
const SET_END_DATE = "SET_END_DATE";
const SET_START_TIME = "SET_START_TIME";
const SET_END_TIME = "SET_END_TIME";
const SET_FROM_CONTEXT = "SET_FROM_CONTEXT";

const INIT_SETTINGS_FORM = {
  title: "",
  visiblity: "",
  participantsLimit: "",
  canParticipantsAdd: "",
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_CONTEST_TITLE:
      return { ...state, title: payload };
    case SET_VISIBLITY:
      return { ...state, visiblity: payload };
    case SET_PARTICIPANTS_LIMIT:
      return { ...state, participantsLimit: payload };
    case SET_CAN_PARTICIPANTS_ADD:
      return { ...state, canParticipantsAdd: payload };
    case SET_END_DATE:
      return { ...state, endDate: payload };
    case SET_START_TIME:
      return { ...state, startTime: payload };
    case SET_START_DATE:
      return { ...state, startDate: payload };
    case SET_END_TIME:
      return { ...state, endTime: payload };
    case SET_FROM_CONTEXT:
      return payload;
    default:
      return state;
  }
};

export const Settings = ({ setIsDisabled }) => {
  const [formState, setFormState] = useReducer(reducer, INIT_SETTINGS_FORM);
  const { settings, setSettings, event } = usePickemContext();
  const currentDate = formatDate(new Date(), "DD-MM-YYYY");
  useEffect(() => {
    // TODO: check why this useEffect is not working
    if (settings.participantsLimit) {
      setFormState({ type: SET_FROM_CONTEXT, payload: settings });
    }
  }, [settings]);
  useEffect(() => {
    const {
      title,
      visiblity,
      participantsLimit,
      canParticipantsAdd,
      startDate,
      startTime,
      endDate,
      endTime,
    } = formState;
    if (
      title &&
      visiblity &&
      participantsLimit &&
      canParticipantsAdd &&
      startDate &&
      startTime &&
      endDate &&
      endTime
    ) {
      setSettings(formState);
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formState]);
  return (
    <div className="h-80 flex flex-col gap-6 ">
      <Dividers text="Create a title" />
      <div className="w-96">
        <InputField
          id="pickem-title"
          type="text"
          placeholder="Contest title"
          isRequired={true}
          value={formState.title}
          onBlur={(contestTitle) => {
            setFormState({
              type: SET_CONTEST_TITLE,
              payload: contestTitle.trim(),
            });
          }}
        />
      </div>
      <Dividers text="Visibility" />
      <fieldset className="mt-4 space-y-6">
        <div className="space-y-4">
          <RadioButton
            id="1"
            label="Public"
            value={PUBLIC}
            isSelected={formState.visiblity === PUBLIC}
            onChange={() => {
              setFormState({ type: SET_VISIBLITY, payload: PUBLIC });
            }}
          />
          <RadioButton
            id="2"
            label="Private"
            value={PRIVATE}
            isSelected={formState.visiblity === PRIVATE}
            onChange={() => {
              setFormState({ type: SET_VISIBLITY, payload: PRIVATE });
            }}
          />
        </div>
      </fieldset>
      <Dividers text="Limit Participants" />
      <div className="w-96">
        <InputField
          id="pickem-limit-participants"
          type="number"
          value={formState.participantsLimit}
          placeholder="2-100"
          isRequired={true}
          onBlur={(participantsLimit, setInputFieldError) => {
            if (participantsLimit > 100) {
              setInputFieldError("Can not add more then 100");
            } else {
              setFormState({
                type: SET_PARTICIPANTS_LIMIT,
                payload: participantsLimit.trim(),
              });
            }
          }}
        />
      </div>
      <Dividers text="Let participants add items" />
      <fieldset className="mt-4 space-y-6">
        <div className="space-y-4">
          <RadioButton
            id="1"
            label="Yes"
            value={ADD}
            isSelected={formState.canParticipantsAdd === ADD}
            onChange={() => {
              setFormState({ type: SET_CAN_PARTICIPANTS_ADD, payload: ADD });
            }}
          />
          <RadioButton
            id="2"
            label="No"
            value={ADD_NOT}
            isSelected={formState.canParticipantsAdd === ADD_NOT}
            onChange={() => {
              setFormState({
                type: SET_CAN_PARTICIPANTS_ADD,
                payload: ADD_NOT,
              });
            }}
          />
        </div>
      </fieldset>
      <Dividers text="Time and Date" />
      <div className="grid grid-cols-2 gap-4">
        <div className="w-20 lg:w-72 2xl:w-96">
          <DatePicker
            placeholder="Start Date"
            isRequired={true}
            value={formState.startDate}
            onBlur={(startDate, setFieldError) => {
              const selectedDate = formatDate(startDate, "DD-MM-YYYY");
              if (
                selectedDate < event.startDate &&
                selectedDate >= currentDate
              ) {
                setFormState({ type: SET_START_DATE, payload: startDate });
              } else {
                setFieldError(
                  "Selected start date must be less then the event start date and greater or equal to the current date"
                );
              }
            }}
          />
        </div>
        <div className="w-20 lg:w-72 2xl:w-96">
          <DatePicker
            placeholder="End Date"
            isRequired={true}
            value={formState.endDate}
            onBlur={(endDate, setFieldError) => {
              const selectedDate = formatDate(endDate, "DD-MM-YYYY");
              if (
                selectedDate < event.startDate &&
                selectedDate >= currentDate
              ) {
                setFormState({ type: SET_END_DATE, payload: endDate });
              } else {
                setFieldError(
                  "Selected end date must be less then the event start date  and greater or equal to the current date"
                );
              }
            }}
          />
        </div>
        <div className="w-20 lg:w-72 2xl:w-96">
          <Timepicker
            isRequired={true}
            value={formState.startTime}
            onBlur={(startTime) => {
              setFormState({ type: SET_START_TIME, payload: startTime });
              // if (startTime < event.startTime) {
              // } else {
              //   setFieldError(
              //     "Selected start time  must be less then the event start time"
              //   );
              // }
            }}
          />
        </div>
        <div className="w-20 lg:w-72 2xl:w-96">
          <Timepicker
            isRequired={true}
            value={formState.endTime}
            onBlur={(endTime) => {
              setFormState({ type: SET_END_TIME, payload: endTime });
              // if (endTime < event.startTime) {
              // } else {
              //   setFieldError(
              //     "Selected end time must be less then the event start time"
              //   );
              // }
            }}
          />
        </div>
      </div>
    </div>
  );
};
