import ClassNames from "Helpers/Common";
import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Label,
  LabelList,
} from "recharts";

const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="14" fill="#FFFFFF" fontWeight="Bold" />;
};
const PICKEM_TAB = "PICKEM_TAB";
const RANKPAGE_TAB = "RANKPAGE_TAB";
export const PickemsGraph = ({ pickemStats,rankpageStats }) => {
  const [currentTab, setCurrentTab] = useState(PICKEM_TAB);
  return (
    <div className="h-full flex flex-col p-4 gap-6 mt-10 bg-white roundedd-md">
      <div className="flex gap-80 justify-center">
        <div
          className={ClassNames(
            "text-sm font-medium cursor-pointer",
            currentTab === PICKEM_TAB ? " text-blue-dark" : "text-black",
          )}
          onClick={() => {
            setCurrentTab(PICKEM_TAB);
          }}
        >
          Trending Pick’em Categories
        </div>
        <div
          className={ClassNames(
            "text-sm font-medium cursor-pointer",
            currentTab === RANKPAGE_TAB ? " text-blue-dark" : "text-black",
          )}
          onClick={() => {
            setCurrentTab(RANKPAGE_TAB);
          }}
        >
          Trending Rankpage Categories
        </div>
      </div>
      <div className="flex -mt-1">
        <div
          className={ClassNames(
            "w-1/2 border-2 border-t",
            currentTab === PICKEM_TAB ? " border-blue-dark" : "border-coolGray",
          )}
        />
        <div
          className={ClassNames(
            "w-1/2 border-2 border-t",
            currentTab === RANKPAGE_TAB
              ? " border-blue-dark"
              : "border-coolGray",
          )}
        />
      </div>

      <div className="Pickems_Graph flex ">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical"
            data={currentTab === PICKEM_TAB?pickemStats:rankpageStats}
            margin={{ left: 50, right: 50 }}
          >
            <XAxis hide type="number" />
            <YAxis
              type="category"
              dataKey="name"
              stroke="black"
              fontSize="16"
              // angle={-25}
              alphabetic={true}
            />
            <Tooltip />
            <Bar
              dataKey="completed"
              fill="#D1D5DB"
              stackId="a"
              radius={[20, 20, 20, 20]}
              barSize={20}
            >
              <LabelList
                dataKey="completed"
                position="center"
                content={renderCustomizedLabel}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
