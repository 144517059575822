import { useState, useEffect } from "react";
import ClassNames from "Helpers/Common";
import { BASE_ADMIN_PATH } from "Constants/View";
import { useGlobalContext } from "Context/Global";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { AdminCreation } from "Services/API/Admin.service";
import { InputField } from "Components/Common/InputField/InputField";
import { ReactComponent as BackIcon } from "Assets/Icons/BackArrow.svg";
import { ReactComponent as PasswordIcon } from "Assets/Icons/Password.svg";
import { RadioButton } from "Components/Common/RadioButton/RadioButton";

export const CreateAdmin = () => {
  const { setActiveView } = useGlobalContext();
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [adminDetails, setAdminDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    const { firstName, lastName, email, password } = adminDetails;
    setIsDisabled(!(firstName && lastName && email && password));
  }, [JSON.stringify(adminDetails)]);

  return (
    <div className="flex gap-4 h-full">
      <div className="flex flex-col gap-3 p-4 bg-white First_Coulnm">
        <span className="flex gap-3 items-center h-4">
          <BackIcon
            className="cursor-pointer"
            onClick={() => setActiveView(BASE_ADMIN_PATH)}
          />{" "}
          Create an Admin
        </span>
      </div>
      <div className="flex flex-col gap-5 bg-white Second_Coulnm">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}
        <div className="flex flex-col gap-3 p-4 overflow-y-auto Rankpage_Form_Height">
          <AdminCreationForm
            setAdminDetails={setAdminDetails}
            adminDetails={adminDetails}
          />
        </div>
        <div
          className={ClassNames(
            "flex justify-between border-t-2 border-gray-200  p-4 xl:p-6"
          )}
        >
          <Button
            btnText="Back"
            className={"w-24 py-2 bg-white  border-gray-300"}
            isDisabled={false}
            isLoading={false}
            onClick={() => setActiveView(BASE_ADMIN_PATH)}
          />
          <Button
            btnText="Create"
            className={`w-24 py-2 text-white ${
              !isDisabled ? "bg-blue-dark" : "bg-gray-secondary text-white"
            }`}
            isDisabled={isDisabled}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                const res = await AdminCreation({
                  first_name: adminDetails?.firstName,
                  last_name: adminDetails?.lastName,
                  email: adminDetails?.email,
                  date_of_birth: "2000-01-01",
                  gender: "Other",
                  device: "Web",
                  role: "Rankpages_Admin",
                  password: adminDetails?.password,
                });
                if (res?.message) {
                  setApiError(null);
                  setIsLoading(false);
                  setActiveView(BASE_ADMIN_PATH);
                }
              } catch (err) {
                setIsDisabled(true);
                setIsLoading(false);
                setApiError(err?.response?.data?.errors || err?.message);
                console.error("[AdminCreation][API_ERROR] -", err);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

const AdminCreationForm = ({ adminDetails, setAdminDetails }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="flex flex-col gap-12 w-full">
      <div className="flex gap-4">
        <div className="w-1/2">
          <InputField
            id="first-name"
            type="text"
            label="First Name"
            isRequired={true}
            onChange={(firstName) => {
              setAdminDetails((prevState) => ({
                ...prevState,
                firstName: firstName,
              }));
            }}
          />
        </div>
        <div className="w-1/2">
          <InputField
            id="last-name"
            type="text"
            label="Last Name"
            isRequired={true}
            onChange={(lastName) => {
              setAdminDetails((prevState) => ({
                ...prevState,
                lastName: lastName,
              }));
            }}
          />
        </div>{" "}
      </div>
      <div className="flex gap-4">
        <div className="w-1/2">
          <InputField
            id="email"
            type="text"
            label="Email"
            isRequired={true}
            onChange={(email) => {
              setAdminDetails((prevState) => ({
                ...prevState,
                email: email.trim(),
              }));
            }}
            onBlur={(fieldValue, setInputFieldError) => {
              if (!(fieldValue.includes("@") && fieldValue.includes(".com"))) {
                setAdminDetails((prevState) => ({
                  ...prevState,
                  email: "",
                }));
                setInputFieldError("EMAIL_INVALID");
                //   setIsDisabled(true);
              } else if (fieldValue) {
                setAdminDetails((prevState) => ({
                  ...prevState,
                  email: fieldValue.trim(),
                }));
              }
            }}
          />
        </div>
        <div className="w-1/2">
          <InputField
            id="password"
            type={showPassword ? "text" : "Password"}
            label="Password"
            isRequired={true}
            onChange={(password) => {
              setAdminDetails((prevState) => ({
                ...prevState,
                password: password,
              }));
            }}
          >
            <PasswordIcon
              onClick={() => setShowPassword(!showPassword)}
              className="h-5 w-5 text-coolGray cursor-pointer"
              aria-hidden="true"
            />
          </InputField>
        </div>
      </div>
        <fieldset className="mt-4 space-y-6">
        <div className="space-y-4">
          <span>Set admin role</span>
          <RadioButton
            id="1"
            label="Public"
            // isSelected={detailForm.visibility === PUBLIC}
            // onChange={() => {
            //   setDetailForm((prevState) => ({
            //     ...prevState,
            //     visibility: PUBLIC,
            //   }));
            // }}
          />
          <RadioButton
            id="2"
            label="Private"
            // isSelected={detailForm.visibility === PRIVATE}
            // onChange={() => {
            //   setDetailForm((prevState) => ({
            //     ...prevState,
            //     visibility: PRIVATE,
            //   }));
            // }}
          />
        </div>
      </fieldset>
    </div>
  );
};
