import { useState, useEffect } from "react";
import ClassNames from "Helpers/Common";
import { BASE_ADMIN_PATH } from "Constants/View";
import { useGlobalContext } from "Context/Global";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import {
  AdminCreation,
  GetAdminById,
  UpdateAdmin,
} from "Services/API/Admin.service";
import { InputField } from "Components/Common/InputField/InputField";
import { ReactComponent as BackIcon } from "Assets/Icons/BackArrow.svg";
import { ReactComponent as PasswordIcon } from "Assets/Icons/Password.svg";
import { useParams } from "react-router-dom";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";

export const EditAdmin = () => {
  const { id } = useParams();
  const { setActiveView } = useGlobalContext();
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [adminDetails, setAdminDetails] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    const { firstName, lastName, email } = adminDetails;
    setIsDisabled(!(firstName && lastName && email));
  }, [JSON.stringify(adminDetails)]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      GetAdminById(id)
        .then((res) => {
          setAdminDetails({
            id: res?._id,
            firstName: res?.first_name,
            lastName: res?.last_name,
            email: res?.email,
            password: "",
          });
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("[GetAdminById][API_ERROR] - ", err);
          setIsLoading(false);
        });
    }
  }, [id]);

  return (
    <div className="flex gap-4 h-full">
      <div className="flex flex-col gap-3 p-4 bg-white First_Coulnm">
        <span className="flex gap-3 items-center h-4">
          <BackIcon
            className="cursor-pointer"
            onClick={() => setActiveView(BASE_ADMIN_PATH)}
          />{" "}
          Create an Admin
        </span>
      </div>
      <div className="flex flex-col gap-5 bg-white Second_Coulnm">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}
        <div className="flex flex-col gap-3 p-4 overflow-y-auto Rankpage_Form_Height">
          {isLoading && <Spinner />}
          {!isLoading && (
            <AdminEditForm
              setAdminDetails={setAdminDetails}
              adminDetails={adminDetails}
            />
          )}
        </div>
        {!isLoading && (
          <div
            className={ClassNames(
              "flex justify-between border-t-2 border-gray-200  p-4 xl:p-6"
            )}
          >
            <Button
              btnText="Back"
              className={"w-24 py-2 bg-white  border-gray-300"}
              isDisabled={false}
              isLoading={false}
              onClick={() => setActiveView(BASE_ADMIN_PATH)}
            />
            <Button
              btnText="Save"
              className={`w-24 py-2 text-white ${
                !isDisabled ? "bg-blue-dark" : "bg-gray-secondary text-white"
              }`}
              isDisabled={isDisabled}
              isLoading={isBtnLoading}
              onClick={async () => {
                try {
                  setIsBtnLoading(true);
                  const res = await UpdateAdmin(adminDetails?.id, {
                    first_name: adminDetails?.firstName,
                    last_name: adminDetails?.lastName,
                    email: adminDetails?.email,
                    password: adminDetails?.password
                      ? adminDetails?.password
                      : null,
                  });
                  if (res) {
                    setApiError(null);
                    setIsBtnLoading(false);
                    setActiveView(BASE_ADMIN_PATH);
                  }
                } catch (err) {
                  setIsDisabled(true);
                  setIsBtnLoading(false);
                  setApiError(err?.response?.data?.errors || err?.message);
                  console.error("[AdminCreation][API_ERROR] -", err);
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const AdminEditForm = ({ adminDetails, setAdminDetails }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="flex flex-col gap-12 w-full">
      <div className="flex gap-4">
        <div className="w-1/2">
          <InputField
            id="first-name"
            type="text"
            label="First Name"
            value={adminDetails?.firstName}
            isRequired={true}
            onChange={(firstName) => {
              setAdminDetails((prevState) => ({
                ...prevState,
                firstName: firstName,
              }));
            }}
          />
        </div>
        <div className="w-1/2">
          <InputField
            id="last-name"
            type="text"
            label="Last Name"
            value={adminDetails?.lastName}
            isRequired={true}
            onChange={(lastName) => {
              setAdminDetails((prevState) => ({
                ...prevState,
                lastName: lastName,
              }));
            }}
          />
        </div>{" "}
      </div>
      <div className="flex gap-4">
        <div className="w-1/2">
          <InputField
            id="email"
            type="text"
            label="Email"
            value={adminDetails?.email}
            isRequired={true}
            onChange={(email) => {
              setAdminDetails((prevState) => ({
                ...prevState,
                email: email.trim(),
              }));
            }}
            onBlur={(fieldValue, setInputFieldError) => {
              if (!(fieldValue.includes("@") && fieldValue.includes(".com"))) {
                setAdminDetails((prevState) => ({
                  ...prevState,
                  email: "",
                }));
                setInputFieldError("EMAIL_INVALID");
                //   setIsDisabled(true);
              } else if (fieldValue) {
                setAdminDetails((prevState) => ({
                  ...prevState,
                  email: fieldValue.trim(),
                }));
              }
            }}
          />
        </div>
        <div className="w-1/2">
          <InputField
            id="password"
            type={showPassword ? "text" : "Password"}
            label="Password"
            value={adminDetails?.password}
            isRequired={true}
            onChange={(password) => {
              setAdminDetails((prevState) => ({
                ...prevState,
                password: password,
              }));
            }}
          >
            <PasswordIcon
              onClick={() => setShowPassword(!showPassword)}
              className="h-5 w-5 text-coolGray cursor-pointer"
              aria-hidden="true"
            />
          </InputField>
        </div>
      </div>
    </div>
  );
};
