import { useEffect, useState } from "react";
import ClassNames from "Helpers/Common";
import { TextEditor } from "./TextEditor";
import { Button } from "Components/Common/Button/Button";
import { UpdateLegalDocs } from "Services/API/LegalDocs.service";
import { ReactComponent as BackIcon } from "Assets/Icons/BackArrow.svg";
import "./legalDocs.css";

const VIEW_MODE = "ViewMode";

export const EditLegalDocs = ({ docName, setActiveView }) => {
  const { title, description, id } = docName;
  const [isDisabled, setIsDisabled] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [convertedContent, setConvertedContent] = useState(null);
  useEffect(() => {
    if (description) {
      setConvertedContent(description);
    }
  }, [description]);

  return (
    <div className="flex gap-4 h-full">
      <div className="flex flex-col gap-3 p-4 bg-white First_Coulnm">
        <span className="flex gap-3 items-center h-4">
          <span
            onClick={() => setActiveView(VIEW_MODE)}
            className="cursor-pointer"
          >
            <BackIcon />
          </span>{" "}
          {title ? title : "Legal Docs"}
        </span>
      </div>
      <div className="flex flex-col  gap-3 bg-white Second_Coulnm">
        <TextEditor
          convertedContent={convertedContent||description}
          setConvertedContent={setConvertedContent}
        />

        <div className="flex justify-end border-t-2 border-gray-200  p-4 xl:p-6">
          <Button
            btnText="Save"
            className={ClassNames(
              "w-24 py-2",
              !isDisabled && "bg-blue-dark text-white"
            )}
            isDisabled={isDisabled}
            isLoading={isBtnLoading}
            onClick={() => {
              if (convertedContent) {
                setIsBtnLoading(true);
                UpdateLegalDocs(id, { title, description: convertedContent })
                  .then((res) => {
                    if (res) {
                      setIsBtnLoading(false);
                      setActiveView(VIEW_MODE);
                    }
                  })
                  .catch((err) => {
                    console.error("[UpdateLegalDocs][API_ERROR] - ", err);
                    setIsBtnLoading(false);
                  });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
