/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import Table from "Components/Common/Table/Table";
import { Button } from "Components/Common/Button/Button";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { TableHeading } from "Components/Common/Table/TableHeading";
import {
  AddExpert,
  DeleteExpert,
  EditExpert,
  GetExperts,
} from "Services/API/Experts.service";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import "Colors.css";
import { InputField } from "Components/Common/InputField/InputField";
import { Description } from "Components/Common/TextArea/Description";
import { ReactComponent as ProfileImage } from "Assets/Icons/Profile.svg";
import { ReactComponent as UploadIcon } from "Assets/Icons/UploadPic.svg";
import { getBase64, imgToBase64 } from "Helpers/Common";
import { UploadImage } from "Services/API/UploadImage.service";

const HEADINGS = ["Title", " ", " "];
const TABLE_CSS = "px-6 py-4 cursor-pointer text-sm";

const expertsFormatter = (experts) => {
  return experts.map((expert) => {
    return {
      id: expert.id,
      title: expert.first_name,
      image: expert.profile_image_url,
      description: expert.bio,
    };
  });
};

export const ExpertTable = ({ addExpert, setAddExpert }) => {
  const [isSideView, setIsSideView] = useState(false);
  const [expertList, setExpertList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [deleteExpertId, setDeleteExpert] = useState("");
  const [expertDetails, setExpertDetails] = useState({
    id: "",
    title: "",
    image: "",
    description: "",
  });
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [addExpertDetails, setAddExpertDetails] = useState({
    id: "",
    title: "",
    description: "",
    image_url: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [editExpert, setIsEditExpert] = useState(false);
  useEffect(() => {
    const { title, description, image_url } = addExpertDetails;
    if (title && description && image_url) {
      setIsDisabled(false);
    }
  }, [addExpertDetails]);

  const fetchExperts = () => {
    setIsLoading(true);

    GetExperts(pageNumber, perPage)
      .then((res) => {
        if (res) {
          setExpertList(expertsFormatter(res?.data));
          setHasNextPage(res?.pagination?.hasNext);
          setHasPreviousPage(res?.pagination?.hasPrevious);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error("[GetExperts][API_ERROR] - ", err);
        setIsLoading(false);
      });
    return () => {
      setExpertList([]);
    };
  };
  useEffect(() => {
    fetchExperts();
  }, [pageNumber]);
  console.log("add expert is", addExpert);
  return (
    <>
      <div
        className={`flex flex-col gap-4 ${
          isSideView ? "Table" : "Orignal_Table_Width"
          //  addExpert ? "Table" : "Orignal_Table_Width"
        }`}
      >
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={
            expertList?.length > 0 &&
            expertList.map((item, index) => (
              <tr
                key={index}
                className={item.hidden ? "bg-gray-100 text-gray-500" : ""}
              >
                <td className={TABLE_CSS}>
                  <div>{item.title}</div>
                </td>
                <td className={TABLE_CSS}>
                  <span
                    className="text-blue-400"
                    onClick={() => {
                      setAddExpertDetails({
                        id: item.id,
                        title: item.title,
                        image_url: item.image,
                        description: item.description,
                      });
                      setIsEditExpert((prevState) => !prevState);
                    }}
                  >
                    Edit
                  </span>
                </td>
                <td className={TABLE_CSS}>
                  <span
                    className="text-blue-400"
                    onClick={() => {
                      setExpertDetails({
                        id: item.id,
                        title: item.title,
                        image: item.image,
                        description: item.description,
                      });
                      setIsSideView((prevState) => !prevState);
                    }}
                  >
                    View
                  </span>
                </td>
                {/* <td className={`text-center TABLE_CSS`}>
                  <span
                    className="text-red-500 cursor-pointer"
                    onClick={() => {
                      setDeleteExpert(item.id);
                      DeleteExpert(item.id)
                        .then((res) => {
                          if (res) {
                            fetchExperts();
                          }
                        })
                        .catch((err) => {
                          console.error("[DeleteWidget][API_ERROR] - ", err);
                        })
                        .finally(() => {
                          setDeleteExpert("");
                        });
                    }}
                  >
                    {deleteExpertId === item.id ? (
                      <Spinner className="h-4 w-4" />
                    ) : (
                      !(isSideView || addExpert) && "Delete"
                    )}
                  </span>
                </td> */}
              </tr>
            ))
          }
        />
        {!isLoading && expertList?.length <= 0 && (
          <div className="flex bg-white w-full justify-center items-center h-96">
            No Experts found at the moment
          </div>
        )}

        {isLoading && <Spinner />}
        {!isLoading && expertList.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
      </div>
      {isSideView && (
        <SideView
          showProfileImage={true}
          setIsSideView={setIsSideView}
          heading={expertDetails.title}
          profileImage={expertDetails.image}
          onClose={() => setIsSideView(false)}
        >
          <div className="flex flex-col gap-4 pt-2">
            <div className="flex flex-col gap-2">
              <span className="raven">Description</span>
              <span className="primary_black">{expertDetails.description}</span>
            </div>
            <div className="flex flex-col gap-2">
              <span className="raven">Delete</span>
              <span className="primary_black">
                Deletion will remove this expert completely.
              </span>
              <Button
                btnText="Delete"
                className="w-24 py-2 bg_red text-white"
                isDisabled={false}
                isLoading={isBtnLoading}
                onClick={() => {
                  setIsBtnLoading(true);
                  DeleteExpert(expertDetails.id)
                    .then((res) => {
                      if (res) {
                        setIsBtnLoading(false);
                        setIsSideView(false);
                        fetchExperts();
                      }
                    })
                    .catch((err) => {
                      console.error("[DeleteExpert][API_ERROR] - ", err);
                      setIsBtnLoading(false);
                    });
                }}
              />
            </div>
          </div>
        </SideView>
      )}
      {editExpert && (
        <SideView
          showProfileImage={false}
          setIsSideView={editExpert}
          heading="Edit Expert"
        >
          <div className="flex flex-col gap-4 pt-2">
            <span>Image</span>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer bg-white rounded-md font-medium "
              >
                <span className="inline-block h-14 w-14 rounded-full overflow-hidden bg-gray-100">
                  {addExpertDetails.image_url ? (
                    <img
                      className="h-full w-full rounded-full"
                      src={addExpertDetails.image_url}
                    />
                  ) : (
                    <ProfileImage />
                  )}
                </span>
                <UploadIcon className="-mt-5 ml-7" />
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onChange={async ({ target }) => {
                    getBase64(target.files[0]).then((data) => {
                      UploadImage({
                        image: data.replace("data:", "").replace(/^.+,/, ""),
                      })
                        .then(({ url }) => {
                          setAddExpertDetails((prevState) => ({
                            ...prevState,
                            image_url: url,
                          }));
                        })
                        .catch((err) => {
                          console.eror("[UploadImage][API_ERROR] - ", err);
                        });
                    });
                  }}
                />
              </label>
            </div>

            <InputField
              id="title"
              type="title"
              label="Title"
              value={addExpertDetails?.title}
              isRequired={true}
              onBlur={(fieldValue) => {
                if (fieldValue) {
                  setAddExpertDetails((prevState) => ({
                    ...prevState,
                    title: fieldValue,
                  }));
                }
              }}
            />
            <Description
              id="description"
              type="description"
              label="Description"
              value={addExpertDetails?.description}
              isRequired={true}
              onBlur={(fieldValue) => {
                if (fieldValue) {
                  setAddExpertDetails((prevState) => ({
                    ...prevState,
                    description: fieldValue,
                  }));
                }
              }}
            />
            <Button
              btnText="Save"
              className="w-full py-2 bg_skyblue text-white"
              isDisabled={isDisabled}
              isLoading={isBtnLoading}
              onClick={() => {
                setIsBtnLoading(true);
                EditExpert(addExpertDetails?.id, {
                  first_name: addExpertDetails?.title,
                  bio: addExpertDetails?.description,
                  profile_image_url: addExpertDetails?.image_url,
                })
                  .then((res) => {
                    if (res) {
                      setIsBtnLoading(false);
                      setIsEditExpert(false)
                      fetchExperts();
                    }
                  })
                  .catch((err) => {
                    console.error("[EditExpert][API_ERROR] - ", err);
                    setIsBtnLoading(false);
                  });
              }}
            />
          </div>
        </SideView>
      )}
      {addExpert && (
        <SideView
          showProfileImage={false}
          setIsSideView={addExpert}
          heading="Add Expert"
        >
          <div className="flex flex-col gap-4 pt-2">
            <span>Image</span>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer bg-white rounded-md font-medium "
              >
                <span className="inline-block h-14 w-14 rounded-full overflow-hidden bg-gray-100">
                  {addExpertDetails.image_url ? (
                    <img
                      className="h-full w-full rounded-full"
                      src={addExpertDetails.image_url}
                    />
                  ) : (
                    <ProfileImage />
                  )}
                </span>
                <UploadIcon className="-mt-5 ml-7" />
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onChange={async ({ target }) => {
                    getBase64(target.files[0]).then((data) => {
                      UploadImage({
                        image: data.replace("data:", "").replace(/^.+,/, ""),
                      })
                        .then(({ url }) => {
                          setAddExpertDetails((prevState) => ({
                            ...prevState,
                            image_url: url,
                          }));
                        })
                        .catch((err) => {
                          console.eror("[UploadImage][API_ERROR] - ", err);
                        });
                    });
                  }}
                />
              </label>
            </div>

            <InputField
              id="title"
              type="title"
              label="Title"
              value={addExpertDetails?.title}
              isRequired={true}
              onBlur={(fieldValue) => {
                if (fieldValue) {
                  setAddExpertDetails((prevState) => ({
                    ...prevState,
                    title: fieldValue,
                  }));
                }
              }}
            />
            <Description
              id="description"
              type="description"
              label="Description"
              value={addExpertDetails?.description}
              isRequired={true}
              onBlur={(fieldValue) => {
                if (fieldValue) {
                  setAddExpertDetails((prevState) => ({
                    ...prevState,
                    description: fieldValue,
                  }));
                }
              }}
            />
            <Button
              btnText="Save"
              className="w-full py-2 bg_skyblue text-white"
              isDisabled={isDisabled}
              isLoading={isBtnLoading}
              onClick={() => {
                setIsBtnLoading(true);
                AddExpert(addExpertDetails)
                  .then((res) => {
                    if (res) {
                      setIsBtnLoading(false);
                      setAddExpert(false);
                      setIsSideView(false);
                      fetchExperts();
                    }
                  })
                  .catch((err) => {
                    console.error("[AddExpert][API_ERROR] - ", err);
                    setIsBtnLoading(false);
                  });
              }}
            />
          </div>
        </SideView>
      )}
    </>
  );
};
