import React, { useEffect, useState } from "react";
import Table from "Components/Common/Table/Table";
import { Button } from "Components/Common/Button/Button";
import { reportedPickemsFormatter } from "./APIFormatter";
import {
  DeletePickem,
  GetReportedPickem,
  HidePickem,
} from "Services/API/Pickem.service";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { EmptyData } from "Components/Common/EmptyData/EmptyData";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { Collapse } from "Components/Common/Collapseable/Collapse";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import "./Pickems.css";

const HEADINGS = ["Title", "Uploaded By", "Reported By", " "];
const TABLE_CSS = "px-6 py-4 text-sm cursor-pointer";

export const ReportedPickems = () => {
  const [isSideView, setIsSideView] = useState(false);
  const [reportedPickem, setReportedPickem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportedPickemDetails, setReportedPickemDetails] = useState({
    id: "",
    hide: "",
    title: "",
    category: "",
    eventList: "",
    uploadedBy: "",
    reportedBy: "",
  });
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(7);
  const [hideDeleteuser, setHideDeleteUser] = useState(false);
  const [isHideBtnLoading, setHideBtnLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    GetReportedPickem(pageNumber, perPage)
      .then((res) => {
        setReportedPickem(reportedPickemsFormatter(res.data));
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("[GetReportedPickem][API_ERROR] - ", err);
        setIsLoading(false);
      });
    return () => {
      setReportedPickem([]);
    };
  }, [pageNumber, hideDeleteuser]);
  return (
    <>
      <div
        className={`flex flex-col gap-4 ${
          isSideView ? "Table" : "Orignal_Table_Width"
        }`}
      >
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={
            reportedPickem.length > 0 &&
            reportedPickem.map((pickem, index) => (
              <tr
                key={index}
                className={pickem.hidden ? "bg-gray-100 text-gray-500" : ""}
              >
                <td className={TABLE_CSS}>
                  <span className="font-bold">{pickem.title}</span>
                </td>

                <td className={TABLE_CSS}>
                  <div className="font-normal text-gray-secondary">{pickem.uploadedBy}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div className="font-normal text-gray-secondary">{pickem.reportedBy}</div>
                </td>
                <td className={TABLE_CSS}>
                  <span
                    onClick={() => {
                      setReportedPickemDetails({
                        id: pickem.id,
                        hide: pickem.hidden,
                        title: pickem.title,
                        category: pickem.category,
                        eventList: pickem.eventList,
                        uploadedBy: pickem.uploadedBy,
                        reportedBy: pickem.reportedBy,
                      });
                      setIsSideView((x) => !x);
                    }}
                    className="cursor-pointer text-blue-dark"
                  >
                    {isSideView ? "" : "View"}
                  </span>
                </td>
              </tr>
            ))
          }
        />
        {isLoading ? <Spinner /> : reportedPickem.length < 0 && <EmptyData />}
        {!isLoading && reportedPickem.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
      </div>
      {isSideView && (
        <SideView
          showProfileImage={false}
          setIsSideView={setIsSideView}
          heading={reportedPickemDetails.title}
          subHeading={
            <span className="text-blue-400">
              {reportedPickemDetails.category}
            </span>
          }
        >
          <DetailsVIew
            isBtnLoading={isBtnLoading}
            setIsSideView={setIsSideView}
            setIsBtnLoading={setIsBtnLoading}
            isHideBtnLoading={isHideBtnLoading}
            setHideDeleteUser={setHideDeleteUser}
            setHideBtnLoading={setHideBtnLoading}
            reportedPickemDetails={reportedPickemDetails}
          />
        </SideView>
      )}
    </>
  );
};
const DetailsVIew = ({
  isBtnLoading,
  setIsSideView,
  setIsBtnLoading,
  isHideBtnLoading,
  setHideDeleteUser,
  setHideBtnLoading,
  reportedPickemDetails,
}) => {
  return (
    <div className="flex flex-col justify gap-6 pt-4">
      <div className="grid grid-cols-2 gap-5">
        <div className="text-gray-400">
          Uploaded By{" "}
          <div className="text-black mt-1">
            {" "}
            {reportedPickemDetails.uploadedBy}
          </div>
        </div>
        <div className="text-gray-400">
          Reported by{" "}
          <div className="text-black mt-1">
            {" "}
            {reportedPickemDetails.reportedBy}
          </div>
        </div>
      </div>
      <div className="text-gray-400">
        Delete
        <div className="text-black mt-1">
          Deletion will remove the list completely. Hiding list will only make
          it visible to you.
        </div>
      </div>
      <div className="flex gap-3">
        <Button
          btnText={reportedPickemDetails.hide ? "Unhide" : "Hide"}
          className="w-24 py-2 bg-white border border-gray-300"
          isDisabled={false}
          isLoading={isHideBtnLoading}
          onClick={() => {
            setHideBtnLoading(true);
            HidePickem(reportedPickemDetails.id)
              .then((res) => {
                if (res) {
                  setHideBtnLoading(false);
                  setHideDeleteUser((prevState) => !prevState);
                  setIsSideView(false);
                }
              })
              .catch((err) => {
                console.error("[HideRankpage][API_ERROR] - ", err);
                setHideBtnLoading(false);
              });
          }}
        />{" "}
        <Button
          btnText="Delete"
          className="w-24 py-2 bg-red-400 text-white"
          isDisabled={false}
          isLoading={isBtnLoading}
          onClick={() => {
            setIsBtnLoading(true);
            DeletePickem(reportedPickemDetails.id)
              .then((res) => {
                if (res) {
                  setIsBtnLoading(false);
                  setHideDeleteUser(true);
                  setIsSideView(false);
                }
              })
              .catch((err) => {
                console.error("[DeleteRankpage][API_ERROR] - ", err);
                setIsBtnLoading(false);
              });
          }}
        />
      </div>
      <Collapse listData={reportedPickemDetails.eventList} />
    </div>
  );
};
