import React, { Fragment, useState } from "react";
import { ReactComponent as DashboardIcon } from "Assets/Icons/DashboardSidebar.svg";
import { ReactComponent as UserProfilesIcon } from "Assets/Icons/User.svg";
import { ReactComponent as RankpagesIcon } from "Assets/Icons/Rankpages.svg";
import { ReactComponent as AnnouncmentIcon } from "Assets/Icons/Announcment.svg";
import { ReactComponent as RankpageExpertIcon } from "Assets/Icons/Star.svg";
import { ReactComponent as PickemIcon } from "Assets/Icons/Pickems.svg";
import { ReactComponent as LogoutIcon } from "Assets/Icons/Logout.svg";
import { ReactComponent as DocumentIcon } from "Assets/Icons/Document.svg";
import { ReactComponent as XIcon } from "Assets/Icons/Cross.svg";
import { ReactComponent as AppIcon } from "Assets/Icons/Logo.svg";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "Helpers/Common";
import SearchBar from "Components/Common/SearchBar/SearchBar";
import "./AppLayout.css";
import {
  BASE_ADMIN_PATH,
  BASE_ANNOUNCEMENTS_PATH,
  BASE_DASHBOARD_PATH,
  BASE_EXPERTS_PATH,
  BASE_FAQS_PATH,
  BASE_LEGAL_DOCS_PATH,
  BASE_PICKEMS_PATH,
  BASE_RANKITEMS_PATH,
  BASE_RANKPAGE_PATH,
  BASE_SEARCH_FILTER_PATH,
  BASE_USER_PROFILES_PATH,
  BASE_WIDGET_PATH,
} from "Constants/View";
import { Link } from "react-router-dom";
import { INIT_TOKEN, useGlobalContext } from "Context/Global";

export const AppLayout = ({ children }) => {
  const { setTokenDetails, getAdminRole,setActiveView } = useGlobalContext();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const visiblity = !!(getAdminRole() === "Admin");

  const navigation = [
    {
      name: "Dashboard",
      path: BASE_DASHBOARD_PATH,
      icon: DashboardIcon,
      isVisible: true,
    },
    {
      name: "User Profiles",
      path: BASE_USER_PROFILES_PATH,
      icon: UserProfilesIcon,
      isVisible: visiblity,
    },
    {
      name: "Rankpages",
      path: BASE_RANKPAGE_PATH,
      icon: RankpagesIcon,
      isVisible: true,
    },
    {
      name: "Rank Items",
      path: BASE_RANKITEMS_PATH,
      icon: PickemIcon,
      isVisible: visiblity,
    },
    {
      name: "Pick’ems",
      path: BASE_PICKEMS_PATH,
      icon: PickemIcon,
      isVisible: visiblity,
    },
    {
      name: "Announcements",
      path: BASE_ANNOUNCEMENTS_PATH,
      icon: AnnouncmentIcon,
      isVisible: visiblity,
    },
    {
      name: "Rankpage Experts",
      path: BASE_EXPERTS_PATH,
      icon: RankpageExpertIcon,
      isVisible: visiblity,
    },
    {
      name: "Rankpage Widget",
      path: BASE_WIDGET_PATH,
      icon: AnnouncmentIcon,
      isVisible: visiblity,
    },
    {
      name: "Legal Docs",
      path: BASE_LEGAL_DOCS_PATH,
      icon: DocumentIcon,
      isVisible: visiblity,
    },
    {
      name: "FAQs",
      path: BASE_FAQS_PATH,
      icon: DocumentIcon,
      isVisible: visiblity,
    },
    {
      name: "Admins",
      path: BASE_ADMIN_PATH,
      icon: UserProfilesIcon,
      isVisible: visiblity,
    },
    { name: "Logout", path: "/", icon: LogoutIcon, isVisible: true },
  ];
let path = window.location.pathname
console.log("path is",path)
  return (
    <div className="min-h-screen flex">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <AppIcon />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-gray-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-4 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className=" hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <AppIcon />
          </div>
          <div className="mt-5 flex-grow flex flex-col">
            <nav className="flex-1 px-2 pb-4 space-y-5">
              {navigation.map((item) => (
                <Link to={item.path}>
                  {item?.isVisible && (
                    <div
                      key={item.name}
                      onClick={() => {
                        setActiveTab(item.name);
                        if (item.name === "Logout")
                          setTokenDetails({ login: false, ...INIT_TOKEN });
                      }}
                      className={classNames(
                        path.includes(item.path)
                          ? item.name!=="Logout"&&"bg-gray-200 text-gray-900"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex  items-center px-2 py-5 text-sm font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.name === activeTab
                            ? "text-gray-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-3 flex-shrink-0 h-4 w-4"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </div>
                  )}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>
      {/*End Static sidebar for desktop */}
      <div className="Main_Wrapper md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <div className="flex-1 flex pl-8 items-center">
            <SearchBar
              onKeyDown={(e, searchQuery) => {
                if (e.key === "Enter") {
                  setActiveView(
                    BASE_SEARCH_FILTER_PATH.replace(":query", searchQuery)
                  );
                }
              }}
            />
          </div>
        </div>
        <div className="Main_Content_Wrapper p-6 overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};
