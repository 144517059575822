import ClassNames from "Helpers/Common";
import "./Spinner.css";

export const Spinner = ({ className }) => {
  return (
    <div className="Spinner flex justify-center">
      <div
        className={ClassNames(
          "border-l-2 border-b-2 border-gray-800 rounded-full animate-spin",
          className ? className : "w-12 h-12 "
        )}
      />
    </div>
  );
};
