import { InputField } from "Components/Common/InputField/InputField";
import { RadioButton } from "Components/Common/RadioButton/RadioButton";
import { WithMentions } from "Components/Common/WithMentions/WithMentions";
import { useEffect, useRef, useState } from "react";
import { Mention } from "react-mentions";
import { mentionsCSSGenerator } from "./MentionsCss";
import { ReactComponent as ProfileImage } from "Assets/Icons/Profile.svg";
import { TagOtherUsers } from "Services/API/Tagging.service";
import { Badge } from "Components/Common/Badge/Badge";
import { TheAddInterestsModal } from "./TheAddInterestsModal/TheAddInterestsModal";
import "./DetailForm.scss";

const flattenInterests = (interests) => {
  let subInterests = interests.map((interest) => {
    return interest.subInterests.filter((val) => val.selected === true);
  });
  return [...interests, ...subInterests.flat()];
};

const users = [
  {
    id: "0b5bde0c-9ad9-44c0-a209-7eaa4bb423d2",
    display: "Hassan Ali",
  },
  {
    id: "23422",
    display: "Ketut",
  },
  {
    id: "334534",
    display: "Gede",
  },
];
const hashtags = [
  {
    id: "001",
    display: "topMoviesonnetflix",
  },
  {
    id: "002",
    display: "bestMovies",
  },
  {
    id: "003",
    display: "cricket",
  },
  {
    id: "004",
    display: "best-bowlers",
  },
];
const PUBLIC = "Public";
const PRIVATE = "Me";

export const DetailForm = ({ setDetailForm, detailForm, setIsDisabled }) => {
  const inputRef = useRef();
  const [styledDescription, setStyledDescription] = useState("");
  const [showAddInterestsModal, setShowAddInterestsModal] = useState(false);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [removeInterests, setRemoveInterests] = useState(false);
  const { title, description, visibility, categories } = detailForm;

  useEffect(() => {
    setIsDisabled(!(title && description && visibility));
  }, [title, description, visibility]);

  useEffect(() => {
    TagOtherUsers()
      .then((res) => {})
      .catch((err) => {
        console.error("[TagOtherUsers][API_ERROR] - ", err);
      });
  }, []);
  return (
    <div>
      <InputField
        id="pickem-title"
        type="text"
        label="Title"
        isRequired={true}
        value={title}
        onChange={(contestTitle) => {
          setDetailForm((prevState) => ({ ...prevState, title: contestTitle }));
        }}
      />
      <div className="DetailForm_AddInterestsSection">
        <span className="mt-2 text-sm font-semibold">Add Interests</span>
        <div
          className="DetailForm_AddInterests bg-white border border-coolGray cursor-pointer"
          onClick={() =>
            
            setShowAddInterestsModal(categories.length === 0?true:false)
          }
        >
          {categories.length > 0 &&
            categories.map((interest) => {
              return (
                <Badge
                  id={interest.id}
                  key={interest.title}
                  title={interest.title}
                  onCrossIcon={(id) => {
                    setShowAddInterestsModal(false);
                    setDetailForm((prevState) => ({
                      ...prevState,
                      categories: categories.filter((item) => item.id !== id),
                    }));
                  }}
                  setRemoveInterests={setRemoveInterests}
                />
              );
            })}
        </div>
      </div>
      <div
        className="RankpageDetail_Description "
        onClick={() => {
          inputRef.current?.focus?.();
        }}
      >
        <span className="mt-2 text-sm font-semibold">Add Description</span>
        <WithMentions
          ref={inputRef}
          value={styledDescription}
          onChange={({ target: { value } }) => {
            setStyledDescription(value);
            setDetailForm((prevState) => ({
              ...prevState,
              description: value,
            }));
          }}
          styleGenerator={mentionsCSSGenerator}
        >
          <Mention
            trigger="@"
            data={users}
            allowSuggestionsAboveCursor={true}
            markup={`<mention>(type1:"__display__")(id:"__id__")</mention>`}
            className="MentionBasic"
            renderSuggestion={SuggestionsView}
            displayTransform={(_, display) => `@${display}`}
          />
          <Mention
            trigger="#"
            data={hashtags}
            allowSuggestionsAboveCursor={true}
            markup={`<hashtag>(type2:"__display__")(id:"__id__")</hashtag>`}
            className="MentionBasic"
            renderSuggestion={SuggestionsView}
            displayTransform={(_, display) => `#${display}`}
          />
        </WithMentions>
      </div>
      <TheAddInterestsModal
        isOpen={showAddInterestsModal}
        selectedInterests={selectedInterests}
        clearInitialSelection
        onSelect={(interests) => {
          //For checklist functionality
          setSelectedInterests(interests);
          //For display and for sending to backend
          setDetailForm((prevState) => ({
            ...prevState,
            categories: flattenInterests(interests),
          }));
        }}
        onSubmit={() => {
          // TODO check why this dispatch is occuring twice via onAdd call
          setShowAddInterestsModal(false);
        }}
        onClose={() => {
          setShowAddInterestsModal(false);
          // setDetailForm((prevState) => ({ ...prevState, categories: {...detailForm.categories,...[]} }));
        }}
      />
      <fieldset className="mt-4 space-y-6">
        <div className="space-y-4">
          <span>Set your post visibility</span>
          <RadioButton
            id="1"
            label="Public"
            isSelected={detailForm.visibility === PUBLIC}
            onChange={() => {
              setDetailForm((prevState) => ({
                ...prevState,
                visibility: PUBLIC,
              }));
            }}
          />
          <RadioButton
            id="2"
            label="Private"
            isSelected={detailForm.visibility === PRIVATE}
            onChange={() => {
              setDetailForm((prevState) => ({
                ...prevState,
                visibility: PRIVATE,
              }));
            }}
          />
        </div>
      </fieldset>
    </div>
  );
};
function SuggestionsView(entry, search, highlightedDisplay, index, focused) {
  return (
    <div
      key={entry.id + entry.display}
      className="Mentions_SuggestionsWrapper flex  items-center gap-3"
    >
      <div className="">
        {entry.src ? (
          <img src={entry.src} className="AddComment_Img" />
        ) : (
          <ProfileImage className="h-8 w-8 text-gray-300 bg-gray-400 rounded-full" />
        )}
      </div>
      <span className="text-base font-sans font-family-Roboto font-medium">
        {entry.display}
      </span>
    </div>
  );
}
