import React from "react";
import { ReactComponent as ColorDotIcon } from "Assets/Icons/Ellipse.svg";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import "./Dashboard.css";
import useWindowDimensions from "CustomHooks/useWindowDimensions";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 200 },
  { name: "Group D", value: 100 },
];

const COLORS = ["#194890", "#589BD4", "#9CA3AF", "#D1D5DB"];
const TEXT = "text-xs font-normal cursor-pointer";

export const AgeGraph = ({ ageStats }) => {
  const { width } = useWindowDimensions();
  return (
    <div className="flex flex-col p-4 2xl:p-6 gap-4 bg-white h-80 rounded-md max-w-lg">
      <div className="text-sm font-medium">Age Demographic</div>
      <div className="Demographic_Graph flex items-center 2xl:gap-20">
        <div className="flex flex-col w-20 items-center gap-6">
          <div className="flex gap-6"> 
            <ColorDotIcon className="h-5 w-3 mt-1 DarkBlue" />
            <div className={TEXT}>
              18-34
              <div className="Mischka">{(ageStats?.[1]?.value).toFixed(2)}</div>
            </div>
          </div>
          <div className="flex gap-6"> 
            <ColorDotIcon className="h-5 w-3 mt-1 LightBlue" />
            <div className={TEXT}>
              35-54
              <div className="Mischka">{(ageStats?.[2]?.value).toFixed(2)}</div>
            </div>
          </div>
          <div className="flex gap-6"> 
            <ColorDotIcon className="h-5 w-3 mt-1 Gray" />
            <div className={TEXT}>
              55-64 <div className="Mischka">{(ageStats?.[3]?.value).toFixed(2)}</div>
            </div>
          </div>
          <div className="flex gap-6"> 
            <ColorDotIcon className="h-5 w-3 mt-1 LightGray" />
            <div className={TEXT}>
              65+ <div className="Mischka">{(ageStats?.[4]?.value).toFixed(2)}</div>
            </div>
          </div>
        </div>
        <ResponsiveContainer width="80%" height="100%">
          <PieChart width={400} height={400}>
            <Pie
              data={ageStats}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={width >= 1920 ? 101 : width >= 1366 ? 90 : 60}
              fill="#8884d8"
              dataKey="value"
            >
              {ageStats.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
