import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for getting the legal docs
 * @returns all of the legal docs
 */
export const FetchLegalDocs = async () => {
  const {
    data: { result },
  } = await Axios.get(`${API_BASE}admin/legaldoc/all`);
  console.log(`[FetchLegalDocs][API_RESPONSE]`, result);
  return result;
};

/**
 * @description  An API for updating the legal documents
 * @param {String} id of the legal doc
 * @param {*} payload title and description of the document
 * @returns 
 */
export const UpdateLegalDocs = async (id,payload) => {
    const {
      data: { result },
    } = await Axios.patch(`${API_BASE}admin/legaldoc/${id}`,payload);
    console.log(`[UpdateLegalDocs][API_RESPONSE]`, result);
    return result;
  };