import { useGlobalContext } from "Context/Global";
import { Route, Redirect } from "react-router-dom";
export default function ProtectedRoute({ component: Component, ...props }) {
  const { getToken } = useGlobalContext();
  const isAuthenticated = getToken();
  return (
    <Route
      {...props}
      render={(props) =>
        isAuthenticated ? <Component {...props} />: <Redirect from="*" to="/" />
      }
    />
  );
}
