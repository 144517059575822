import { useEffect, useState } from "react";
import Table from "Components/Common/Table/Table";
import { EDIT_ADMIN_PATH } from "Constants/View";
import { useGlobalContext } from "Context/Global";
import { Button } from "Components/Common/Button/Button";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { DeleteAdmin, ListingAdmins } from "Services/API/Admin.service";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";

const formattedAdmins = (admins) => {
	return admins?.map((admin) => {
		return {
			id: admin.id,
			userId: admin?.user_id,
			firstName: admin.first_name ?? "",
			lastName: admin.last_name ?? "",
			email: admin.email,
		};
	});
};
const HEADINGS = ["Name", "Email", " ", " "];
const TABLE_CSS = "px-6 py-4 cursor-pointer text-sm";

export const AdminsListing = () => {
	const { setActiveView } = useGlobalContext();
	const [error, setError] = useState("");
	const [admins, setAdmins] = useState([]);
	const [perPage, setPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [isSideView, setIsSideView] = useState(false);
	const [hasNextPage, setHasNextPage] = useState(false);
	const [hasPreviousPage, setHasPreviousPage] = useState(false);
	const [adminDetails, setAdminDetails] = useState({
		id: "",
		email: "",
		firstName: "",
		lastName: "",
	});
	const fetchAdmins = () => {
		setIsLoading(true);
		ListingAdmins(pageNumber, perPage)
			.then((res) => {
				setAdmins(formattedAdmins(res?.data));
				setHasNextPage(res?.pagination?.hasNext);
				setHasPreviousPage(res?.pagination?.hasPrevious);
				setIsLoading(false);
				setError("");
			})
			.catch((err) => {
				setIsLoading(false);
				setError("Something went wrong try again later");
				console.error("[AdminsListing][API_ERROR] -", err);
			});
		return () => {
			setAdmins([]);
		};
	};
	useEffect(() => {
		fetchAdmins();
	}, [pageNumber]);

	return error ? (
		<div className="flex justify-center items-center h-96">{error}</div>
	) : (
		<>
			<div
				className={`flex flex-col gap-4 ${
					isSideView ? "Table" : "Orignal_Table_Width"
				}`}
			>
				<Table
					heading={<TableHeading TableHeader={HEADINGS} />}
					body={
						admins.length > 0 &&
						admins.map((admin, index) => (
							<tr key={index}>
								<td className={TABLE_CSS}>
									<div>{admin.firstName + " " + admin.lastName}</div>
								</td>
								<td className={TABLE_CSS}>
									<div>{admin.email}</div>
								</td>

								<td className="px-4 py-4 text-left text-sm font-medium">
									<span
										onClick={() => {
											setAdminDetails({
												id: admin.id,
												firstName: admin.firstName,
												lastName: admin.lastName,
												email: admin?.email,
											});
											setIsSideView((prevState) => !prevState);
										}}
										className="cursor-pointer text-blue-dark"
									>
										View
									</span>
								</td>
								<td className={TABLE_CSS}>
									<div
										className="cursor-pointer text-blue-dark"
										onClick={() => {
											setIsSideView((prevState) => !prevState);
											setActiveView(
												EDIT_ADMIN_PATH.replace(":id", admin.userId),
											);
										}}
									>
										Edit
									</div>
								</td>
							</tr>
						))
					}
				/>
				{isLoading && <Spinner />}
				{!isLoading && admins.length > 0 && (
					<PaginationBtns
						isNextDisabled={!hasNextPage}
						isPrevDisabled={!hasPreviousPage}
						onNext={() => {
							if (hasNextPage) {
								setPageNumber(pageNumber + 1);
							}
						}}
						onPrevious={() => {
							if (hasPreviousPage) {
								setPageNumber(pageNumber - 1);
							}
						}}
					/>
				)}
			</div>
			{isSideView && (
				<SideView
					setIsSideView={setIsSideView}
					heading={adminDetails.firstName + " " + adminDetails?.lastName}
					subHeading={adminDetails.email}
					showProfileImage={false}
				>
					<div className="flex flex-col justify gap-6 pt-4">
						<div className="grid grid-cols-2 gap-5">
							<div className="text-gray-400">
								Name{" "}
								<div className="text-black mt-1">
									{adminDetails.firstName + " " + adminDetails?.lastName}
								</div>
							</div>
							<div className="text-gray-400">
								Email{" "}
								<div className="text-black mt-1">{adminDetails.email}</div>
							</div>
						</div>
						<div className="text-gray-400">
							Delete Admin
							<div className="text-black mt-1">
								User will not be able to login into their account if they are
								deleted.
							</div>
						</div>
						<Button
							btnText="Delete"
							className="w-24 py-2 bg-red-400 text-white"
							isDisabled={false}
							isLoading={isLoading}
							onClick={async () => {
								setIsLoading(true);
								try {
									const res = await DeleteAdmin(adminDetails?.id);
									if (res) {
										setIsLoading(false);
										setIsSideView((prevState) => !prevState);
										fetchAdmins();
									}
								} catch (err) {
									setIsLoading(false);
									console.error("[DeleteAdmin][API_ERROR] - ", err);
								}
							}}
						/>
					</div>
				</SideView>
			)}
		</>
	);
};
