import  { useEffect,useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as SearchIcon } from "Assets/Icons/Search.svg";
import "./SearchBar.css";

export default function SearchBar({
  placeholder = "Search",
  label = "Search",
  onChange = () => {},
  onKeyDown = () => {},
}) {
  const {query} =useParams();
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    if(query) {
      setSearchQuery(query);
    }
  },[query])
  return (
    <div className="w-full">
      <label htmlFor={label} className="sr-only">
        Search
      </label>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          id="search"
          name="search"
          value={searchQuery}
          className="SearchBar block  bg-white border border-gray-300 rounded-md py-2 pl-10  pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder={placeholder}
          type="search"
          onChange={({ target }) => {
            onChange?.(target?.value);
            setSearchQuery(target?.value);
          }}
          onKeyPress={(e) => onKeyDown?.(e, searchQuery)}
        />
      </div>
    </div>
  );
}
