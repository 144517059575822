import { useEffect, useState } from "react";
import ClassNames from "Helpers/Common";
import { useGlobalContext } from "Context/Global";
import { Button } from "Components/Common/Button/Button";
import { BASE_ANNOUNCEMENTS_PATH } from "Constants/View";
import { Description } from "Components/Common/TextArea/Description";
import { InputField } from "Components/Common/InputField/InputField";
import { CreateAnnouncement } from "Services/API/Announcement.service";
import { ReactComponent as BackIcon } from "Assets/Icons/BackArrow.svg";

export const AnnouncementCreation = () => {
  const { setActiveView } = useGlobalContext();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  useEffect(() => {
    setIsDisabled(!(title && description));
  }, [title, description]);
  return (
    <div className="flex gap-4 h-full">
      <div className="flex flex-col gap-3 p-4 bg-white First_Coulnm">
        <span className="flex gap-3 items-center h-4">
          <BackIcon
            onClick={() => setActiveView(BASE_ANNOUNCEMENTS_PATH)}
            className="cursor-pointer"
          />{" "}
          Create a Announcement
        </span>
      </div>
      <div className="flex flex-col  gap-3 bg-white Second_Coulnm p-4">
        <div className="FAQS_Content_Wrapper flex flex-col gap-4">
          <InputField
            id="title"
            type="title"
            label="Title"
            value={title}
            isRequired={true}
            onChange={(fieldValue) => setTitle(fieldValue)}
            onBlur={(fieldValue, setInputFieldError) => {
              if (!fieldValue) {
                setInputFieldError("Title is required");
              } else if (fieldValue) {
                setTitle(fieldValue);
              }
            }}
          />
          <Description
            rows={18}
            id="description"
            type="description"
            value={description}
            label="Description"
            isRequired={true}
            onChange={(fieldValue) => {
              setDescription(fieldValue);
            }}
          />
        </div>
        <div className="flex justify-end border-t-2 border-gray-200  p-4 xl:p-6">
          <Button
            btnText="Save"
            className={ClassNames(
              "w-24 py-2",
              !isDisabled && "bg-blue-dark text-white"
            )}
            isDisabled={isDisabled}
            isLoading={isBtnLoading}
            onClick={async () => {
              if (title && description) {
                setIsBtnLoading(true);

                await CreateAnnouncement({
                  title,
                  description,
                })
                  .then((res) => {
                    if (res?.id) {
                      setIsBtnLoading(false);
                      setActiveView(BASE_ANNOUNCEMENTS_PATH);
                    }
                  })
                  .catch((err) => {
                    console.error("[CreateAnnouncement][API_ERROR] - ", err);
                    setIsBtnLoading(false);
                  });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
