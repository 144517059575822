import { useEffect, useState } from "react";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import Table from "Components/Common/Table/Table";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { formatDate } from "Helpers/DateFormaater";
import { GetReportedItems } from "Services/API/RankItems.service";

const HEADINGS = ["Title", "Uploaded By", "Reported BY", " "];
const TABLE_CSS = "px-6 py-4 cursor-pointer text-sm";

export const ReportedRankItems = () => {
  const [reportedRankItems, setReportedRankItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    GetReportedItems()
      .then((res) => {
        setReportedRankItems([...res?.data]);
        setLoading(false);
      })
      .catch((err) => {
        console.error("[GetReportedItems][API_ERROR]", err);
        setLoading(false);
      });
  }, []);
  return (
    <div>
      {" "}
      {isLoading && <Spinner />}
      {!isLoading && (
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={
            reportedRankItems?.length > 0 &&
            reportedRankItems.map((item, index) => (
              <tr
                key={index}
                className={item.hidden ? "bg-gray-100 text-gray-500" : ""}
              >
                <td className={TABLE_CSS}>
                  <div>{item.title}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div>{(item.uploadedBy).replaceAll("null","")}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div>{formatDate(item.createdAt, "MM/DD/YYYY")}</div>
                </td>

                <td className="px-4 py-4 text-left text-sm font-medium">
                  <span
                    // onClick={() => {
                    //   setRankpageUserDetails({
                    //     id: item.id,
                    //     hide: item.hidden,
                    //     title: item.title,
                    //     uploadedBy: item.uploadedBy,
                    //     uploadDate: item.uploadedDate,
                    //     rankLists: item.rankLists,
                    //     category: item.category,
                    //   });
                    //   setIsSideView((x) => !x);
                    // }}
                    className="cursor-pointer text-blue-dark"
                  >
                    View
                    {/* {isSideView ? "" : "View"} */}
                  </span>
                </td>
              </tr>
            ))
          }
        />
      )}
      {!isLoading &&reportedRankItems?.length <= 0 &&<div className="flex bg-white w-full justify-center items-center h-96">No Reported RankItems found at the moment</div>}

    </div>
  );
};
