import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for getting the main categories
 * @param {Number} pageNo current page number
 * @param {Number} perPage no of records to retrieve
 * @returns the main categories
 */
export const GetMainCategories = async (pageNo = 1, perPage = 500) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}user/category/discover?perPage=${perPage}&page=${pageNo}`
  );
  console.log(`[GetMainCategories][API_RESPONSE]`, result);
  return result;
};
