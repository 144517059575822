import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for getting the announcements
 * @returns all of the announcements
 */
export const GetAnnouncements = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/announcement?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetAnnouncements][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for creating an announcement
 * @param {Object} payload detials of the announcements
 * Payload_Example {
    "title": "We have made some changes!",
    "description": "We have now introduced more privacy options, so that you feel safer while using Rankpage. Check out these options in your settings."
}
 * @returns 
 */
export const CreateAnnouncement = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}admin/announcement`, payload);
  console.log(`[CreateAnnouncement][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for deleting a announcement
 * @param {String} announcement_Id
 * @returns
 */
export const DeleteAnnouncement = async (announcement_Id) => {
  const {
    data: { result },
  } = await Axios.delete(`${API_BASE}admin/announcement/${announcement_Id}`);
  console.log(`[DeleteAnnouncement][API_RESPONSE]`, result);
  return result;
};
