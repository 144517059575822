import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for getting the faqs
 * @param {Number} pageNo current page number
 * @param {Number} perPage total number of records to retrieve
 * @returns
 */
export const GetFAQs = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(`${API_BASE}admin/faq?page=${pageNo}&perPage=${perPage}`);
  console.log(`[GetFAQs][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for creaeting the faq
 * @param {Object} payload to create an faq 
 * Payload_Example :{
 *  "question": "I forgot my password",
    "answer": "# Header 1\n  ## Header 2\n\n  _ italic _\n\n  ** bold **\n\n  <b> bold Html </b>"
 * } 
 * @returns 
 */
export const CreateFAQs = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}admin/faq`, payload);
  console.log(`[CreateFAQs][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for updating the faq
 * @param {String} id of the faq
 * @param {*} payload
 * @returns
 */
export const UpdateFAQs = async (id, payload) => {
  const {
    data: { result },
  } = await Axios.patch(`${API_BASE}admin/faq/${id}`, payload);
  console.log(`[UpdateFAQs][API_RESPONSE]`, result);
  return result;
};
