import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import "./ModalBasic.css";
import ClassNames from "Helpers/Common";

export default function ModalBasic({
  open,
  setOpen,
  className,
  children
}) {

  const themes  ="light"
  const modalWrapperRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className={ClassNames("ModalBasic fixed z-10 inset-0 overflow-y-auto",className)}
        onClose={setOpen}
        initialFocus={modalWrapperRef}
      >
        <div
          ref={modalWrapperRef}
          className={themes}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="Modal_Overlay" />
          </Transition.Child>

          {/* TODO - remove this span, go with flex strategy for positioning content */}
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* TODO - remove inline-block when adding flex implementation later */}
              <div className={ClassNames(
                "inline-block align-middle rounded-lg shadow-xl transform transition-all",
                "Modal_ContentWrapper"
              )}>
                {children}
              </div>
            </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root >
  );
}
