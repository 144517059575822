import { useEffect, useState } from "react";
import { ReactComponent as AddIcon } from "Assets/Icons/add.svg";
import { XIcon } from "@heroicons/react/solid";
import "./RankList.css";
import {
  FetchQueriedRankpageItems,
  FetchRankpageItems,
} from "Services/API/Widget.service";
import AutoComplete from "Components/Common/AutoComplete";
import { Button } from "Components/Common/Button/Button";
import { Divider } from "Components/Common/Divider/Divider";
import { InputField } from "Components/Common/InputField/InputField";
import ModalBasic from "Components/Common/ModalBasic";
import { RankedItem } from "Components/Common/RankedItem/RankedItem";
import { UploadPicture } from "Components/Common/UploadPicture/UploadPicture";
import { AddItem, SearchItem } from "Services/API/Ranklists.service";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const RankList = ({
  setSelectedItems,
  itemsLimit = 10000,
  setIsDisabled,
}) => {
  const [rankedItems, setRankedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    setRankedItems((prevExercises) => {
      const result = Array.from(prevExercises); // Create a new array to not mutate the previous one
      const [removed] = result.splice(source.index, 1); // Removes the drag item from the array by using the start index
      result.splice(destination.index, 0, removed); // Inserts the moved item at its new position using the end index
      return result; // Returns the reordered array to the hook to be saved
    });
  };
  useEffect(() => {
    setIsDisabled?.(true);
    FetchRankpageItems()
      .then(({ rankItems }) => {
        setSuggestionsList(rankItems);
      })
      .catch((error) => {
        console.error("[FetchRankpageItems][API_ERROR] - ", error);
      });
  }, []);

  useEffect(() => {
    if (searchQuery) {
      FetchQueriedRankpageItems(searchQuery)
        .then(({ rankItems }) => {
          if (rankItems?.length > 0) {
            setSuggestionsList([...suggestionsList, ...rankItems]);
          }
        })
        .catch((error) => {
          console.error("[FetchRankpageItems][API_ERROR] - ", error);
        });
    }
  }, [searchQuery]);
  return (
    <>
      <div className="RankList_HeaderWrapper">
        <div className="RankListHeader_SearchWrapper bg-black-light">
          <AutoComplete
            value={searchQuery}
            list={suggestionsList}
            onSelect={(title, item) => {
              setSearchQuery(title);
              const itemExists = rankedItems
                .map((rankedItem) => {
                  return rankedItem.title;
                })
                .includes(item.title);
              if (!itemExists && rankedItems.length < itemsLimit) {
                setRankedItems([...rankedItems, item]);
                setSelectedItems([...rankedItems, item]);
              }
            }}
            onChange={({ target }) => {
              setSearchQuery(target.value);
            }}
            setValue={setSearchQuery}
          />{" "}
        </div>
        <Divider type="vertical" />
        <button className="RankList_AddBtn" onClick={() => setShowModal(true)}>
          Add Item
          <AddIcon />
        </button>
      </div>
      <div className="RankList_Rankings">
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <Droppable droppableId="items">
            {(provided, snapshot) => {
              return (
                <div
                  className="flex flex-col gap-3 overflow-y-auto"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {rankedItems?.map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              className="rounded-md flex gap-2 items-center max-w-md"
                            >
                              {index}
                              <RankedItem
                                key={index}
                                ranking={index + 1}
                                title={item.title}
                                imgUrl={item.image_url}
                                icon={
                                  <XIcon
                                    onClick={() => {
                                      setRankedItems(
                                        rankedItems.filter(
                                          (rankedItem) =>
                                            rankedItem.title !== item.title
                                        )
                                      );
                                    }}
                                    className="h-6 w-6 "
                                    aria-hidden="true"
                                  />
                                }
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
      <TheAddItemModal
        open={showModal}
        setOpen={setShowModal}
        onSubmit={(newRankedItem) => {
          setShowModal(false);
          setRankedItems([...rankedItems, newRankedItem]);
          setSelectedItems([...rankedItems, newRankedItem]);
        }}
      />
    </>
  );
};

function TheAddItemModal({ open, setOpen, onSubmit }) {
  const [title, setTitle] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  useEffect(() => {
    return () => {
      if (!open) {
        setTitle("");
        setImgUrl("");
      }
    };
  }, [open]);
  useEffect(() => {
    setIsDisabled(!(title && imgUrl));
  }, [title, imgUrl]);
  return (
    <ModalBasic open={open} setOpen={setOpen} className={"TheAddItemModal"}>
      <div className="TheAddItemModal_Wrapper bg-white w-96 p-4 border border-coolGray rounded-md">
        <XIcon
          className="h-6 w-6 dark:text-gray-400 cursor-pointer"
          aria-hidden="true"
          onClick={() => setOpen(false)}
        />
        <InputField
          id="item-title"
          name="Add Item"
          label="Add Item"
          labelPosition="col"
          value={title}
          onBlur={(title, setFieldError) => {
            SearchItem({ title })
              .then((res) => {
                res && setTitle(title);
              })
              .catch((err) => {
                console.error("[SearchItem][API_ERROR] - ", err);
                setFieldError("Item already exists");
                setIsDisabled(true);
              });
          }}
        />
        <UploadPicture
          id="upload-rankItem-picture"
          label="Select a Photo"
          onImageUpload={(photo) => {
            setImgUrl(photo);
          }}
        />
        <Button
          btnText="Submit Item"
          className={`w-full h-8 ${!isDisabled && "bg-blue-dark text-white"}`}
          isDisabled={isDisabled}
          isLoading={isBtnLoading}
          onClick={() => {
            setIsBtnLoading(true);
            AddItem({
              title,
              description: "",
              image_url: imgUrl,
            })
              .then((res) => {
                res &&
                  onSubmit({
                    id: res,
                    title: title,
                    image_url: imgUrl,
                  });
                setIsBtnLoading(false);
              })
              .catch((err) => {
                console.error("[AddItem][API_ERROR] - ", err);
                setIsBtnLoading(false);
              });
          }}
        />
      </div>
    </ModalBasic>
  );
}
