import { useState } from "react";
import { Button } from "Components/Common/Button/Button";
import { Collapse } from "Components/Common/Collapseable/Collapse";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { DeleteRankpage, HideRankpage } from "Services/API/Ranklists.service";
import { ConfirmationModal } from "./ConfirmationModal";

export const ViewDetails = ({
  setIsSideView,
  rankpageDetails,
  setHideDeleteUser,
  onDelete,
  children,
}) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isHideModalOpen, setIsHideModalOpen] = useState(false);
  const [isHideBtnLoading, setHideIsBtnLoading] = useState(false);
  const [isDeleteModalOpen, setisDeleteModalOpen] = useState(false);
  return (
    <>
      <SideView
        showProfileImage={false}
        setIsSideView={setIsSideView}
        heading={rankpageDetails.title}
        subHeading={
          <span className="text-blue-400">{rankpageDetails.category}</span>
        }
      >
        <div className="flex flex-col justify gap-6 pt-4">
          <div className="grid grid-cols-2 gap-5">{children}</div>
          <div className="text-gray-400">
            Delete
            <div className="text-black mt-1">
              Deletion will remove the list completely. Hiding list will only
              make it visible to you.
            </div>
          </div>
          <div className="flex gap-3">
            <Button
              btnText={rankpageDetails.hide ? "Unhide" : "Hide"}
              className="w-24 py-2 bg-white border border-gray-300"
              isDisabled={false}
              isLoading={false}
              onClick={() => {
                setIsHideModalOpen(true);
              }}
            />{" "}
            <Button
              btnText="Delete"
              className="w-24 py-2 bg-red-400 text-white"
              isDisabled={false}
              isLoading={false}
              onClick={() => {
                setisDeleteModalOpen(true);
              }}
            />
          </div>
          <Collapse listData={rankpageDetails.rankLists} />
        </div>
      </SideView>
      {isDeleteModalOpen && (
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => {
            setisDeleteModalOpen(false);
          }}
          isBtnLoading={isBtnLoading}
          onBtnClick={async () => {
            setIsBtnLoading(true);
            DeleteRankpage(rankpageDetails.id)
              .then((res) => {
                if (res) {
                  setisDeleteModalOpen(false);
                  setIsBtnLoading(false);
                  setHideDeleteUser(true);
                  setIsSideView(false);
                  onDelete?.();
                }
              })
              .catch((err) => {
                console.error("[DeleteRankpage][API_ERROR] - ", err);
                setIsBtnLoading(false);
              });
          }}
        >
          <div className="flex flex-col gap-1 text-base font-medium text-left pb-4">
            Are you sure you want to delete this Rankpage?
            <div className="text-gray-secondary">
              Deletion will remove the rankpage completely.
            </div>
          </div>
        </ConfirmationModal>
      )}
      {isHideModalOpen && (
        <ConfirmationModal
          heading={rankpageDetails.hide ? "Unhide Rankpage" : "Hide Rankpage"}
          isOpen={isHideModalOpen}
          onClose={() => {
            setIsHideModalOpen(false);
          }}
          btnText={rankpageDetails.hide ? "Unhide" : "Hide"}
          isBtnLoading={isHideBtnLoading}
          onBtnClick={async () => {
            setHideIsBtnLoading(true);
            HideRankpage(rankpageDetails.id)
              .then((res) => {
                if (res) {
                  setHideDeleteUser((prevState) => !prevState);
                  setIsSideView(false);
                }
              })
              .catch((err) => {
                console.error("[HideRankpage][API_ERROR] - ", err);
                setHideIsBtnLoading(false);
              });
          }}
        >
          <div className="flex flex-col gap-1 text-base font-medium text-left pb-4">
            {rankpageDetails.hide
              ? "Are you sure you want to unHide this Rankpage?"
              : "Are you sure you want to hide this Rankpage?"}
            <div className="text-gray-secondary">
              {rankpageDetails.hide
                ? "This rankpage will be visible to users."
                : "This rankpage won’t be visible to users."}
            </div>
          </div>
        </ConfirmationModal>
      )}
    </>
  );
};
