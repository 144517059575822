import { useState } from "react";
import { WidgetTable } from "./Widget";
import { useGlobalContext } from "Context/Global";
import { Tabs } from "Components/Common/Tabs/Tabs";
import { CREATE_WIDGET_PATH } from "Constants/View";
import { Button } from "Components/Common/Button/Button";

const tab = [{ name: "Widget" }];

export const Widget = () => {
  const { setActiveView } = useGlobalContext();
  const [activeTab, setActiveTab] = useState(tab[0].name);

  return (
    <>
      <span>Rankpage Widget</span>
      <div className="flex justify-between">
        <Tabs tabs={tab} activeTab={activeTab} onTab={(tabName) => {}} />
        <Button
          btnText="Create a Poll"
          className="w-36 h-8 bg-blue-400 text-white"
          isDisabled={false}
          isLoading={false}
          onClick={() => setActiveView(CREATE_WIDGET_PATH)}
        />
      </div>
      <br />
      <WidgetTable />
    </>
  );
};
