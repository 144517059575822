import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

const formatInterests = ({ data, pagination }) => {
  return {
    // we do not format interest array
    interests: data.map((instance) => {
      return {
        id: instance.id,
        description: instance.description,
        imageUrl: instance.imageUrl,
        selected: instance.is_selected,
        title: instance.title,
        subInterests: formatSubInterests(instance),
      };
    }),
    hasNext: pagination.hasNext,
    hasPrevious: pagination.hasPrevious,
    page: pagination.page,
    perPage: pagination.perPage,
  };
};
const formatSubInterests = ({ sub_categories }) => {
  return sub_categories.map((subCategory) => {
    return {
      id: subCategory.id,
      description: subCategory.description,
      imageUrl: subCategory.image_url,
      selected: subCategory.is_selected,
      title: subCategory.title,
    };
  });
};

/**
 * @description  An API for getting ranklists
 * @param {*} pageNo current page number
 * @param {*} perPage no of records to retrieve
 * @returns return the ranklists
 */
export const GetLists = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/ranklist/all?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetLists][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting trending ranklists
 * @param {*} pageNo current page number
 * @param {*} perPage no of records to retrieve
 * @returns return the trending ranklists
 */
export const GetTrendingLists = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/ranklist/trending?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetTrendingLists][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting reported ranklists
 * @param {*} pageNo current page number
 * @param {*} perPage no of records to retrieve
 * @returns return the reported ranklists
 */
export const GetReportedRankpages = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/ranklist/reported?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetReportedRankpages][API_RESPONSE]`, result);
  return result;
};

/**
 * @description  An API for deleteing rankpage
 * @param {String} id Rankpage id for deletion
 * @returns
 */
export const DeleteRankpage = async (id) => {
  const {
    data: { result },
  } = await Axios.delete(`${API_BASE}admin/ranklist/${id}`);
  console.log(`[DeleteRankpage][API_RESPONSE]`, result);
  return result;
};

/**
 * @description  An API for hiding rankpage
 * @param {String} id Rankpage id for hiding
 * @returns
 */
export const HideRankpage = async (id) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}admin/ranklist/hide/${id}`);
  console.log(`[HideRankpage][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for creating a rankpage
 * @param {Object} payload to create a rank page 
 * Payload_Example :{
 *  "title" : "Top Best Seller Movies original",
    "description" : "Best Movies <hashtag:{\"value\":\"bestMovies\",\"id\":null}>. <mention:{\"name\":\"Hassan Ali\",\"id\":\"0b5bde0c-9ad9-44c0-a209-7eaa4bb423d2\"}>\n\n\n<hashtag:{\"value\":\"topMoviesonnetflix\",\"id\":null}>. Best Movies <hashtag:{\"value\":\"bestMovies\",\"id\":null}>. <mention:{\"name\":\"Hassan Ali\",\"id\":\"0b5bde0c-9ad9-44c0-a209-7eaa4bb423d2\"}>\n\n\n<hashtag:{\"value\":\"topMoviesonnetflix\",\"id\":null}>",
    "visibility":"Public",
    "ordered_items" : ["61b1edec354c49aaeff5c00b","61b1ede8354c49aaeff5c00a"],
    "categories" : ["61b1ed20354c49aaeff5c006"]
 * } 
 * @returns 
 */
export const CreateRanklist = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}ranklist`, payload);
  console.log(`[CreateRanklist][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting the categories
 * @returns the categories
 */
export const GetInterests = async () => {
  const {
    data: { result },
  } = await Axios.get(`${API_BASE}user/category/discover?page=1&perPage=100`);
  console.log(`[GetInterests][API_Response] - `, result);
  return formatInterests(result);
};
/**
 * @description  An API for searching a ranklist item
 * @param {String} title to search for the ranklist item if it exists
 * @returns
 */
export const SearchItem = async ({title = ""}) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}ranklist/item/all?page=1&title=${title}&perPage=10`
  );
  console.log(`[SearchItem][API_Response] - `, result);
  return formatInterests(result);
};
/**
 * @description  An API for adding a new ranklist item
 * @param {Object} payload for adding a new ranklist item
 * Payload_Example :{
 * "title": "Gladiator I",
    "description": "Movies",
    "image_url": "https://image.shutterstock.com/image-vector/film-reel-vector-icon-graphic-260nw-455037928.jpg"
 * }
 * @returns 
 */
export const AddItem = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}ranklist/item`, payload);
  console.log(`[AddItem][API_Response] - `, result);
  return result;
};
