import ClassNames from "Helpers/Common";

export const DropdownOption = ({
  id,
  value,
  label,
  isDefaultOption = false,
  className,
}) => {
  return (
    <option
      id={id}
      key={id}
      value={value}
      disabled={isDefaultOption}
      hidden={isDefaultOption}
      className={ClassNames("DropdownOption", className)}>
      {label}
    </option>
  );
};
