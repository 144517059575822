import { useEffect, useState } from "react";
import { Button } from "Components/Common/Button/Button";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { InputField } from "Components/Common/InputField/InputField";
import { ReactComponent as ProfileImage } from "Assets/Icons/Profile.svg";
import { ReactComponent as UploadIcon } from "Assets/Icons/UploadPic.svg";
import { getBase64 } from "Helpers/Common";
import { UploadImage } from "Services/API/UploadImage.service";
import "Colors.css";
import { AddRankItem, EditRankItem } from "Services/API/RankItems.service";
import { ApiError } from "Components/Common/ApiError";

export const AddRankItems = ({
  setAddRankItem,
  onAddedItem,
  adminItemDetails
}) => {
  const [addExpertDetails, setAddExpertDetails] = useState({
    title: "",
    image_url: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const { title, imageUrl } = adminItemDetails;
  useEffect(() => {
    setIsDisabled(!(addExpertDetails.image_url && addExpertDetails.title));
  }, [JSON.stringify(addExpertDetails)]);
  useEffect(() => {
    setAddExpertDetails({
      title: title,
      image_url: imageUrl,
    });
  }, [title, imageUrl]);
  return (
    <SideView
      showProfileImage={false}
      setIsSideView={setAddRankItem}
      heading="Add Rank Item"
    >
      <div className="flex flex-col gap-4 pt-2">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}
        <span>Image</span>
        <div className="flex text-sm text-gray-600">
          <label
            htmlFor="file-upload"
            className="relative cursor-pointer bg-white rounded-md font-medium "
          >
            <span className="inline-block h-14 w-14 rounded-full overflow-hidden bg-gray-100">
              {addExpertDetails.image_url || imageUrl ? (
                <img
                  className="h-full w-full rounded-full"
                  src={imageUrl || addExpertDetails.image_url}
                />
              ) : (
                <ProfileImage />
              )}
            </span>
            <UploadIcon className="-mt-5 ml-7" />
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              className="sr-only"
              onChange={async ({ target }) => {
                getBase64(target.files[0]).then((data) => {
                  UploadImage({
                    image: data.replace("data:", "").replace(/^.+,/, ""),
                  })
                    .then(({ url }) => {
                      setAddExpertDetails((prevState) => ({
                        ...prevState,
                        image_url: url,
                      }));
                    })
                    .catch((err) => {
                      console.error("[UploadImage][API_ERROR] - ", err);
                    });
                });
              }}
            />
          </label>
        </div>

        <InputField
          id="title"
          type="title"
          label="Title"
          isRequired={true}
          value={title || addExpertDetails?.title}
          onChange={(fieldValue) => {
            if (fieldValue) {
              setAddExpertDetails((prevState) => ({
                ...prevState,
                title: fieldValue,
              }));
            }
          }}
          onBlur={(fieldValue, setInputFieldError) => {
            if (!fieldValue) {
              setInputFieldError("Title is required");
              setIsDisabled(true);
            } else if (fieldValue) {
              setAddExpertDetails((prevState) => ({
                ...prevState,
                title: fieldValue,
              }));
            }
          }}
        />

        <Button
          btnText="Save"
          className="w-full py-2 bg_skyblue text-white"
          isDisabled={isDisabled}
          isLoading={isBtnLoading}
          onClick={() => {
            setIsBtnLoading(true);
            if (title && imageUrl) {
              EditRankItem(adminItemDetails?.id,{
                title: addExpertDetails?.title,
                description: "",
                image_url: addExpertDetails?.image_url,
                source_url: null,
                admin_item: true,
              })
                .then((res) => {
                  if (res) {
                    setIsBtnLoading(false);
                    setAddRankItem(false);
                    setApiError(false);
                    onAddedItem?.();
                  }
                })
                .catch((err) => {
                  console.error("[EditRankItem][API_ERROR] - ", err);
                  setApiError(err?.response?.data?.errors || err?.message);
                  setIsBtnLoading(false);
                });
            } else {
              AddRankItem({
                title: addExpertDetails?.title,
                description: "",
                image_url: addExpertDetails?.image_url,
                source_url: null,
                admin_item: true,
              })
                .then((res) => {
                  if (res) {
                    setIsBtnLoading(false);
                    setAddRankItem(false);
                    setApiError(false);
                    onAddedItem?.();
                  }
                })
                .catch((err) => {
                  console.error("[AddRankItem][API_ERROR] - ", err);
                  setApiError(err?.response?.data?.errors || err?.message);
                  setIsBtnLoading(false);
                });
            }
          }}
        />
      </div>
    </SideView>
  );
};
