import React, { useEffect, useState } from "react";
import { TableHeading } from "./TableHeading";
import Table from "Components/Common/Table/Table";
import { GetUsers } from "Services/API/Users.service";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import "./Profile.css";
import { formatDate } from "Helpers/DateFormaater";

const HEADINGS = ["Name", "Username", "Email","Phone Number", "Date of Birth", "Gender", " "];
const userProfilesFormatters = (data) => {
  return data.map((users) => {
    return {
      id: users.id,
      about: users.bio||"",
      email: users.email||"",
      gender: users.gender||"",
      country: users.country||"",
      DOB: formatDate(users.date_of_birth,"MM/DD/YYYY")||"",
      userName: (users?.username)?.replaceAll("null","")??"",
      userImage: users.profile_image_url||"",
      firstName: (users.first_name)?.replaceAll("null","")||"",
      lastName:(users.last_name)?.replaceAll("null","")||"",
      phoneNumber: (users?.phone)?.replaceAll("null","")||""
    };
  });
};

export const UserProfiles = () => {
  const [isSideView, setIsSideView] = useState(false);
  const [userProfiles, setUserProfiles] = useState([]);
  const [selectedUserDetails, setSelectedUserDetails] = useState({
    DOB: "",
    name: "",
    email: "",
    about: "",
    gender: "",
    userName: "",
    userImage: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(7);

  useEffect(() => {
    setIsLoading(true);
    GetUsers(pageNumber, perPage)
      .then((res) => {
        setUserProfiles(userProfilesFormatters(res.data));
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("[GetUsers][API_ERROR] - ", err);
        setIsLoading(false);
      });
    return () => {
      setUserProfiles([]);
    };
  }, [pageNumber]);

  return (
    <>
      <div
        className={`flex flex-col gap-4 ${
          isSideView ? "Table" : "Orignal_Table_Width"
        }`}
      >
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={
            userProfiles?.length > 0 &&
            userProfiles.map((user, index) => (
              <tr key={index}>
                <td className="px-6 py-4 cursor-pointer">
                  <div className="text-sm bali_hai font-bold">{user.firstName+" "+user.lastName}</div>
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  <div className="text-sm text-gray-secondary">{user.userName}</div>
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  <div className="text-sm text-gray-secondary">{user.email}</div>
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  <div className="text-sm text-gray-secondary">{user.phoneNumber}</div>
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  <div className="text-sm text-gray-secondary">{user.DOB}</div>
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  <div className="text-sm text-gray-secondary">{user.gender}</div>
                </td>
                <td className="px-4 py-4 text-left text-sm font-medium ">
                  <span
                    onClick={() => {
                      setSelectedUserDetails({
                        name: (user.name)?.replaceAll("null",""),
                        DOB: user.DOB,
                        gender: user.gender,
                        email: user.email,
                        userName: (user.userName)?.replaceAll("null",""),
                        userImage: user.userImage,
                        about: user.about,
                      });
                      setIsSideView((x) => !x);
                    }}
                    className="cursor-pointer text-blue-dark"
                  >
                    {isSideView ? "" : "View"}
                  </span>
                </td>
              </tr>
            ))
          }
        />
        {!isLoading &&userProfiles?.length <= 0 &&<div className="flex bg-white w-full justify-center items-center h-96">No Reported Rankpages found at the moment</div>}

        {isLoading && <Spinner />}

        {!isLoading && userProfiles.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
      </div>
      {isSideView && (
        <SideView
          setIsSideView={setIsSideView}
          heading={selectedUserDetails.name}
          subHeading={selectedUserDetails.email}
          profileImage={selectedUserDetails.userImage}
        >
          <div className="flex flex-col justify gap-6 pt-4">
            <div className="grid grid-cols-2 gap-5">
              <div className="text-gray-400">
                UserName{" "}
                <div className="text-black mt-1">
                  {selectedUserDetails.userName}
                </div>
              </div>
              <div className="text-gray-400">
                Date of Birth{" "}
                <div className="text-black mt-1">{selectedUserDetails.DOB}</div>
              </div>
              <div className="text-gray-400">
                Gender{" "}
                <div className="text-black mt-1">
                  {selectedUserDetails.gender}
                </div>
              </div>
             
            </div>
            <div className="text-gray-400">
              About
              <div className="text-black mt-1">{selectedUserDetails.about}</div>
            </div>
          </div>
        </SideView>
      )}
    </>
  );
};
