import { Route, Switch } from "react-router";
import {
  BASE_ADMIN_PATH,
  CREATE_ADMIN_PATH,
  EDIT_ADMIN_PATH,
} from "Constants/View";
import { AdminsView } from "Pages/Admins/AdminsView";
import { AdminCreation } from "Pages/Admins/AdminCreation";
import { AdminEdit } from "Pages/Admins/AdminEdit";

export const BaseAdminViews = () => {
  return (
    <Switch>
      <Route exact path={BASE_ADMIN_PATH} render={() => <AdminsView />} />
      <Route exact path={CREATE_ADMIN_PATH} render={() => <AdminCreation />} />
      <Route exact path={EDIT_ADMIN_PATH} render={() => <AdminEdit />} />
    </Switch>
  );
};
