import { Route, Switch } from "react-router";
import {
  BASE_ANNOUNCEMENTS_PATH,
  CREATE_ANNOUNCEMENTS_PATH,
} from "Constants/View";
import { Announcements } from "Pages/Announcements/Announcements";
import { CreateAnnouncement } from "Pages/Announcements/AnnouncementCreation";

export const AnnouncementMainPage = () => {
  return (
    <Switch>
      <Route
        exact
        path={BASE_ANNOUNCEMENTS_PATH}
        render={() => <Announcements />}
      />
      <Route
        exact
        path={CREATE_ANNOUNCEMENTS_PATH}
        render={() => <CreateAnnouncement />}
      />
    </Switch>
  );
};
