import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for getting the admin dashboard stats
 * @returns the admin dashboard stats
 */
export const GetAdminDashboard = async () => {
  const {
    data: { result },
  } = await Axios.get(`${API_BASE}admin/dashboard`);
  console.log(`[GetAdminDashboard][API_RESPONSE]`, result);
  return result;
};
