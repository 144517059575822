import React, { useState } from "react";
import Table from "Components/Common/Table/Table";
import { mockDataPickem } from "../MockData";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { ReactComponent as BackIcon } from "Assets/Icons/BackArrow.svg";
import "../Pickems.css";

const HEADINGS = [
  " ",
  "Name",
  "Level",
  "Criteria 1",
  "Criteria 2",
  "Total Points",
];
const TABLE_CSS = "px-6 py-4 text-sm font-medium cursor-pointer";
const COLOR_CSS = "text-gray-400";

export const LeaderBoard = ({ setCurrentTableView, view }) => {
  const [isSideView, setIsSideView] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      <span
        className="flex gap-3 items-center"
        onClick={() => setCurrentTableView(view)}
      >
        <BackIcon />
        Leaderboard
      </span>
      <div className={isSideView ? "Table" : "Orignal_Table_Width"}>
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={mockDataPickem.map((user, index) => (
            <tr key={index}>
              <td className={TABLE_CSS}>
                <span className={COLOR_CSS}>{index + 1}</span>
              </td>
              <td className={TABLE_CSS}>
                <span>{user.title}</span>
              </td>
              <td className={TABLE_CSS}>
                <span>{user.rank}</span>
              </td>
              <td className={TABLE_CSS}>
                <span className={COLOR_CSS}>{user.reactions}</span>
              </td>
              <td className={TABLE_CSS}>
                <span className={COLOR_CSS}>{user.reposts}</span>
              </td>
              <td className={TABLE_CSS}>
                <span className={COLOR_CSS}>{user.reactions}</span>
              </td>
            </tr>
          ))}
        />
      </div>
    </div>
  );
};
