import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";
/**
 * @description  An API for getting the pickem events
 * @param {Number} pageNo current page number
 * @param {Number} perPage total number of events per page
 * @param {String} category user interest
 * @param {String} subCategory user subinterest
 * @param {String} eventType selected event type
 * @returns the list of the pickem events
 */
export const GetPickemEvents = async (
  category = "sports",
  subCategory = "FOOTBALL",
  eventType = "MATCH",
  pageNo = 1,
  perPage = 10
) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}pickem/event/${category}?subCategory=${subCategory}&eventType=${eventType}&page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetPickemEvents][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting the pickem orderedItems
 * @param {String} id of the event
 * @param {String} itemType type to get the pickem orderedItems
 * @param {Number} pageNo current page number
 * @param {Number} perPage total number of events per page
 * @param {String} category user interest
 * @returns the list of the pickem items
 */
export const GetPickemItems = async (
  category = "sports",
  id = "",
  itemType = "",
  pageNo = 1,
  perPage = 10
) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}pickem/items/${category}/${id}?itemType=${itemType}&page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetPickemItems][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for creating the pickem
 * @param {Object} payload for the pickem creation 
 * Payload_Example :{
 *  "title": "Which stock will perform best?",
    "category": "FINANCE",
    "sub_category": "STOCKS",
    "event_id": "61c46b05b9667564154058d2",
    "ordered_items": ["61cdfcc980e87f53a2642c23","61cdfcc980e87f53a2642c24"],
    "end_date": "2022-01-01",
    "start_date": "2021-12-31",
    "visibility": "PUBLIC",
    "participants_limit": 100,
    "add_items": false,
    "criteria": "EXCHANGE_RATE",
    "criteria_operator":"MOST"
 * }
 * @returns 
 */
export const PostPickem = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}pickem/`, payload);
  console.log(`[PostPickem][API_RESPONSE]`, result);
  return result;
};
