import { useEffect, useState } from "react";
import { Dropdown } from "Components/Common/Dropdown/Dropdown";
import { FilterUsers } from "Services/API/SearchFilter.service";
import { ReactComponent as XIcon } from "Assets/Icons/Cross.svg";
import { GetMainCategories } from "Services/API/Categories.service";
import { ReactComponent as ArrowDown } from "Assets/Icons/ChevronDown.svg";
import { ReactComponent as DownloadIcon } from "Assets/Icons/ArrowSmDown.svg";
import {
  CONTENT_FILTER,
  formattedFilteredRasnkpages,
  INTERACTIONS_FILTER,
  FilterBtn,
  formattedCategories,
} from "./Common";
import { DatePicker } from "Components/Common/DatePicker/DatePicker";

export const RankpageFilters = ({
  searchQuery,
  setUserData,
  setIsLoading,
  setHasPrevPage,
  setHasNextPage,
  pageNumber,
  contentFilter,
  setContentFilter,
}) => {
  const [categories, setCategories] = useState([]);
  const [interactionFilter, setInteractionFilter] = useState("");
  const [categoriesFiter, setCategoriesFiter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    GetMainCategories()
      .then((res) => {
        setCategories([
          { id: "All", description: "All", image: "", title: "ALL" },
          ...formattedCategories(res?.data),
        ]);
      })
      .catch((err) => {
        console.error("[GetMainCategories][API_ERROR] =>", err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    FilterUsers(
      {
        to: null,
        from: null,
        content_type: "Rankpages",
        partialText: searchQuery || "",
        criteria: interactionFilter || null,
        category_id: categoriesFiter ? [categoriesFiter] : null,
      },
      pageNumber
    )
      .then((res) => {
        if (res) {
          setUserData([...formattedFilteredRasnkpages(res?.data)]);
          setHasNextPage(res?.pagination?.hasNext);
          setHasPrevPage(res?.pagination?.hasPrevious);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error("[FilterUsers_RANKPAGES][API_ERROR] =>", err);
        setIsLoading(false);
      });
  }, [interactionFilter, categoriesFiter, contentFilter, pageNumber]);

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center w-8/12 gap-3">
        <Dropdown
          id="content-filter"
          label="Content"
          Icon={ArrowDown}
          onChange={(value) => setContentFilter(value)}
        >
          <Dropdown.Option
            id={-1}
            value={"-1"}
            label={contentFilter}
            isDefaultOption={true}
          />
          {CONTENT_FILTER.map((pickemType, index) => (
            <Dropdown.Option
              key={index}
              id={index}
              value={pickemType}
              label={pickemType}
            />
          ))}
        </Dropdown>

        <Dropdown
          id="age-filter"
          label="Filter By"
          Icon={ArrowDown}
          onChange={(value) => setInteractionFilter(value)}
        >
          <Dropdown.Option
            id={-1}
            value={"-1"}
            label={interactionFilter || "Filter By"}
            isDefaultOption={true}
          />
          {INTERACTIONS_FILTER.map((interaction, index) => (
            <Dropdown.Option
              key={index}
              id={index}
              value={interaction?.value}
              label={interaction?.label}
            />
          ))}
        </Dropdown>
        <Dropdown
          id="category"
          label="Category"
          Icon={ArrowDown}
          onChange={(value) => {
            if (value === "All") {
              setCategoriesFiter("");
            } else setCategoriesFiter(value);
          }}
        >
          <Dropdown.Option
            id={-1}
            value={"-1"}
            label={categoriesFiter || "Interests"}
            isDefaultOption={true}
          />
          {categories?.map((gender, index) => (
            <Dropdown.Option
              key={index}
              id={gender?.id}
              value={gender?.id}
              label={gender?.title}
            />
          ))}
        </Dropdown>
        {/* <DatePicker
          placeholder="Start Date"
          isRequired={true}
          value={startDate}
          onBlur={(startDate, setFieldError) => {
            setStartDate(startDate);
          }}
        /> */}
        <FilterBtn
          Icon={XIcon}
          text="Clear Filter"
          onClick={
            () => setInteractionFilter("")
            // setCategoriesFiter("Interests");
            // setGenderFilter("Gender");
          }
        />
      </div>
      <FilterBtn Icon={DownloadIcon} text="Download" />
    </div>
  );
};
