import { CreateAdmin } from "Components/Admins/CreateAdmin";
import { AppLayout } from "Layouts/AppLayout/AppLayout";

export const AdminCreation = () => {
  return (
    <AppLayout>
      <CreateAdmin />
    </AppLayout>
  );
};
