import { UserPickems } from "./UserPickems";
import { useGlobalContext } from "Context/Global";
import { Tabs } from "Components/Common/Tabs/Tabs";
import { ReportedPickems } from "./ReportedPickems";
import { TrendingPickems } from "./TrendingPickems";
import { CREATE_PICKEM_PATH } from "Constants/View";
import { MainRankpageView } from "./RankpagePickems";
import { Button } from "Components/Common/Button/Button";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";

const RANKPAGE_PICKEMS = "Rankpage Pick’ems";
const USER_PICKEMS = "User Pick’ems";
const REPORTED_PICKEMS = "Reported Pick’ems";
const TRENDING_PICKEMS = "Trending Pick’ems";
const tabs = [
  { name: "Rankpage Pick’ems" },
  { name: "User Pick’ems" },
  { name: "Reported Pick’ems" },
  { name: "Trending Pick’ems" },
];

export const PickemsMain = () => {
  const { setActiveView } = useGlobalContext();
  const [activeTab, setActiveTab] = useLocalStorage(
    "pickems_current_tab",
    RANKPAGE_PICKEMS
  );

  const handleTabClick = (tabName) => {
    if (tabName === USER_PICKEMS) {
      setActiveTab(USER_PICKEMS);
    } else if (tabName === REPORTED_PICKEMS) {
      setActiveTab(REPORTED_PICKEMS);
    } else if (tabName === TRENDING_PICKEMS) {
      setActiveTab(TRENDING_PICKEMS);
    } else {
      setActiveTab(RANKPAGE_PICKEMS);
    }
  };

  return (
    <>
      <span>Pick’ems</span>
      <div className="flex justify-between">
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onTab={(tabName) => handleTabClick(tabName)}
        />
        <Button
          btnText="Create a Pick’em"
          className="w-36 h-8 bg-blue-400 text-white"
          isDisabled={false}
          isLoading={false}
          onClick={() => {
            setActiveView(CREATE_PICKEM_PATH);
          }}
        />
      </div>
      <br />
      {activeTab === RANKPAGE_PICKEMS ? (
        <MainRankpageView />
      ) : activeTab === USER_PICKEMS ? (
        <UserPickems />
      ) : activeTab === REPORTED_PICKEMS ? (
        <ReportedPickems />
      ) : activeTab === TRENDING_PICKEMS ? (
        <TrendingPickems />
      ) : (
        ""
      )}
    </>
  );
};
