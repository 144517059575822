import { formatDate } from "Helpers/DateFormaater";

export const stocksFormatter = (stocks) => {
    return stocks.map((stock) => {
      return {
        id: stock.id,
        name: stock.title,
      };
    });
  };
  export const matchFormatter = (matches) => {
    return matches.map((match) => {
      return {
        id: match.id,
        name: match.title,
        date: formatDate(match.start_date, "DD-MM-YYYY"),
        time: formatDate(match.start_date, "hh:mm A"),
        contenstant1: {
          name: match.home_team || "",
          image: match.home_image_url || "",
        },
        contenstant2: {
          name: match.away_team || "",
          image: match.away_image_url || "",
        },
      };
    });
  };