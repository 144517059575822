import { useEffect, useState } from "react";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import Table from "Components/Common/Table/Table";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { formatDate } from "Helpers/DateFormaater";
import {
  DeleteRankItem,
  GetAdminItems,
  HideRankItem,
} from "Services/API/RankItems.service";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { Button } from "Components/Common/Button/Button";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import { AddRankItems } from "./AddRankItem";

const HEADINGS = ["Title", "Uploaded By", "Date of Upload", " ", " "];
const TABLE_CSS = "px-6 py-4 cursor-pointer text-sm";

export const AdminRankItems = ({ addRankItem, setAddRankItem }) => {
  const [adminRankItems, setAdminRankItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [adminItemDetails, setAdminItemDetails] = useState({
    id: "",
    imageUrl: "",
    title: "",
    uploadedBy: "",
    DateOfUpload: "",
    hide: false,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isSideView, setIsSideView] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isHideBtnLoading, setIsHideBtnLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const fetchAdminRankItems = () => {
    setLoading(true);
    GetAdminItems(pageNumber, perPage)
      .then((res) => {
        setAdminRankItems([...res?.data]);
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
        setLoading(false);
      })
      .catch((err) => {
        console.error("[GetAdminItems][API_ERROR]", err);
        setLoading(false);
      });
    return () => {
      setAdminRankItems([]);
    };
  };
  useEffect(() => {
    fetchAdminRankItems();
  }, [pageNumber]);
  return (
    <div
      className={`flex flex-col gap-4 ${
        isSideView ? "Table" : "Orignal_Table_Width"
      }`}
    >
      {" "}
      {isLoading && <Spinner />}
      {!isLoading && (
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={
            adminRankItems?.length > 0 &&
            adminRankItems.map((item, index) => (
              <tr
                key={index}
                className={item?.hidden ? "bg-red-300 text-gray-500" : ""}
              >
                <td className={TABLE_CSS}>
                  <div>{item.title}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div>{(item.created_by?.first_name).replaceAll("null","") ?? ""}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div>{formatDate(item.createdAt, "MM/DD/YYYY")}</div>
                </td>
                <td className="px-4 py-4 text-left text-sm font-medium">
                  <span
                    onClick={() => {
                      setAdminItemDetails({
                        id: item?.id,
                        title: item?.title,
                        imageUrl: item?.image_url,
                        uploadedBy: (item?.created_by?.first_name).replaceAll("null","")?? "",
                        DateOfUpload: formatDate(item?.createdAt, "MM/DD/YYYY"),
                        hide: item?.hidden,
                      });
                      setIsEdit(true);
                    }}
                    className="cursor-pointer text-blue-dark"
                  >
                    Edit
                  </span>
                </td>
                <td className="px-4 py-4 text-left text-sm font-medium">
                  <span
                    onClick={() => {
                      setAdminItemDetails({
                        id: item?.id,
                        title: item?.title,
                        imageUrl: item?.image_url,
                        uploadedBy: (item?.created_by?.first_name).replaceAll("null","") ?? "",
                        DateOfUpload: formatDate(item?.createdAt, "MM/DD/YYYY"),
                        hide: item?.hidden,
                      });
                      setIsSideView((prevState) => !prevState);
                    }}
                    className="cursor-pointer text-blue-dark"
                  >
                    View
                  </span>
                </td>
              </tr>
            ))
          }
        />
      )}
      {!isLoading &&adminRankItems?.length <= 0 &&<div className="flex bg-white w-full justify-center items-center h-96">No Admin RankItems found at the moment</div>}

      {isSideView && (
        <SideView
          showProfileImage={true}
          setIsSideView={setIsSideView}
          profileImage={adminItemDetails?.imageUrl}
          heading={adminItemDetails.title}
        >
          <div className="flex flex-col gap-4 pt-2">
            <div className="flex justify-between">
              <div className="flex flex-col gap-2">
                <span className="raven">Uploaded By</span>
                <span className="primary_black">
                  {adminItemDetails.uploadedBy}
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <span className="raven">Date of Upload</span>
                <span className="primary_black">
                  {adminItemDetails.DateOfUpload}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="raven">Delete</span>
              <span className="primary_black">
                Deletion will remove the rank item completely. Hiding list will
                only make it visible to you.
              </span>
              <div className="flex gap-3">
                <Button
                  btnText={adminItemDetails.hide ? "Unhide" : "Hide"}
                  className="w-24 py-2 bg-white border border-gray-300"
                  isDisabled={false}
                  isLoading={isHideBtnLoading}
                  onClick={() => {
                    setIsHideBtnLoading(true);
                    HideRankItem(adminItemDetails.id)
                      .then((res) => {
                        if (res) {
                          setIsHideBtnLoading(false);
                          setIsSideView(false);
                          fetchAdminRankItems();
                        }
                      })
                      .catch((err) => {
                        console.error("[HideRankItem][API_ERROR] - ", err);
                        setIsHideBtnLoading(false);
                      });
                  }}
                />{" "}
                <Button
                  btnText="Delete"
                  className="w-24 py-2 bg-red-400 text-white"
                  isDisabled={false}
                  isLoading={isBtnLoading}
                  onClick={() => {
                    setIsBtnLoading(true);
                    DeleteRankItem(adminItemDetails.id)
                      .then((res) => {
                        if (res) {
                          setIsBtnLoading(false);
                          setIsSideView(false);
                          fetchAdminRankItems();
                        }
                      })
                      .catch((err) => {
                        console.error("[DeleteRankpage][API_ERROR] - ", err);
                        setIsBtnLoading(false);
                      });
                  }}
                />
              </div>
            </div>
          </div>
        </SideView>
      )}
      {!isLoading && (
        <PaginationBtns
          isNextDisabled={!hasNextPage}
          isPrevDisabled={!hasPreviousPage}
          onNext={() => {
            if (hasNextPage) {
              setPageNumber(pageNumber + 1);
            }
          }}
          onPrevious={() => {
            if (hasPreviousPage) {
              setPageNumber(pageNumber - 1);
            }
          }}
        />
      )}
      {(addRankItem || isEdit) && (
        <AddRankItems
          adminItemDetails={adminItemDetails}
          setAddRankItem={setAddRankItem}
          onAddedItem={() => {
            setIsEdit(false);
            fetchAdminRankItems();
          }}
        />
      )}
    </div>
  );
};
