/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as XIcon } from "Assets/Icons/Cross.svg";
import { ReactComponent as ProfileImage } from "Assets/Icons/Profile.svg";
import "./SidePannel.css";

export const SideView = ({
  setIsSideView,
  profileImage = "",
  showProfileImage = true,
  heading,
  subHeading,
  children,
  onClose = () => {},
}) => {
  const [open, setOpen] = useState(true);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 mt-20">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-sm border-l-2 border-gray-300">
                <div className="h-full pt-16 flex flex-col bg-white shadow-xl overflow-auto divide-y divide-gray-300 px-4">
                  <div className="py-6">
                    <div className="flex items-start justify-between">
                      <div className="flex items-center gap-4">
                        {showProfileImage && (
                          <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                            {profileImage ? (
                              <img
                                className="h-10 w-10 rounded-full"
                                src={profileImage}
                              />
                            ) : (
                              <ProfileImage />
                            )}
                          </span>
                        )}
                        <div>
                          <span className="text-sm font-medium eclipse cursor-pointer">
                            {heading}
                          </span>
                          <br />
                          <span className="text-sm cursor-pointer">
                            {subHeading}
                          </span>
                        </div>
                      </div>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md border-none  focus:outline-none"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                            onClick={() => setIsSideView(false)}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Content Children */}
                  <div>
                    <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
