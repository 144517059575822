import { AdminMainView } from "Components/Admins/AdminMainView";
import { AppLayout } from "Layouts/AppLayout/AppLayout";

export const AdminsView = () => {
  return (
    <AppLayout>
      <div className="flex flex-col gap-4">
        <span className="text-lg font-medium">Rankpage Admins</span>
        <AdminMainView/>
      </div>
    </AppLayout>
  );
};
