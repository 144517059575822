import { formatDate } from "Helpers/DateFormaater";

export const formatRanklistItems = (rankListsItems) => {
  return rankListsItems.map((item) => {
    return {
      id: item.id||"",
      image: item.image_url,
      title: item.title,
    };
  });
};

export const rankListFormatter = (rankLists) => {
  return rankLists.map((list) => {
    return {
      id: list.id,
      title: list.title,
      hidden: list.hidden,
      uploadedBy:
        (list.uploaded_by.first_name + " " + list.uploaded_by.last_name).replaceAll("null",""),
      uploadedDate: formatDate(list.createdAt, "MM/DD/YYYY"),
      rankLists: formatRanklistItems(list.ranklistItems),
      //TODO: resolve the no of catgories to show
      category: list.category[0].title,
    };
  });
};
export const reportedRankpagesFormatter = (reportedRankpages) => {
  return reportedRankpages.map((rankpage) => {
    return {
      id: rankpage.id,
      title: rankpage.title,
      hidden: rankpage.hidden,
      category: rankpage.category[0].title,
      rankLists: formatRanklistItems(rankpage.ranklistItems),
      uploadedBy:
       ( rankpage.uploaded_by.first_name + " " + rankpage.uploaded_by.last_name).replaceAll("null",""),
      reportedBy:
        (rankpage.reportedByUser.first_name +
        " " +
        rankpage.reportedByUser.last_name).replaceAll("null",""),
       reportedReason: rankpage.report_option?.name.replaceAll("null",""),
    };
  });
};
export const trendingListsFormatter = (trendingLists) => {
  return trendingLists.map((list) => {
    return {
      id: list.id,
      title: list.title,
      hidden: list.hidden,
      reactions: list.reactions || "-",
      reposts: list.reposts || "-",
      comments: list.comments || "-",
      uploadedBy:
        (list.uploaded_by.first_name + " " + list.uploaded_by.last_name).replaceAll("null",""),
      rankLists: formatRanklistItems(list.ranklistItems),
      //TODO: resolve the no of catgories to show
      category: list.category[0].title,
    };
  });
};
