import { useEffect, useState } from "react";
import { Button } from "Components/Common/Button/Button";
import { ReactComponent as BackIcon } from "Assets/Icons/BackArrow.svg";
import ClassNames from "Helpers/Common";
import "./CreateRankpage.css";
import { DetailForm } from "./DetailForm";
import { RankList } from "Components/Widget/CreatePoll/RankList/RankList";
import { CreateRanklist } from "Services/API/Ranklists.service";
import { useGlobalContext } from "Context/Global";
import { BASE_RANKPAGE_PATH } from "Constants/View";
const DETAILS_FORM = "detailForm";
const LIST_FORM = "listForm";

export const RankpageCreation = () => {
  const { setActiveView } = useGlobalContext();
  const [currentForm, setCurrentForm] = useState(DETAILS_FORM);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [detailForm, setDetailForm] = useState({
    title: "",
    description: "",
    visibility: "",
    categories: [],
  });
  useEffect(() => {
    setIsDisabled(selectedItems.length <= 0);
  }, [currentForm, selectedItems]);

  return (
    <div className="flex gap-4 h-full">
      <div className="flex flex-col gap-3 p-4 bg-white First_Coulnm">
        <span className="flex gap-3 items-center h-4">
          <BackIcon className="cursor-pointer" onClick={() => setActiveView(BASE_RANKPAGE_PATH)}/> Create a Rankpage
        </span>
      </div>
      <div className="flex flex-col gap-5 bg-white Second_Coulnm">
        <div className="flex flex-col gap-3 p-4 overflow-y-auto Rankpage_Form_Height">
          {currentForm === DETAILS_FORM ? (
            <DetailForm
              setDetailForm={setDetailForm}
              detailForm={detailForm}
              setIsDisabled={setIsDisabled}
            />
          ) : (
            <RankList
              setSelectedItems={setSelectedItems}
              itemsLimit={1000000}
              setIsDisabled={setIsDisabled}
            />
          )}
        </div>
        <div
          className={ClassNames(
            "flex border-t-2 border-gray-200  p-4 xl:p-6",
            currentForm !== DETAILS_FORM ? "justify-between" : "justify-end"
          )}
        >
          {currentForm !== DETAILS_FORM && (
            <Button
              btnText="Back"
              className={"w-24 py-2 bg-white  border-gray-300"}
              isDisabled={false}
              isLoading={false}
              onClick={() => {
                if (currentForm === LIST_FORM) {
                  setCurrentForm(DETAILS_FORM);
                }
              }}
            />
          )}
          <Button
            btnText={currentForm !== DETAILS_FORM ? "Publish" : "Next"}
            className={ClassNames(
              "w-24 py-2",
              !isDisabled && "bg-blue-dark text-white"
            )}
            isDisabled={isDisabled}
            isLoading={isBtnLoading}
            onClick={() => {
              if (currentForm === DETAILS_FORM) {
                setCurrentForm(LIST_FORM);
              } else if (currentForm === LIST_FORM) {
                setIsBtnLoading(true);
                const { title, description, visibility, categories } =
                  detailForm;

                CreateRanklist({
                  title,
                  description,
                  visibility,
                  ordered_items: selectedItems.map((item) => item.id),
                  categories: categories.map((item) => item.id),
                })
                  .then((res) => {
                    if (res) {
                      setIsBtnLoading(false);
                      setActiveView(BASE_RANKPAGE_PATH);
                    }
                  })
                  .catch((err) => {
                    setIsBtnLoading(false);
                    console.error("[CreateRanklist][API_ERROR] - ", err);
                  });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
