import ClassNames from "Helpers/Common";
import "./Button.css";

export const Button = ({
  btnText,
  isDisabled = true,
  onClick,
  className,
  isLoading = false,
  Icon,
}) => {
  return (
    <button
      type="button"
      disabled={isDisabled}
      onClick={() => onClick()}
      className={ClassNames(
        "inline-flex justify-center items-center border border-transparent rounded-md text-sm font-normal focus:outline-none cursor-pointer",
        isDisabled ? "bg-gray-300 text-gray-400 border  border-gray-300" : "",
        className
      )}
    >
      <div className="flex items-center justify-center gap-2 ">
        {isLoading ? (
          <div className="w-4 h-4 border-l-2 border-b-2 border-white rounded-full animate-spin" />
        ) : (
          btnText && (
            <span className={"flex items-center"}>
              {btnText}{" "}
              {Icon && (
                <Icon
                  className="h-5 w-5 text-gray-secondary"
                  aria-hidden="true"
                />
              )}
            </span>
          )
        )}
      </div>
    </button>
  );
};
