import React, { useEffect, useState } from "react";
import { Footer } from "./Footer";
import { BASE_LOGIN_PATH } from "Constants/View";
import { useGlobalContext } from "Context/Global";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { HEADING, SUB_HEADING, BTN_TEXT } from "./Common";
import { PasswordResetReq } from "Services/API/Login.service";
import { InputField } from "Components/Common/InputField/InputField";
import "./Login.css";

export const NewPassword = () => {
  const {
    ForgotPasswordDetials: { resetToken },
    resetForgotPasswordDetails,
    setActiveView,
  } = useGlobalContext();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    setIsDisabled(!(password && confirmPassword));
  }, [password, confirmPassword]);
  const handleSubmit = () => {
    if (resetToken) {
      setIsLoading(true);
      PasswordResetReq(resetToken, { new_password: password })
        .then((res) => {
          setIsLoading(false);
          resetForgotPasswordDetails();
          setActiveView(BASE_LOGIN_PATH);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(
            "[ForgotPasswordReq][API_ERROR] - ",
            err?.response?.data?.errors || err?.message
          );
        });
    }
  };
  return (
    <div className="Login_Card flex flex-col gap-3">
    <div className="border-2 border-gray-200 rounded-md flex flex-col xl:gap-12 2xl:gap-44">
      <div className="p-6">
        <span className={`flex justify-center ${HEADING}`}>
          Enter New Password
        </span>
        <div className={`mt-4 ${SUB_HEADING}`}>
          Please enter your new password below
        </div>
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}

        <div className="lg:space-y-4 xl:space-y-6 2xl:space-y-10 mt-4 2xl:mt-6">
          <InputField
            id="new-password"
            placeholder="New Password"
            type="password"
            isRequired={true}
            onChange={(fieldValue) => {
              setPassword(fieldValue);
            }}
          />

          <InputField
            id="password-confirmation"
            placeholder="Re-enter Password"
            type="password"
            isRequired={true}
            onBlur={(fieldValue, setInputFieldError) => {
              if (password === fieldValue) {
                setConfirmPassword(fieldValue);
              } else {
                setIsDisabled(true);
                setInputFieldError("Password Mismatch");
              }
            }}
          />

          <Button
            btnText="Continue"
            className={`w-full py-2  ${BTN_TEXT} ${
              !isDisabled ? "bg-blue-400 text-white" : ""
            }`}
            isDisabled={isDisabled}
            isLoading={isLoading}
            onClick={() => handleSubmit()}
          />
        </div>
      </div>
      <Footer />
    </div>
    </div>
  );
};
