import { formatDate } from "Helpers/DateFormaater";

const eventlistFormatter = (events) => {
  return events.map((list) => {
    return {
      id: list.id,
      image: list.img_url,
      title: list.title,
    };
  });
};
export const rankpagePickemsFormatter = (rankpagePickems) => {
  return rankpagePickems.map((pickem) => {
    return {
      id: pickem.id,
      title: pickem.title,
      category: pickem.category,
      subCategory: pickem.sub_category,
      eventList: eventlistFormatter(pickem.eventChoices),
      startingDate: formatDate(pickem.start_date, "MM/DD/YYYY"),
      leaderBoardStatus:
        pickem.leaderboard_status === "DISABLED" ? "Closed" : "Live",
      status: pickem.contest_status === "FINISHED" ? "Closed" : "Live",
      eventTitle:
        pickem.event.home_team + " " + "vs" + " " + pickem.event.away_team,
      uploadedBy:
        (pickem.posted_by.first_name + " " + pickem.posted_by.last_name).replaceAll("null",""),
    };
  });
};
export const userPickemsFormatter = (userPickems) => {
  return userPickems.map((pickem) => {
    return {
      id: pickem.id,
      title: pickem.title,
      category: pickem.category,
      subCategory: pickem.sub_category,
      hidden: pickem.hidden,
      eventList: eventlistFormatter(pickem.eventChoices),
      closingTime: formatDate(pickem.end_time, "hh:mm A"),
      closingDate: formatDate(pickem.end_date, "MM/DD/YYYY"),
      startingDate: formatDate(pickem.start_date, "MM/DD/YYYY"),
      uploadedBy:
        (pickem.posted_by.first_name + " " + pickem.posted_by.last_name).replaceAll("null",""),
      status: pickem.contest_status === "FINISHED" ? "Closed" : "Live",
      eventTitle:
        pickem.event.home_team + " " + "vs" + " " + pickem.event.away_team,
    };
  });
};
export const reportedPickemsFormatter = (reportedPickems) => {
  return reportedPickems.map((pickem) => {
    return {
      id: pickem.id,
      title: pickem.title,
      hidden: pickem.hidden,
      subCategory: pickem.sub_category,
      eventList: eventlistFormatter(pickem.eventChoices),
      startingDate: formatDate(pickem.start_date, "MM/DD/YYYY"),
      uploadedBy:
       ( pickem.posted_by.first_name + " " + pickem.posted_by.last_name).replaceAll("null",""),
      reportedBy:
        (pickem.reported_by.first_name + " " + pickem.reported_by.last_name).replaceAll("null",""),
      category: pickem.category,
      eventTitle:
        pickem.event.home_team + " " + "vs" + " " + pickem.event.away_team,
    };
  });
};
export const trendingPickemsFormatter = (trendingPickems) => {
  return trendingPickems.map((pickem) => {
    return {
      id: pickem.id,
      title: pickem.title,
      category: pickem.category,
      reposts: pickem.share_count,
      reactions: pickem.reorder_count,
      subCategory: pickem.sub_category,
      eventList: eventlistFormatter(pickem.eventChoices),
      startingDate: formatDate(pickem.start_date, "MM/DD/YYYY"),
      status: pickem.contest_status === "FINISHED" ? "Closed" : "Live",
      eventTitle:
        pickem.event.home_team + " " + "vs" + " " + pickem.event.away_team,
      uploadedBy:
        (pickem.posted_by.first_name + " " + pickem.posted_by.last_name).replaceAll("null",""),
    };
  });
};
