import { ReactComponent as ColorDotIcon } from "Assets/Icons/Ellipse.svg";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import useWindowDimensions from "CustomHooks/useWindowDimensions";
import "./Dashboard.css";

const COLORS = ["#194890", "#589BD4", "#9CA3AF"];
const TEXT = "text-xs font-normal cursor-pointer";

export const GenderGraph = ({ genderStats }) => {
  const { width } = useWindowDimensions();
  return (
    <div className="flex flex-col p-4 2xl:p-6 gap-4 bg-white h-80 rounded-md max-w-lg">
      <div className="text-sm font-medium">Gender Demographic</div>
      <div className="Demographic_Graph flex items-center 2xl:gap-16">
        <div className="flex flex-col items-center  gap-6">
          <div className="flex  gap-6">
            <ColorDotIcon className="h-5 w-3 mt-1 DarkBlue" />
            <div className={TEXT}>
              Male{" "}
              <div className="Mischka">
                {parseFloat(genderStats[0].value).toFixed(2)}
              </div>
            </div>
          </div>
          <div className="flex gap-6">
            <ColorDotIcon className="h-5 w-3 mt-1 LightBlue" />
            <div className={TEXT}>
              Female{" "}
              <div className="Mischka">
                {parseFloat(genderStats[1].value).toFixed(2)}
              </div>
            </div>
          </div>
          <div className="flex gap-6">
            <ColorDotIcon className="h-5 w-3 mt-1 Gray" />
            <div className={TEXT}>
              Others{" "}
              <div className="Mischka">
                {parseFloat(genderStats[2].value).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie
              data={genderStats}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={width >= 1920 ? 101 : width >= 1366 ? 90 : 60}
              fill="#8884d8"
              dataKey="value"
            >
              {genderStats?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
