import { useEffect, useState } from "react";
import ClassNames from "Helpers/Common";
import {
  INTRESET_FORM,
  EVENT_FORM,
  SETTINGS_FORM,
  REORDER_FORM,
} from "./constants";
const CREATION_STEPS = [
  { name: "Interest" },
  { name: "Event" },
  { name: "Settings" },
  { name: "Reorder" },
];
export const HeaderSteps = ({ currentForm, setCurrentForm }) => {
  const [activeStep, setActiveStep] = useState("Interest");
  useEffect(() => {
    if (currentForm === EVENT_FORM) {
      setActiveStep("Event");
    } else if (currentForm === SETTINGS_FORM) {
      setActiveStep("Settings");
    } else if (currentForm === REORDER_FORM) {
      setActiveStep("Reorder");
    }
  }, [currentForm]);
  return CREATION_STEPS.map((steps, index) => (
    <div className="flex h-16 w-full items-center p-4 gap-3 border-b-2 border-gray-200">
      <span
        className={ClassNames(
          "w-10 h-10 flex items-center justify-center border-2  rounded-full",
          activeStep === steps.name ? "border-blue-400" : "border-gray-400"
        )}
      >
        <span
          className={`${
            activeStep === steps.name ? "text-blue-400" : "text-gray-400"
          }`}
        >
          0{index + 1}
        </span>
      </span>
      <span
        className={ClassNames(
          "cursor-pointer",
          activeStep === steps.name ? "text-blue-400" : "text-gray-400"
        )}
      >
        {steps.name}
      </span>
    </div>
  ));
};
