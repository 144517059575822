
export const ENVIRONMENT = process.env.NODE_ENV
const prod = ENVIRONMENT === "production"; // Anything else is treated as 'dev'

export const SESSION_SECRET = process.env["REACT_APP_SESSION_SECRET"];
export const API_BASE = process.env["REACT_APP_API_BASE"]

export const AUTH_PROVIDER = process.env["REACT_APP_AUTH_PROVIDER"]
export const REALM = process.env["REACT_APP_REALM"]
export const CLIENT_ID = process.env["REACT_APP_CLIENT_ID"]


if (!SESSION_SECRET) {
    let msg = "No client secret. Set REACT_APP_SESSION_SECRET environment variable."
    console.log(msg)
    throw new Error(msg)
    
}


if (!API_BASE) {
    let msg = "No API base address found!!. set REACT_APP_API_BASE env variable"
    console.log(msg)
    throw new Error(msg)
}

if (!AUTH_PROVIDER) {
    let msg = "set REACT_APP_AUTH_PROVIDER env variable"
    console.log(msg)
    throw new Error(msg)
}


if (!REALM) {
    let msg = "set REACT_APP_REALM env variable"
    console.log(msg)
    throw new Error(msg)
}


if (!CLIENT_ID) {
    let msg = "set REACT_APP_CLIENT_ID env variable"
    console.log(msg)
    throw new Error(msg)
}

