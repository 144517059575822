import { useEffect, useState } from "react";
import ClassNames from "Helpers/Common";
import { usePickemContext } from "Context/PickemCreation";
import { matchFormatter, stocksFormatter } from "./APIFormaater";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { DisplayBtns, MatchCard, StockCard } from "./EventHelpers";
import { GetPickemEvents } from "Services/API/CreatePickem.service";

const STOCKS = ["Stocks", "ETFs"];
const SPORTS = ["MATCHES", "LEAGUES"];

export const EventForm = ({ setIsDisabled }) => {
  const {
    interest: { mainCategory, subCategory },
    setEvent,event
  } = usePickemContext();
  const [activeBtn, setActiveBtn] = useState(STOCKS[0]);
  const [eventType, setEventType] = useState("");
  const [pickemEvents, setPickemEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPickem, setSelectedPickem] = useState({
    index: "",
    selected: false,
  });
  const {id:eventId}=event
  useEffect(() => {
    if (mainCategory && subCategory) {
      setIsDisabled(true);
      if (mainCategory === "SPORTS") {
        setEventType("MATCH");
      } else if (mainCategory === "FINANCE") {
        if (subCategory === "CRYPTO") {
          setEventType("DIGITAL");
        } else if (subCategory === "FOREX") {
          setEventType("PHYSICAL");
        } else if (subCategory === "STOCKS") {
          setEventType("STOCKS");
        }
      }
    }  
  }, [mainCategory, subCategory]);
  useEffect(() => {
    if(eventId){
      setIsDisabled(false)
    }
  },[eventId])
  useEffect(() => {
    if (mainCategory && subCategory && eventType) {

      setIsLoading(true);
      GetPickemEvents(mainCategory.toLowerCase(), subCategory, eventType)
        .then((res) => {
          if ((res && eventType === "STOCKS") || eventType === "FUNDS") {
            setPickemEvents(stocksFormatter(res));
          } else if (eventType === "MATCH") {
            setPickemEvents(matchFormatter(res));
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("[GetPickemEvents][API_ERROR] - ", err);
          setIsLoading(false);
        });
    }
  }, [eventType]);

  return (
    <div className="h-80 flex flex-col gap-6 ">
      <div className="flex gap-3">
        {/* DisplayBtns is a fuction that returns the btn on the basis of the mainCategory and the subCategory */}
        {DisplayBtns(
          mainCategory,
          subCategory,
          activeBtn,
          setActiveBtn,
          setEventType
        )}
      </div>
      <>
        {pickemEvents.length > 0
          ? pickemEvents.map((pickem, index) => (
              <div
                key={index}
                onClick={() => {
                  setEvent({
                    id: pickem.id,
                    startDate: pickem.date,
                    startTime: pickem.time,
                  });
                  setSelectedPickem({
                    index: index,
                    selected: !selectedPickem.selected,
                  });
                  setIsDisabled(false);
                }}
                className={ClassNames(
                  "flex flex-col pl-6 2xl:pl-8 gap-3 py-3 border-2 border-gray-300 shadow-md p-3 rounded-lg cursor-pointer",
                  selectedPickem.index === index && selectedPickem.selected || eventId===pickem.id
                    ? "bg-blue-400 text-white"
                    : ""
                )}
              >
                {eventType === "STOCKS" || eventType === "FUNDS" ? (
                  <StockCard stock={pickem} />
                ) : eventType === "MATCH" ? (
                  <MatchCard match={pickem} />
                ) : (
                  ""
                )}
              </div>
            ))
          : isLoading && <Spinner />}
      </>
    </div>
  );
};
