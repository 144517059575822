import React, { useState } from "react";
import { Tabs } from "Components/Common/Tabs/Tabs";
import { UserProfiles } from "./UserProfiles";
import { UserFeedback } from "./UserFeedback";
import { ReportedUsers } from "./ReportedUsers";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";

const PROFILES = "Users";
const FEEDBACK = "Feedback";
const REPORTED_USERS = "Reported User";
const tabs = [
  { name: "Users" },
  { name: "Reported User" },
  { name: "Feedback" },
];

export const Profiles = () => {
  const [activeTab, setActiveTab] = useLocalStorage(
    "users_current_tab",
    PROFILES
  );
  const handleTabClick = (tabName) => {
    if (tabName === REPORTED_USERS) {
      setActiveTab(REPORTED_USERS);
    } else if (tabName === FEEDBACK) {
      setActiveTab(FEEDBACK);
    } else {
      setActiveTab(PROFILES);
    }
  };

  return (
    <>
      <span>User Profiles</span>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTab={(tabName) => handleTabClick(tabName)}
      />
      <br />
      {activeTab === PROFILES ? (
        <UserProfiles />
      ) : activeTab === REPORTED_USERS ? (
        <ReportedUsers />
      ) : (
        <UserFeedback />
      )}
    </>
  );
};
