import { Button } from "Components/Common/Button/Button";
import { CREATE_ADMIN_PATH } from "Constants/View";
import { useGlobalContext } from "Context/Global";
import { AdminsListing } from "./AdminsListing";

export const AdminMainView = () => {
  return (
    <div className="flex flex-col">
      <Header />
    </div>
  );
};
const Header = () => {
  const { setActiveView } = useGlobalContext();
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <span className="text-sm leading-5 font-medium text-blue-dark">
          Rankpage Admins
        </span>
        <Button
          btnText="Add an Admin"
          className="w-40 h-8 bg-blue-dark text-white"
          isDisabled={false}
          isLoading={false}
          onClick={() => {
            setActiveView(CREATE_ADMIN_PATH);
          }}
        />
      </div>
      <Divider />
      <AdminsListing />
    </div>
  );
};
const Divider = () => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-28  border-2 border-t border-blue-dark" />
        <div className="w-full  border-t border-coolGray" />
      </div>
    </div>
  );
};
