import ClassNames from "Helpers/Common";
import "./Divider.css";

export const Divider = ({
    type="horizontal",
    className
}) => (
    <span className={ClassNames(
        `Divider-${type}`,
        className
    )}/>
)