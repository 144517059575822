import { Dividers } from "Components/PicekmCreation/Dividers";
import { InputField } from "Components/Common/InputField/InputField";
import { RadioButton } from "Components/Common/RadioButton/RadioButton";

const SMALL = 3;
const MEDIUM = 4;
const LARGE = 5;

export const DetailForm = ({detailForm,setDetailForm}) => {
 
  return (
    <div className="flex flex-col gap-6">
      {" "}
      <Dividers text="Title" />
      <div className="w-96">
        <InputField
          id="widget-title"
          type="text"
          isRequired={true}
          value={detailForm.title}
          onBlur={(title) => {
            setDetailForm(prevState => ({
              ...prevState,
              "title":title
          }));
          }}
        />
      </div>
      <Dividers text="Size" />
      <fieldset className="mt-4 space-y-6">
        <div className="space-y-4">
          <RadioButton
            id="1"
            label="Small (add 3 items)"
            value={SMALL}
            isSelected={detailForm.numOfItems === SMALL}
            onChange={() => {
              setDetailForm(prevState => ({
                ...prevState,
                "numOfItems":  SMALL
            }));
            }}
          />
          <RadioButton
            id="2"
            label="Medium (add 4 items)"
            value={MEDIUM}
            isSelected={detailForm.numOfItems === MEDIUM}
            onChange={() => {
              setDetailForm(prevState => ({
                ...prevState,
                "numOfItems": MEDIUM
            }));
            }}
          />
          <RadioButton
            id="3"
            label="Large (add 5 items)"
            value={LARGE}
            isSelected={detailForm.numOfItems === LARGE}
            onChange={() => {
              setDetailForm(prevState => ({
                ...prevState,
                "numOfItems":  LARGE
            }));
            }}
          />
        </div>
      </fieldset>
    </div>
  );
};
