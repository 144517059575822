import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for searching
 * @param {String} searchQuery for searching
 *  * @param {Number} pageNo current page number
 * @param {Number} perPage total number of records to retrieve
 * @returns
 */
export const BaseSearch = async (
  searchQuery = "",
  pageNo = 1,
  perPage = 10
) => {
  const {
    data: { result },
  } = await Axios.post(
    `${API_BASE}admin/search?page=${pageNo}&perPage=${perPage}`,
    {
      partialText: searchQuery,
    }
  );
  console.log(`[BaseSearch][API_RESPONSE]`, result);
  return result;
};
/**
 * 
 * @param {Object} payload Object containing the filter values
 * Payload_Example :{
	"content_type": "Users",
	"partialText": "khalid",
	"gender": "Male",
	"max_age": "40",
	"min_age": "18",
	"category_id": null
}
 * @param {*} pageNo current page number
 * @param {*} perPage no of records to retrieve
 * @returns 
 */
export const FilterUsers = async (payload, pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.post(
    `${API_BASE}admin/search?page=${pageNo}&perPage=${perPage}`,
    payload
  );
  console.log(`[FilterUsers][API_RESPONSE]`, result);
  return result;
};
