import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

const rankpageItemsFormatter = ({ data, pagination }) => {
  return {
    rankItems: data,
    hasNext: pagination.hasNext,
    hasPrevious: pagination.hasPrevious,
    page: pagination.page,
    perPage: pagination.perPage,
  };
};

/**
 * @description  An API for getting the Widgets
 * @param {Number} pageNo current page number
 * @param {Number} perPage total number of events per page
 * @returns the list of the widgets
 */
export const WidgetList = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/widget?&page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[WidgetList][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for deleting the widget
 * @param {String} id of the widget
 * @returns
 */
export const DeleteWidget = async (id) => {
  const {
    data: { result },
  } = await Axios.delete(`${API_BASE}admin/widget/${id}`);
  console.log(`[DeleteWidget][API_RESPONSE]`, result);
  return result;
};
export const FetchRankpageItems = async () => {
  const response = await Axios.get(`${API_BASE}ranklist/item/all`);
  console.log(`[FetchRankpageItems][API_RESPONSE] - `, response);
  return rankpageItemsFormatter(response.data.result);
};

export const FetchQueriedRankpageItems = async (title) => {
  const response = await Axios.get(
    `${API_BASE}ranklist/item/all?page=1&per_page=1&title=${title}`
  );
  console.log(`[FetchRankpageItems][API_RESPONSE] - `, response);
  return rankpageItemsFormatter(response.data.result);
};
/**
 * @description  An API for creating widget
 * @param {Object} payload of the widget 
 * Payload_Example:{
 * "title": "Best movies of all time",
    "size": 3,
    "ordered_items": ["61b1ede8354c49aaeff5c00a","61b1edec354c49aaeff5c00b","61b1ede5354c49aaeff5c009"],
 * }
 * @returns 
 */
export const CreateWidget = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}admin/widget`, payload);
  console.log(`[CreateWidget][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting the widget
 * @param {String} id of the widget to retrieve
 * @returns
 */
export const GetWidget = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`${API_BASE}widget/${id}`);
  console.log(`[GetWidget][API_RESPONSE]`, result);
  return result;
};

/**
 * @description  An API for participating in the widget
 * @param {String} id of the widget
 * @param {Object} payload array of the selected items id's
 * Payload_Example : { "participant_choices":[
                    "61b1ede5354c49aaeff5c009",
                    "61b1edec354c49aaeff5c00b",
                    "61b1ede8354c49aaeff5c00a"
                ]}
 * @returns 
 */
export const WidgetParticipation = async (id, payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}widget/participate/${id}`, payload);
  console.log(`[WidgetParticipation][API_RESPONSE]`, result);
  return result;
};
