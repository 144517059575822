/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { ReactComponent as UpIcon } from "Assets/Icons/ArrowUp.svg";
import { ReactComponent as DownIcon } from "Assets/Icons/ArrowDown.svg";
import { ReactComponent as ProfileImage } from "Assets/Icons/Profile.svg";
import "./Collapse.css";

export const Collapse = ({ listData }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <>
      <div className="text-gray-400 bg-gray-50 py-3 flex justify-between ">
        Items{" "}
        {isCollapsed ? (
          <UpIcon onClick={() => setIsCollapsed((x) => !x)} />
        ) : (
          <DownIcon onClick={() => setIsCollapsed((x) => !x)} />
        )}
      </div>
      {!isCollapsed && (
        <div className="flex flex-col gap-4">
          {listData?.map((user, index) => (
            <div
              key={index}
              className="flex gap-6 items-center py-4 px-2 rounded-lg bg-white shadow-lg border border-gray-200"
            >
              {index + 1}
              <span className="inline-block h-16 w-16  overflow-hidden object-fit object-center rounded-md bg-gray-100">
                {user.image ? (
                  <img  src={user.image} className="h-16 w-16"/>
                ) : (
                  <ProfileImage />
                )}
              </span>
              {user.title}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
