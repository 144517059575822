import React, { useEffect, useState } from "react";
import { Footer } from "./Footer";
import { useGlobalContext } from "Context/Global";
import { ApiError } from "Components/Common/ApiError";
import { BASE_NEW_PASSWORD_PATH } from "Constants/View";
import { Button } from "Components/Common/Button/Button";
import { HEADING, SUB_HEADING, BTN_TEXT } from "./Common";
import { ForgotPasswordReq } from "Services/API/Login.service";
import { InputField } from "Components/Common/InputField/InputField";
import "./Login.css";

export const ForgotPassword = () => {
  const { setForgotPasswordDetails, setActiveView } = useGlobalContext();
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setIsDisabled(!email);
  }, [email]);

  const handleSubmit = () => {
    if (email) {
      setIsLoading(true);
      ForgotPasswordReq({ email })
        .then((res) => {
          setForgotPasswordDetails({
            otp: res.otp,
            resetToken: res.reset_token,
          });
          setActiveView(BASE_NEW_PASSWORD_PATH);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsDisabled(true);
          setIsLoading(false);
          setApiError(err?.response?.data?.errors || err?.message);
          console.error("[ForgotPasswordReq][API_ERROR] - ".err);
        });
    }
  };
  return (
    <div className="Forgot_Password_Card flex flex-col gap-3">
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="flex flex-col xl:gap-16 2xl:gap-60 border-2 border-gray-200 rounded-md ">
        <div className="p-4">
          <span className={`flex justify-center ${HEADING}`}>
            Forgot Password
          </span>
          <div className={`mt-4 ${SUB_HEADING}`}>
            Enter your registered email address to receive a verification code
          </div>
          <div className="lg:space-y-4 xl:space-y-6 2xl:space-y-10 xl:mt-6 2xl:mt-10">
            <InputField
              id="email"
              type="email"
              placeholder="Email"
              isRequired={true}
              onChange={(fieldValue) => setEmail(fieldValue.trim())}
              onBlur={(fieldValue, setInputFieldError) => {
                if (
                  !(fieldValue.includes("@") && fieldValue.includes(".com"))
                ) {
                  setEmail("")
                  setInputFieldError("EMAIL_INVALID");
                  setIsDisabled(true);
                } else if (fieldValue) {
                  setEmail(fieldValue.trim());
                }
              }}
            />
            <Button
              btnText="Continue"
              className={`w-full py-2  ${BTN_TEXT} ${
                !isDisabled ? "bg-blue-400 text-white" : ""
              }`}
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
