import { useEffect, useState } from "react";
import ClassNames from "Helpers/Common";
import { Button } from "Components/Common/Button/Button";
import { CreateFAQs, UpdateFAQs } from "Services/API/FAQs.service";
import { InputField } from "Components/Common/InputField/InputField";
import { Description } from "Components/Common/TextArea/Description";
import { ReactComponent as BackIcon } from "Assets/Icons/BackArrow.svg";
import "./FAQs.css";

const VIEW_MODE = "ViewMode";

export const EditFaqs = ({ docDetails, setActiveView }) => {
  const { title, description, id } = docDetails;
  const [faqTitle, setTitle] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [faqDescription, setDescription] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  useEffect(() => {
    if (title) {
      setTitle(title);
    }
    if (description) {
      setDescription(description);
    }
    setIsDisabled(!(title && description ));
  }, [title, description]);

  return (
    <div className="flex gap-4 FAQs_Main_Wrapper">
      <div className="flex flex-col gap-3 p-4 bg-white FAQS_First_Coulnm">
        <span className="flex gap-3 items-center h-4">
          <span
            onClick={() => setActiveView(VIEW_MODE)}
            className="cursor-pointer"
          >
            <BackIcon />
          </span>{" "}
          {title ? title : "FAQs"}
        </span>
      </div>
      <div className="flex flex-col  gap-6 bg-white FAQS_Second_Coulnm p-6">
        <div className="FAQS_Content_Wrapper flex flex-col gap-4">
          <InputField
            id="title"
            type="title"
            label="Title"
            value={faqTitle}
            isRequired={true}
            onBlur={(fieldValue, setInputFieldError) => {
              if (!fieldValue) {
                setInputFieldError("Title is required");
                setIsDisabled(true);
              } else if (fieldValue) {
                setTitle(fieldValue);
                setIsDisabled(false);
              }
            }}
          />
          <Description
            rows={18}
            id="description"
            type="description"
            value={faqDescription}
            label="Description"
            isRequired={true}
            onChange={(fieldValue) => {
              setDescription(fieldValue);
              setIsDisabled(fieldValue?false:true)
            }}
          />
        </div>
        <div className="flex justify-end border-t-2 border-gray-200  p-4 xl:p-6">
          <Button
            btnText="Save"
            className={ClassNames(
              "w-24 py-2",
              !isDisabled && "bg-blue-dark text-white"
            )}
            isDisabled={isDisabled}
            isLoading={isBtnLoading}
            onClick={async () => {
              if (faqTitle && faqDescription) {
                setIsBtnLoading(true);
                if (id) {
                  await UpdateFAQs(id, {
                    question: faqTitle,
                    answer: faqDescription,
                  })
                    .then((res) => {
                      setIsBtnLoading(false);
                      setActiveView(VIEW_MODE);
                    })
                    .catch((err) => {
                      console.error("[UpdateFAQs][API_ERROR] - ", err);
                      setIsBtnLoading(false);
                    });
                } else {
                  await CreateFAQs({
                    question: faqTitle,
                    answer: faqDescription,
                  })
                    .then((res) => {
                      setIsBtnLoading(false);
                      setActiveView(VIEW_MODE);
                    })
                    .catch((err) => {
                      console.error("[CreateFAQs][API_ERROR] - ", err);
                      setIsBtnLoading(false);
                    });
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
