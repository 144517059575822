export default function ClassNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const imgToBase64 = (imgFile) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(imgFile);
    reader.onload = ({target}) => {
      resolve(Buffer(target.result).toString('base64'))
    }
    reader.onerror = error => reject(error);
  });
}
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}