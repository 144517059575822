import { useState } from "react";
import ClassNames from "Helpers/Common";
import Table from "Components/Common/Table/Table";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import { ReactComponent as SadFaceIcon } from "Assets/Icons/SadFace.svg";

const HEADINGS = ["Name", "Username", "Email", "Date of Birth", "Gender", " "];
const TABLE_CSS = "px-6 py-4 cursor-pointer text-sm font-normal";

export const UsersTable = ({
  isLoading = true,
  usersData,
  hasNextPage,
  hasPreviousPage,
  setPageNumber,
  pageNumber,
}) => {
  const [userDetails, setUserDetails] = useState({
    DOB: "",
    bio: "",
    image: "",
    email: "",
    gender: "",
    userName: "",
    lastName: "",
    firstName: "",
  });
  const [isSideView, setIsSideView] = useState(false);
  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && usersData?.length > 0 ? (
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={usersData?.map((user, index) => (
            <tr key={index}>
              <td className={TABLE_CSS}>
                <div>
                  {user.firstName} {user.lastName}
                </div>
              </td>
              <td className={ClassNames("text-gray-secondary", TABLE_CSS)}>
                <div>{user.userName}</div>
              </td>
              <td className={ClassNames("text-gray-secondary", TABLE_CSS)}>
                <div>{user.email}</div>
              </td>
              <td className={ClassNames("text-gray-secondary", TABLE_CSS)}>
                <div>{user.DOB}</div>
              </td>
              <td className={ClassNames("text-gray-secondary", TABLE_CSS)}>
                <div>{user.gender}</div>
              </td>
              <td className={TABLE_CSS}>
                <span
                  className="flex justify-center text-blue-dark"
                  onClick={() => {
                    setUserDetails({
                      firstName: user.firstName,
                      lastName: user.lastName,
                      username: user.userName,
                      image: user.profileImg,
                      gender: user.gender,
                      email: user.email,
                      bio: user.bio,
                      DOB: user.DOB,
                    });
                    setIsSideView((prevState) => !prevState);
                  }}
                >
                  View
                </span>
              </td>
            </tr>
          ))}
        />
      ) : (
        !isLoading && (
          <div className="flex flex-col gap-2 items-center justify-center md:h-96">
            <SadFaceIcon className="h-16 w-16 animate-bounce text-gray-secondary" />
            <span className="text-sm font-medium animate-pulse">
              No data found.Reset the filters
            </span>
          </div>
        )
      )}
      {!isLoading && usersData?.length > 0 && (
        <PaginationBtns
          isNextDisabled={!hasNextPage}
          isPrevDisabled={!hasPreviousPage}
          onNext={() => {
            if (hasNextPage) {
              setPageNumber(pageNumber + 1);
            }
          }}
          onPrevious={() => {
            if (hasPreviousPage) {
              setPageNumber(pageNumber - 1);
            }
          }}
        />
      )}
      {isSideView && (
        <SideView
          setIsSideView={setIsSideView}
          heading={userDetails?.firstName}
          subHeading={userDetails?.email}
          profileImage={userDetails?.image}
        >
          <div className="flex flex-col justify gap-6 pt-4">
            <div className="grid grid-cols-2 gap-5">
              <div className="text-gray-400">
                UserName{" "}
                <div className="text-black mt-1">{userDetails?.userName}</div>
              </div>
              <div className="text-gray-400">
                Date of Birth{" "}
                <div className="text-black mt-1">{userDetails?.DOB}</div>
              </div>
              <div className="text-gray-400">
                Gender{" "}
                <div className="text-black mt-1">{userDetails?.gender}</div>
              </div>
            </div>
            <div className="text-gray-400">
              About
              <div className="text-black mt-1">{userDetails?.bio}</div>
            </div>
          </div>
        </SideView>
      )}
    </>
  );
};
