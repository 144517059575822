import { Button } from "Components/Common/Button/Button";
import { Collapse } from "Components/Common/Collapseable/Collapse";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { useState } from "react";
import { DeletePickem, HidePickem } from "Services/API/Pickem.service";

export const ViewDetails = ({
  setIsSideView,
  picekmDetails,
  onHideAndDelete,
}) => {
  const [isHideBtnLoading, setHideIsBtnLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const { category,title, uploadedBy, startingDate } = picekmDetails;
  return (
    <SideView
      showProfileImage={false}
      setIsSideView={setIsSideView}
      heading={title??""}
      subHeading={<span className="text-blue-400">{category??""}</span>}
    >
      <div className="flex flex-col justify gap-6 pt-4">
        <div className="grid grid-cols-2 gap-5">
          <div className="text-gray-400">
            Uploaded By{" "}
            <div className="text-black mt-1">{picekmDetails?.uploadedBy}</div>
          </div>
          <div className="text-gray-400">
            Date{" "}
            <div className="text-black mt-1">{picekmDetails?.startingDate}</div>
          </div>
          <div className="text-gray-400">
            Event{" "}
            <div className="text-black mt-1">{picekmDetails?.eventTitle}</div>
          </div>{" "}
          <div className="text-gray-400">
            Sub-Category{" "}
            <div className="text-black mt-1">{picekmDetails?.subCategory}</div>
          </div>
        </div>
        <div className="flex gap-3">
          <Button
            btnText={picekmDetails.hidden ? "Unhide" : "Hide"}
            className="w-24 py-2 bg-white border border-gray-300"
            isDisabled={false}
            isLoading={isHideBtnLoading}
            onClick={() => {
              setHideIsBtnLoading(true);
              HidePickem(picekmDetails.id)
                .then((res) => {
                  if (res) {
                    setHideIsBtnLoading(false);
                    setIsSideView(false);
                    onHideAndDelete?.();
                  }
                })
                .catch((err) => {
                  console.error("[HideRankpage][API_ERROR] - ", err);
                  setHideIsBtnLoading(false);
                });
            }}
          />{" "}
          <Button
            btnText="Delete"
            className="w-24 py-2 bg-red-400 text-white"
            isDisabled={false}
            isLoading={isBtnLoading}
            onClick={() => {
              setIsBtnLoading(true);
              DeletePickem(picekmDetails.id)
                .then((res) => {
                  if (res) {
                    setIsBtnLoading(false);
                    setIsSideView(false);
                    onHideAndDelete?.();
                  }
                })
                .catch((err) => {
                  console.error("[DeleteRankpage][API_ERROR] - ", err);
                  setIsBtnLoading(false);
                });
            }}
          />
        </div>
        <Collapse listData={picekmDetails?.eventList} />
      </div>
    </SideView>
  );
};
