import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for getting the rankpage pickems
 * @param {Number} pageNo total no of records to retrieve
 * @param {Number} perPage current page number
 * @param {Boolean} payload flag to retrieve the rankpage pickems
 * @returns the rankpage pickems
 */
export const GetRankpagePickem = async (
  pageNo = 1,
  perPage = 10,
  payload = { isAdmin: true }
) => {
  const {
    data: { result },
  } = await Axios.post(
    `${API_BASE}admin/pickem/all?page=${pageNo}&perPage=${perPage}`,
    payload
  );
  console.log(`[GetRankpagePickem][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting the users pickems
 * @param {Number} pageNo total no of records to retrieve
 * @param {Number} perPage current page number
 * @param {Boolean} payload flag to retrieve the user pickems
 * @returns the user pickems
 */
export const GetUsersPickem = async (
  pageNo = 1,
  perPage = 10,
  payload = { isAdmin: false }
) => {
  const {
    data: { result },
  } = await Axios.post(
    `${API_BASE}admin/pickem/all?page=${pageNo}&perPage=${perPage}`,
    payload
  );
  console.log(`[GetUsersPickem][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting the reported pickems
 * @param {Number} pageNo total no of records to retrieve
 * @param {Number} perPage current page number
 * @returns the reported pickems
 */
export const GetReportedPickem = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/pickem/reported?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetReportedPickem][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting the trending pickems
 * @param {Number} pageNo total no of records to retrieve
 * @param {Number} perPage current page number
 * @returns the trending pickems
 */
export const GetTrendingPickems = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/pickem/trending?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetTrendingPickems][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for deleteing pickem
 * @param {String} id Pickem id for deletion
 * @returns
 */
export const DeletePickem = async (id) => {
  const {
    data: { result },
  } = await Axios.delete(`${API_BASE}admin/pickem/${id}`);
  console.log(`[DeletePickem][API_RESPONSE]`, result);
  return result;
};

/**
 * @description  An API for hiding pickem
 * @param {String} id Pickem id for hiding
 * @returns
 */
export const HidePickem = async (id) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}admin/pickem/hide/${id}`);
  console.log(`[HidePickem][API_RESPONSE]`, result);
  return result;
};

