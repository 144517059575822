import React from "react";
import { ReactComponent as AppIcon } from "Assets/Icons/Logo.svg";
import { ReactComponent as LoginIcon } from "Assets/Icons/Frame.svg";
import "./LoginLayout.css";

export const LoginLayout = ({ children }) => {
  return (
    <div className="min-w-full min-h-screen bg-gray-200 ">
      <div className="flex py-4 pl-24 bg-white">
        <AppIcon />
      </div>
      <div className="Login_Layout_Wrapper flex xl:gap-40 2xl:gap-80 items-center rounded-md bg-white">
        <span className="flex xl:pl-32 2xl:pl-52">
          <LoginIcon />
        </span>
        {children}
      </div>
    </div>
  );
};
