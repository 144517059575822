import { formatDate } from "Helpers/DateFormaater";
import { Button } from "Components/Common/Button/Button";
import { formatRanklistItems } from "Components/Rankpages/APIFormatters";

export const formattedFilteredUsers = (users) => {
    return users.map((user) => {
      return {
        id: user._id,
        firstName: user?.first_name ?? "",
        last_name: user?.last_name ?? "",
        userName: user.username ?? "",
        email: user?.email ?? "",
        gender: user?.gender ?? "",
        DOB: formatDate(user?.date_of_birth, "MM/DD/YYYY"),
        profileImg: user?.profile_image_url ?? "",
        bio: user?.bio ?? "",
      };
    });
  };
export const formattedFilteredRasnkpages = (rankPages) => {
  return rankPages?.map((rankPage) => {
    return {
      id: rankPage?._id,
      title: rankPage.title,
      uploadedBy: rankPage?.posted_by?.first_name,
      uploadDate: formatDate(rankPage.createdAt, "MM/DD/YYYY"),
      orderedItems: formatRanklistItems(rankPage?.ordered_items),
      category: rankPage?.categories[0]?.title,
    };
  });
};
export const formattedCategories = (categories) => {
  return categories.map((category) => {
    return {
      id: category.id,
      description: category.description,
      image: category.image_url,
      title: category.title,
    };
  });
};

export const FilterBtn = ({ Icon, text, onClick }) => {
  return (
    <Button
      btnText={text}
      className="flex justify-around mt-1 w-36 h-9 bg-white border-gray-300  focus:ring-blue-400 focus:border-blue-400"
      isDisabled={false}
      isLoading={false}
      onClick={() => onClick?.()}
      Icon={Icon}
    />
  );
};
export const CONTENT_FILTER = ["User Profiles", "Rankpages"];
export const GENDER_FILTER = ["Male", "Female", "Other", "All"];
export const AGE_FILTER = ["18 - 34", "35 - 54", "54 -64", "65+", "All"];
export const INTERACTIONS_FILTER = [
  { value: "reorders", label: "Most Reorders" },
  { value: "reactions", label: "Most Reactions" },
  { value: "comments", label: "Most Comments" },
  { value: "", label: "Reset" },
];
