import ClassNames from "Helpers/Common";
import { ReactComponent as XIcon } from "Assets/Icons/Cross.svg";
import "./Badge.scss";

export const Badge = ({
  id,
  index,
  title,
  className,
  onCrossIcon,
  setRemoveInterests,
}) => {
  return (
    <span key={index} className={ClassNames("badge flex gap-3", className)}>
      {title}{" "}
      <XIcon
        className="h-5 w-5 text-white cursor-pointer"
        onClick={() => {
          setRemoveInterests(true);
          onCrossIcon?.(id);
        }}
      />
    </span>
  );
};
