import React from "react"
export const TableHeading = ({TableHeader}) => {
    return TableHeader.map((head, index) => (
      <th
        key={index}
        scope="col"
        className="text-gray-secondary px-6 py-3 text-left text-sm font-normal"
      >
        {head}
      </th>
    ));
  };