import React, { useEffect, useState } from "react";
import Table from "Components/Common/Table/Table";
import { ViewDetails } from "./Common/ViewDetails";
import { reportedRankpagesFormatter } from "./APIFormatters";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { GetReportedRankpages } from "Services/API/Ranklists.service";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import "./Rankpage.css";

const HEADINGS = ["Title", "Uploaded By", "Reported By", " "];
const TABLE_CSS = "px-6 py-4 cursor-pointer text-sm";

export const ReportedRankpages = () => {
  const [isSideView, setIsSideView] = useState(false);
  const [reportedRankpages, setReportedRankpages] = useState([]);
  const [reportedRankpageUser, setReportedRankpageUser] = useState({
    id: "",
    hide: "",
    title: "",
    reason:"",
    category: "",
    rankLists: "",
    uploadedBy: "",
    reportedBy: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(7);
  const [hideDeleteuser, setHideDeleteUser] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    GetReportedRankpages(pageNumber, perPage)
      .then((res) => {
        setReportedRankpages(reportedRankpagesFormatter(res.data));
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("[GetReportedRankpages][API_ERROR] - ", err);
        setIsLoading(false);
      });
    return () => {
      setReportedRankpages([]);
    };
  }, [pageNumber, hideDeleteuser]);
  return (
    <>
      <div
        className={`flex flex-col gap-4 ${
          isSideView ? "Table" : "Orignal_Table_Width"
        }`}
      >
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={
            reportedRankpages.length > 0 &&
            reportedRankpages.map((user, index) => (
              <tr
                key={index}
                className={user.hidden ? "bg-gray-100 text-gray-500" : ""}
              >
                <td className={TABLE_CSS}>
                  <div className="font-bold">{user.title}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div className="text-gray-secondary">{user.uploadedBy}</div>
                </td>
                <td className={TABLE_CSS}>
                  <div className="text-gray-secondary">{user.reportedBy}</div>
                </td>

                <td className="px-4 py-4 text-left text-sm font-medium">
                  <span
                    onClick={() => {
                      setReportedRankpageUser({
                        id: user.id,
                        title: user.title,
                        hide: user.hidden,
                        category: user.category,
                        rankLists: user.rankLists,
                        reason:user.reportedReason,
                        uploadedBy: user.uploadedBy,
                        reportedBy: user.reportedBy,
                      });
                      setIsSideView((x) => !x);
                    }}
                    className="cursor-pointer text-blue-dark"
                  >
                    {isSideView ? "" : "View"}
                  </span>
                </td>
              </tr>
            ))
          }
        />
        {!isLoading &&reportedRankpages?.length <= 0 &&<div className="flex bg-white w-full justify-center items-center h-96">No Reported Rankpages found at the moment</div>}
        {isLoading && <Spinner />}
        {!isLoading && reportedRankpages.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
      </div>
      {isSideView && (
        <ViewDetails
          setIsSideView={setIsSideView}
          rankpageDetails={reportedRankpageUser}
          setHideDeleteUser={setHideDeleteUser}
        >
          <div className="text-gray-400">
            Uploaded By
            <div className="text-black mt-1">
              {reportedRankpageUser.uploadedBy}
            </div>
          </div>
          <div className="text-gray-400">
            Reported By{" "}
            <div className="text-black mt-1">
              {reportedRankpageUser.reportedBy}
            </div>
          </div>
          <div className="text-gray-400">
            Reported Reason{" "}
            <div className="text-black mt-1">
              {reportedRankpageUser.reason}
            </div>
          </div>
        </ViewDetails>
      )}
    </>
  );
};
