import { useEffect, useState } from "react";
import { EditFaqs } from "./EditFaqs";
import Table from "Components/Common/Table/Table";
import { GetFAQs } from "Services/API/FAQs.service";
import { Button } from "Components/Common/Button/Button";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";

const HEADINGS = ["Name", " "];

const TABLE_CSS = "px-6 py-4 cursor-pointer text-sm font-medium";
const VIEW_MODE = "ViewMode";
const EDIT_MODE = "EditMode";
const DOCS_INIT_STATE = { id: "", title: "", description: "" };
export const FAQs = () => {
  const [activeView, setActiveView] = useLocalStorage("faqs_view", VIEW_MODE);
  const [docDetails, setDocDetails] = useState(DOCS_INIT_STATE);

  return activeView === VIEW_MODE ? (
    <MainTableView
      setActiveView={setActiveView}
      setDocDetails={setDocDetails}
    />
  ) : (
    <EditFaqs docDetails={docDetails} setActiveView={setActiveView} />
  );
};

const faqFormatter = (faqs) => {
  return faqs.map((faq) => {
    return {
      id: faq.id,
      title: faq.question,
      description: faq.answer,
    };
  });
};
const MainTableView = ({ setDocName, setActiveView, setDocDetails }) => {
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(7);

  useEffect(() => {
    setIsLoading(true);
    GetFAQs()
      .then((res) => {
        if (res) {
          setFaqs(faqFormatter(res.data));
          setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("[GetFAQs][API_ERROR] - ", err);
      });
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between">
        <span className="text-lg font-medium text-black-light">FAQs</span>

        <Button
          btnText="FAQ"
          className="w-16 h-8 bg-blue-dark text-white"
          isDisabled={false}
          isLoading={false}
          onClick={() => {
            setDocDetails(DOCS_INIT_STATE);
            setActiveView(EDIT_MODE);
          }}
        />
      </div>
      <Table
        heading={<TableHeading TableHeader={HEADINGS} />}
        body={
          faqs.length > 0 &&
          faqs.map((item, index) => (
            <tr key={index}>
              <td className={TABLE_CSS}>
                <div>{item.title}</div>
              </td>
              <td className={TABLE_CSS}>
                <span
                  onClick={() => {
                    setDocDetails({
                      title: item.title,
                      description: item.description,
                      id: item.id,
                    });
                    setActiveView(EDIT_MODE);
                  }}
                  className="flex justify-center text-blue-dark"
                >
                  {item.title && "Edit"}
                </span>
              </td>
            </tr>
          ))
        }
      />
      {isLoading && <Spinner />}
      {!isLoading && faqs.length > 0 && (
        <PaginationBtns
          isNextDisabled={!hasNextPage}
          isPrevDisabled={!hasPreviousPage}
          onNext={() => {
            if (hasNextPage) {
              setPageNumber(pageNumber + 1);
            }
          }}
          onPrevious={() => {
            if (hasPreviousPage) {
              setPageNumber(pageNumber - 1);
            }
          }}
        />
      )}
    </div>
  );
};
