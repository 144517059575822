import { forwardRef } from "react";
import "./RankedItem.css";
import ClassNames from "Helpers/Common";
import { ReactComponent as ProfileImage } from "Assets/Icons/Profile.svg";
import { Image } from "../Image/Image";

export const RankedItem = forwardRef(
  (
    {
      ranking,
      imgUrl = "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/v1432049687/qbhv1wb2jsqtf85bnzzm.png",
      title,
      className,
      icon,
      onClick,
      imageShape = "square",
      ...props
    },
    ref,
  ) => {
    const themes = "light";
    return (
      <div
        ref={ref}
        onClick={onClick}
        onMouseEnter={(event) => props.onMouseEnter?.(event)}
        className={ClassNames("RankedItem_Wrapper", className)}
      >
        <div
          className={ClassNames(
            "RankedItemContent rounded-none",
            themes,
            icon && "RankedItemContent_WithIcon",
          )}
        >
          <div className={"RankedItemContent_LeftSection"}>
            {imageShape === "square" ? (
              <div className="RankedItem_ImgWrapper">
                <Image src={imgUrl} className="RankedItem_Img h-full" />
              </div>
            ) : imgUrl ? (
              <img className="h-6 w-6 rounded-full" src={imgUrl} />
            ) : (
              <ProfileImage className="h-6 w-6 rounded-full" />
            )}
            <span
              className={ClassNames("RankedItem_Title truncate ...", themes)}
            >
              {title}
            </span>
          </div>
          {icon && <div className="RankedItemContent_RightSection">{icon}</div>}
        </div>
      </div>
    );
  },
);
