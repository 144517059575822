import React from "react"
import { AppLayout } from "Layouts/AppLayout/AppLayout"
import { Profiles } from "Components/UserProfiles/Profiles"

export const UserProfiles = () => {
    return (
        <div>
            <AppLayout>
                <Profiles/>
            </AppLayout>
        </div>
    )
}
