import { useState, useEffect } from "react";
import ClassNames from "Helpers/Common";
import Table from "Components/Common/Table/Table";
import { formatDate } from "Helpers/DateFormaater";
import { Button } from "Components/Common/Button/Button";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { TableHeading } from "Components/Common/Table/TableHeading";
import {
  DeleteAnnouncement,
  GetAnnouncements,
} from "Services/API/Announcement.service";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import { ReactComponent as AnnouncementIcon } from "Assets/Icons/Announcement.svg";

const HEADINGS = ["Title", "Description", "Date of Upload", " "];
const TABLE_CSS = "px-6 py-4 cursor-pointer text-sm font-normal";

export const AnnouncementListing = () => {
  const [announcement, setAnnouncements] = useState([]);
  const [isSideView, setIsSideView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(7);
  const [announcementDetails, setAnnouncementDetails] = useState({
    id: "",
    title: "",
    description: "",
  });
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const FetchAnnouncement = () => {
    setIsLoading(true);
    GetAnnouncements(pageNumber, perPage)
      .then((res) => {
        setAnnouncements([...res?.data]);
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("[GetAnnouncements][API_ERROR]", err);
      });
    return () => {
      setAnnouncements([]);
    };
  };
  useEffect(() => {
    FetchAnnouncement();
  }, [pageNumber]);
  return (
    <div>
      {isLoading && <Spinner />}
      {!announcement?.length > 0 && !isLoading ? (
        <div className="flex flex-col items-center mt-52 2xl:mt-72">
          <AnnouncementIcon />
          <span className="text-sm font-medium mt-1">No Announcements Yet</span>
        </div>
      ) : (
        announcement?.length > 0 &&
        !isLoading && (
          <div>
            <Table
              heading={<TableHeading TableHeader={HEADINGS} />}
              body={announcement.map((item, index) => (
                <tr key={index}>
                  <td className={TABLE_CSS}>
                    <div>{item.title}</div>
                  </td>
                  <td className={TABLE_CSS}>
                    <div>{item.description}</div>
                  </td>
                  <td className={ClassNames("text-gray-secondary", TABLE_CSS)}>
                    <div>{formatDate(item.updatedAt, "MM/DD/YYYY")}</div>
                  </td>
                  <td className={TABLE_CSS}>
                    <span
                      className="flex justify-center text-blue-dark"
                      onClick={() => {
                        setAnnouncementDetails({
                          id: item.id,
                          title: item.title,
                          description: item.description,
                        });
                        setIsSideView((prevState) => !prevState);
                      }}
                    >
                      {item.title && "View"}
                    </span>
                  </td>
                </tr>
              ))}
            />
            {!isLoading && (
              <PaginationBtns
                isNextDisabled={!hasNextPage}
                isPrevDisabled={!hasPreviousPage}
                onNext={() => {
                  if (hasNextPage) {
                    setPageNumber(pageNumber + 1);
                  }
                }}
                onPrevious={() => {
                  if (hasPreviousPage) {
                    setPageNumber(pageNumber - 1);
                  }
                }}
              />
            )}
          </div>
        )
      )}
      {isSideView && (
        <SideView showProfileImage={false} setIsSideView={setIsSideView}>
          <div className="flex flex-col gap-4 pt-2">
            <div className="flex flex-col gap-2">
              <span className="raven">Title</span>
              <span className="primary_black">{announcementDetails.title}</span>
            </div>
            <div className="flex flex-col gap-2">
              <span className="raven">Description</span>
              <span className="primary_black">
                {announcementDetails.description}
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <span className="raven">Delete</span>
              <span className="primary_black">
                Deletion will remove this announcement completely
              </span>
              <Button
                btnText="Delete"
                className="w-24 py-2 bg_red text-white"
                isDisabled={false}
                isLoading={isBtnLoading}
                onClick={() => {
                  setIsBtnLoading(true);
                  DeleteAnnouncement(announcementDetails.id)
                    .then((res) => {
                      if (res) {
                        setIsBtnLoading(false);
                        setIsSideView(false);
                        FetchAnnouncement();
                      }
                    })
                    .catch((err) => {
                      console.error("[DeleteAnnouncement][API_ERROR] - ", err);
                      setIsBtnLoading(false);
                    });
                }}
              />
            </div>
          </div>
        </SideView>
      )}
    </div>
  );
};
