import { AppLayout } from "Layouts/AppLayout/AppLayout";
import { PickemCreation } from "Components/PicekmCreation";

export const CreatePickemMainPage = () => {
  return (
    <AppLayout>
      <PickemCreation />
    </AppLayout>
  );
};
