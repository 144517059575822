import { SearchMainView } from "Components/SearchFilter/SearchMainView";
import { AppLayout } from "Layouts/AppLayout/AppLayout";

export const SearchFilter = () => {
  return (
    <AppLayout>
      <SearchMainView />
    </AppLayout>
  );
};
