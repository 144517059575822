import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import "./legalDocs.css";

export const TextEditor = ({
  convertedContent,
  setConvertedContent,
}) => {
  const [editorState, setEditorState] = useState(() => {
    const blocksFromHTML = convertFromHTML(convertedContent);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    return EditorState.createWithContent(contentState);
  });
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  
  return (
    <div className="Editor_Main_Wrapper p-6">
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        value=""
        toolbar={{
          options: ["inline"],
          inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline"],
          },
          list: { inDropdown: false },
          textAlign: { inDropdown: false },
          link: { inDropdown: false },
          history: { inDropdown: false },
        }}
      />
    </div>
  );
};
