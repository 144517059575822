export const mockDataPickem = [
  {
    title: "Best Young Adult Movies",
    status: "Live",
    uploadedBy: "Jane Cooper",
    dateOfUpload: "12/02/2020",
    reprtedBy: "Emma Chamberlain",
    reposts: "15k",
    reactions: "10k",
    closingDate: "10-03-2021",
    closingTime: "09:00 pm",
    rank:"expert"
  },
  {
    title: "Best Restaurants",
    status: "Closed",
    uploadedBy: "Kristin Watson",
    dateOfUpload: "05/04/2020",
    reprtedBy: "Andrew Lowe",
    reposts: "21k",
    reactions: "8k",
    closingDate: "12-05-2021",
    closingTime: "04:00 pm",
    rank:"expert"
  },
  {
    title: "Top 11 Movies in the Thriller Genre",
    status: "Live",
    uploadedBy: "Justin Cayeln",
    dateOfUpload: "05/30/2020",
    reprtedBy: "Britney Broski",
    reposts: "10k",
    reactions: "21k",
    closingDate: "04-23-2021",
    closingTime: "06:00 pm",
    rank:"expert"
  },
  {
    title: "Top 10 Travel Destinations",
    status: "Closed",
    uploadedBy: "Cody Fisher",
    dateOfUpload: "05/20/2020",
    reprtedBy: "Jasmine Sullivan",
    reposts: "2k",
    reactions: "15k",
    closingDate: "10-03-2021",
    closingTime: "09:00 pm",
    rank:"regular"
  },
  {
    title: "Top Architectural Designs",
    status: "Live",
    uploadedBy: "Cameron Williamson",
    dateOfUpload: "05/04/2020",
    reprtedBy: "Jenna Marbles",
    reposts: "1k",
    reactions: "4k",
    closingDate: "15-11-2021",
    closingTime: "08:00 pm",
    rank:"regular"
  },
];

export const rankpagePickems = [
  {
    name: "Joshua Basset",
    points: "107",
    position: "1st",
    rank: "Expert",
    img: "",
  },
  { name: "Alan", points: "101", position: "2nd", rank: "Expert", img: "" },
  {
    name: "Cumali Bey",
    points: "100",
    position: "3rd",
    rank: "Expert",
    img: "",
  },
  { name: "Farhat Abbas", points: "433", position: "4th", rank: "", img: "" },
  { name: "Joshua Estes", points: "35", position: "5th", rank: "", img: "" },
  { name: "Mark Edison", points: "33", position: "6th", rank: "", img: "" },
  { name: "Naila Basset", points: "43", position: "7th", rank: "", img: "" },
  { name: "Thomas Basset", points: "63", position: "8th", rank: "", img: "" },
  { name: "Eddie Murph", points: "23", position: "9th", rank: "", img: "" },
  { name: "Roland King", points: "54", position: "10th", rank: "", img: "" },
  { name: "Joshua Ellen", points: "45", position: "11th", rank: "", img: "" },
  {
    name: "Victoria Basset",
    points: "65",
    position: "12th",
    rank: "",
    img: "",
  },
  { name: "Alan", points: "65", position: "13th", rank: "", img: "" },
  { name: "Cumali Bey", points: "76", position: "14th", rank: "", img: "" },
  { name: "Farhat Abbas", points: "56", position: "15th", rank: "", img: "" },
  { name: "Joshua Estes", points: "25", position: "16th", rank: "", img: "" },
  { name: "Mark Edison", points: "65", position: "17th", rank: "", img: "" },
  {
    name: "Regina Philange",
    points: "85",
    position: "18th",
    rank: "",
    img: "",
  },
];
