import { LoginLayout } from "Layouts/LoginLayout/LoginLayout";
import { Login } from "Components/Login/Login";
import { NewPassword } from "Components/Login/NewPassword";
import { ForgotPassword } from "Components/Login/ForgotPassword";
import { Route, Switch } from "react-router";
import {
  BASE_FORGOT_PASSWORD_PATH,
  BASE_NEW_PASSWORD_PATH,
} from "Constants/View";

export const LoginMainPage = () => {
  return (
    <Switch>
      <LoginLayout>
        <Route
          exact
          path={BASE_FORGOT_PASSWORD_PATH}
          render={() => <ForgotPassword />}
        />
        <Route
          exact
          path={BASE_NEW_PASSWORD_PATH}
          render={() => <NewPassword />}
        />
        <Route exact path="/" render={() => <Login />} />
      </LoginLayout>
    </Switch>
  );
};
