import { AnnouncmentMainView } from "Components/Announcements/AnnouncmentMainView";
import { AppLayout } from "Layouts/AppLayout/AppLayout";

export const Announcements = () => {
  return (
    <AppLayout>
      <div className="flex flex-col gap-4">
        <span className="text-lg font-medium">Announcements</span>
        <AnnouncmentMainView />
      </div>
    </AppLayout>
  );
};
