import { Button } from "Components/Common/Button/Button";
import { ReactComponent as DefaultImageIcon } from "Assets/Icons/Profile.svg";
import ClassNames from "Helpers/Common";

const STOCKS = ["Stocks", "ETFs"];

export const DisplayBtns = (
  mainCategory,
  subCategory,
  activeBtn,
  setActiveBtn,
  setEventType
) => {
  if (mainCategory === "SPORTS") {
    return (
      <Button
        btnText="Matches"
        className="w-24 py-2 bg-blue-400 text-white"
        isDisabled={false}
        isLoading={false}
        onClick={() => {}}
      />
    );
  } else if (mainCategory === "FINANCE") {
    if (subCategory === "CRYPTO") {
      return (
        <Button
          btnText="Digital"
          className="w-24 py-2 bg-blue-400 text-white"
          isDisabled={false}
          isLoading={false}
          onClick={() => {}}
        />
      );
    } else if (subCategory === "FOREX") {
      return (
        <Button
          btnText="Physical"
          className="w-24 py-2 bg-blue-400 text-white"
          isDisabled={false}
          isLoading={false}
          onClick={() => {}}
        />
      );
    } else if (subCategory === "STOCKS") {
      return STOCKS.map((btnText) => (
        <>
          <Button
            btnText={btnText}
            className={ClassNames(
              "w-24 py-2",
              btnText === activeBtn
                ? "bg-blue-400 text-white"
                : "bg-white border-gray-300"
            )}
            isDisabled={false}
            isLoading={false}
            onClick={() => {
              setActiveBtn(btnText);
              if (btnText === "ETFs") {
                setEventType("FUNDS");
              } else {
                setEventType("STOCKS");
              }
            }}
          />
        </>
      ));
    }
    return;
  }
};
export const MatchCard = ({ match }) => {
  return (
    <>
      <div className="flex gap-4 cursor-pointer">
        <span> {match.name}</span>
        <span className="text-gray-600">{match.date}</span>
        <span className="text-gray-600">{match.time}</span>
      </div>
      <div className="flex gap-4">
        <span className="flex gap-4">
          <span>
            {match.contenstant1.image ? (
              <img
                className="h-7 w-7 rounded-full"
                src={match.contenstant1.image}
              />
            ) : (
              <DefaultImageIcon className="h-7 w-7 rounded-full bg-gray-300 text-gray-300 border border-gray-300" />
            )}{" "}
          </span>
          {match.contenstant1.name}
        </span>
        <span className="text-gray-300 ">vs</span>
        <span className="flex gap-4">
          <span>
            {match.contenstant2.image ? (
              <img
                className="h-7 w-7 rounded-full"
                src={match.contenstant2.image}
              />
            ) : (
              <DefaultImageIcon className="h-7 w-7 rounded-full bg-gray-300 text-gray-300 " />
            )}
          </span>
          {match.contenstant2.name}
        </span>
      </div>
    </>
  );
};
export const StockCard = ({ stock }) => {
  return (
    <div className="flex gap-4 cursor-pointer">
      <span> {stock?.name}</span>
    </div>
  );
};
