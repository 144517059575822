import { ReactComponent as CrossIcon } from "Assets/Icons/Cross.svg";
export const ApiError = ({ error, setApiError }) => {
  return (
    <div className="flex rounded-md bg-red-200 items-center justify-between p-2">
      <h3 className="text-sm font-medium text-red-800">{error}</h3>
      <CrossIcon
        className="h-4 w-4 sapphire cursor-pointer"
        onClick={() => setApiError("")}
      />
    </div>
  );
};
