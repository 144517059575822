import React from "react";
import ClassNames from "Helpers/Common";

export const Tabs = ({ tabs, activeTab, onTab }) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
          {tabs.map((tab) => (
            <option
              key={tab.name}
              onClick={() => {
                onTab(tab.name);
              }}
            >
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, index) => (
              <span
                key={index}
                onClick={() => {
                  onTab(tab.name);
                }}
                className={ClassNames(
                  tab.name === activeTab
                    ? "border-blue-400 text-blue-500 cursor-pointer"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                )}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
