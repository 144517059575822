import ClassNames from "Helpers/Common";
import { useRef, useEffect } from "react";
import "./Image.css";

export const Image = ({
  src,
  className,
  ...props
}) => {
  const imgRef = useRef();

  useEffect(()=>{
    if(imgRef.current) {
      const image = imgRef.current;
      image.style.backgroundImage = `url('${src}')`;
    }
  },[imgRef,src])

  return (
    <div
      ref={imgRef}
      className={ClassNames(
        "BackgroundImage",
        className
      )}
      {...props}
    />
  )
}