import { useEffect, useState } from "react";
import ClassNames from "Helpers/Common";
import './Description.css'
import "Colors.css";

export const Description = ({
  id = "",
  rows = 4,
  name = "",
  label = "",
  isRequired = false,
  placeholder = "",
  defaultValue = "",
  onChange,
  onBlur,
  value,
  className,
}) => {
  const [fieldValue, setFieldValue] = useState("");
  const [fieldError, setFieldError] = useState("");

  useEffect(() => {
    if (value) {
      setFieldValue(value);
    }
  }, [value]);
  return (
    <div>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium primary_black">
          {label}
        </label>
      )}
      <div className="mt-1">
        <textarea
          rows={rows}
          name={name}
          value={fieldValue}
          id={id}
          className={ClassNames(
            "shadow-sm focus:outline-none p-2 block w-full sm:text-sm border border-gray-300 rounded-md",
            className
          )}
          defaultValue={defaultValue}
          onChange={({ target }) => {
            setFieldError(false);
            setFieldValue(target.value);
            onChange?.(target.value);
          }}
          onBlur={() => {
            if (fieldValue) {
              onBlur?.(fieldValue, setFieldError);
            } else if (isRequired && !fieldValue) {
              onBlur?.("", setFieldError);
              setFieldError(`${label || placeholder} is required`);
            }
          }}
        />
      </div>
      {fieldError && (
        <div className="InputField_ErrorWrapper text-sm text-red-600">
          {fieldError}
        </div>
      )}
    </div>
  );
};
