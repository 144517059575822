import React, { useEffect, useState } from "react";
import Table from "Components/Common/Table/Table";

import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { TableHeading } from "Components/Common/Table/TableHeading";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import { DeleteWidget, WidgetList } from "Services/API/Widget.service";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { Collapse } from "Components/Common/Collapseable/Collapse";
import { ReactComponent as CopyCode } from "Assets/Icons/CopyCode.svg";
import "Colors.css";

const HEADINGS = ["Title", "Details", " "];
const TABLE_CSS = "px-6 py-4 cursor-pointer text-sm";
const widgetFormatter = (widgetData) => {
  const formatItems = (items) => {
    return items.map((item) => {
      return {
        id: item.id,
        title: item.title,
        image: item.image_url,
      };
    });
  };
  return widgetData.map((listItem) => {
    return {
      id: listItem.id,
      code: listItem.code,
      title: listItem.title,
      items: formatItems(listItem.ordered_items),
    };
  });
};

export const WidgetTable = () => {
  const [isSideView, setIsSideView] = useState(false);
  const [widgetList, setWidgetList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [widgetDetails, setWidgetDetails] = useState({
    id: "",
    code: "",
    title: "",
    items: "",
  });
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(7);
  const [deleteWidget, setDeleteWidget] = useState(false);

  const fetchWidgets = () => {
    setIsLoading(true);

    WidgetList(pageNumber, perPage)
      .then((res) => {
        if (res) {
          setWidgetList(widgetFormatter(res?.data));
          setHasNextPage(res?.pagination?.hasNext);
          setHasPreviousPage(res?.pagination?.hasPrevious);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error("[WidgetList][API_ERROR] - ", err);
        setIsLoading(false);
      });
    return () => {
      setWidgetList([]);
    };
  };
  useEffect(() => {
    fetchWidgets();
  }, [pageNumber]);
  return (
    <>
      <div
        className={`flex flex-col gap-4 ${
          isSideView ? "Table" : "Orignal_Table_Width"
        }`}
      >
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={
            widgetList.length > 0 &&
            widgetList.map((item, index) => (
              <tr
                key={index}
                className={item.hidden ? "bg-gray-100 text-gray-500" : ""}
              >
                <td className={TABLE_CSS}>
                  <div>{item.title}</div>
                </td>
                <td className={TABLE_CSS}>
                  <span
                    className="text-blue-400"
                    onClick={() => {
                      setWidgetDetails({
                        id: item.id,
                        code: item.code,
                        title: item.title,
                        items: item.items,
                      });
                      setIsSideView((x) => !x);
                    }}
                  >
                    View
                  </span>
                </td>
                <td className={`text-center TABLE_CSS`}>
                  <span
                    className="text-red-500 cursor-pointer"
                    onClick={() => {
                      setWidgetDetails({
                        id: item.id,
                      });
                      setDeleteWidget(true);
                      DeleteWidget(item.id)
                        .then((res) => {
                          if (res) {
                            fetchWidgets();
                          }
                        })
                        .catch((err) => {
                          console.error("[DeleteWidget][API_ERROR] - ", err);
                        })
                        .finally(() => {
                          setDeleteWidget(false);
                        });
                    }}
                  >
                    {deleteWidget && widgetDetails.id === item.id ? (
                      <Spinner className="h-4 w-4" />
                    ) : (
                      "Delete"
                    )}
                  </span>
                </td>
              </tr>
            ))
          }
        />
        {isLoading && <Spinner />}
        {!isLoading && widgetList.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
      </div>
      {isSideView && (
        <SideView
          showProfileImage={false}
          setIsSideView={setIsSideView}
          heading={widgetDetails.title}
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col justify gap-6 pt-4">
              <Collapse listData={widgetDetails.items} />
            </div>
            <div className="flex flex-col gap-4">
              <span className="flex items-center gap-3 text-md font-medium sapphire">
                <CopyCode className="h-4 w-4" />
                Copy Code
              </span>
              <span className="text-sm font-normal sapphire">
                {widgetDetails.code}
              </span>
            </div>
          </div>
        </SideView>
      )}
    </>
  );
};
