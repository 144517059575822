import axios from "axios";
import { API_BASE } from "Config/Secrets";
import { LOGIN } from "Constants/LocalStorage";
import { RefreshTokenReq } from "Services/API/Login.service";
const customAxios = axios.create({
  baseURL: API_BASE,
  timeout: 10000,
});

const requestHandler = (request) => {
  const { accessToken } = JSON.parse(localStorage.getItem(LOGIN));

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;

    return request;
  }
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    let code = parseInt(error.message.replace(/\D/g, ""));
    const { refreshToken } = JSON.parse(localStorage.getItem(LOGIN));
    if (code === 401) {
      const { accessToken } = JSON.parse(localStorage.getItem(LOGIN));

      if (!accessToken) {
        window.location = "/login";
        return;
      }

      RefreshTokenReq({ refresh_token: refreshToken })
        .then(({ auth }) => {
          localStorage.setItem(
            LOGIN,
            JSON.stringify({
              accessToken: auth.access_token,
              refreshToken: auth.refresh_token,
              accessTokenExpiresIn: auth.expires_in,
              refreshTokenExpiresIn: auth.refresh_expires_in,
            })
          );
        })
        .catch((err) => {
          console.error("Axios interceptor error is",err);
          window.location = "/";
        });
      return axios.request(error); // repeat last API call
    }
    return Promise.reject(error);
  }
);

export default customAxios;
