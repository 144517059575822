import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for getting the Rankpage Experts
 * @param {Number} pageNo current page number
 * @param {Number} perPage no of records to retrieve
 * @returns the list of the rankpage experts
 */
export const GetExperts = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/rankpageExpert?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetExperts][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for deleting an expert
 * @param {String} id of an expert
 * @returns
 */
export const DeleteExpert = async (id) => {
  const {
    data: { result },
  } = await Axios.delete(`${API_BASE}admin/rankpageExpert/${id}`);
  console.log(`[DeleteExpert][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for adding the expert
 * @param {Object} payload data of the expert 
 * Payload_Example :{
 * "title": "IMDB",
    "description": "All about movies",
    "image_url": "https://image.shutterstock.com/image-vector/film-reel-vector-icon-graphic-260nw-455037928.jpg"
 * }
 * @returns 
 */
export const AddExpert = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}admin/rankpageExpert/`, payload);
  console.log(`[AddExpert][API_RESPONSE]`, result);
  return result;
};
export const EditExpert = async (expert_ID,payload) => {
  const {
    data: { result },
  } = await Axios.patch(`${API_BASE}admin/rankpageExpert/${expert_ID}/`, payload);
  console.log(`[EditExpert][API_RESPONSE]`, result);
  return result;
};
