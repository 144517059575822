import { Route, Switch } from "react-router";
import { BASE_RANKPAGE_PATH, CREATE_RANKPAGE_PATH } from 'Constants/View';
import { ViewRankpages } from "Pages/Rankpages/ViewRankpages.js";
import { CreateRankpage } from "Pages/Rankpages/CreateRankpage";

export const Rankpage = () => {
    return (
        <Switch>
          <Route exact path={BASE_RANKPAGE_PATH} render={() => <ViewRankpages />} />
          <Route exact path={CREATE_RANKPAGE_PATH} render={() => <CreateRankpage />} />
        </Switch>
      );
};
