import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for getting all of the user Items
 * @param {Integer} pageNo current page number
 * @param {Integer} perPage no of records to retrieve
 * @returns all of the user added rank items
 */
export const GetUserItems = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/rankitems/users?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetUserItems][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting all of the admin Items
 * @param {Integer} pageNo current page number
 * @param {Integer} perPage no of records to retrieve
 * @returns all of the admin added rank items
 */
export const GetAdminItems = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/rankitems?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetAdminItems][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting all of the reported Items
 * @param {Integer} pageNo current page number
 * @param {Integer} perPage no of records to retrieve
 * @returns all of the reported added rank items
 */
export const GetReportedItems = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/item/reported?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetReportedItems][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for adding the new rank Item.
 * @param {Object} payload of the Rank Item details
 * Payload_Example:{
 *   "title": "Gladiator IIII",
    "description": "Movies",
    "image_url": "https://image.shutterstock.com/image-vector/film-reel-vector-icon-graphic-260nw-455037928.jpg",
    "source_url": null,
    "admin_item": true
 * }
 * @returns 
 */
export const AddRankItem = async (payload) => {
  const {
    data: { result },
  } = await Axios.post(`${API_BASE}ranklist/item`, payload);
  console.log(`[AddRankItem][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for updating a ranklist item
 * @param {String} rankItem_Id od the rank item
 * @param {Object} payload detsils of the rank item
 * Payload_Example {
 * 
	"title": "Perella Weinberg",
	"image_url": "https://rankpage-images.nyc3.digitaloceanspaces.com/1657226662993.png",
	"thumbnail": "https://rankpage-images.nyc3.digitaloceanspaces.com/1657226666461.png",
	"source_url": "wikipedia",
	"admin_item": true
 * }
 * @returns 
 */
export const EditRankItem = async (rankItem_Id, payload) => {
  const {
    data: { result },
  } = await Axios.put(`${API_BASE}admin/edit/rankitem/${rankItem_Id}`, payload);
  console.log(`[EditRankItem][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for hiding/unhiding the rank item
 * @param {String} rankItemId
 * @returns
 */
export const HideRankItem = async (rankItemId) => {
  const {
    data: { result },
  } = await Axios.patch(`${API_BASE}admin/hide/rankitem/${rankItemId}`);
  console.log(`[HideRankItem][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for deleting the rank item
 * @param {String} rankItemId
 * @returns
 */
export const DeleteRankItem = async (rankItemId) => {
  const {
    data: { result },
  } = await Axios.patch(`${API_BASE}admin/delete/rankitem/${rankItemId}`);
  console.log(`[DeleteRankItem][API_RESPONSE]`, result);
  return result;
};
