import ClassNames from "Helpers/Common";

export function EmptyDataBar({ children, className }) {
  return (
    <span
      className={ClassNames(
        "BaseEmptyDataBar h-12 w-full flex flex-wrap items-center justify-center bg-gray-700",
        className
      )}
    >
      {children}
    </span>
  );
}
