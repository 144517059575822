import ProtectedRoute from "ProtectedRoutes";
import { TokenProvider } from "Context/Global";
import {
  BASE_ADMIN_PATH,
  BASE_ANNOUNCEMENTS_PATH,
  BASE_DASHBOARD_PATH,
  BASE_EXPERTS_PATH,
  BASE_FAQS_PATH,
  BASE_LEGAL_DOCS_PATH,
  BASE_RANKITEMS_PATH,
  BASE_SEARCH_FILTER_PATH,
  BASE_USER_PROFILES_PATH,
} from "Constants/View";
import { Experts } from "Pages/Experts/Experts";
import { Widget } from "Containers/Widget/Widget";
import { Pickems } from "Containers/Pickems/Pickems";
import { Dashboard } from "Pages/Dashboard//Dashboard";
import { PickemFormCTX } from "Context/PickemCreation";
import { LoginMainPage } from "Containers/Login/Login";
import { PublicWidget } from "Pages/Widget/PublicWidget";
import { UserProfiles } from "Pages/UserProfiles/UserProfiles";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { LegalDocs } from "Pages/LegalDocs/legalDocs";
import { FAQSMainPage } from "Pages/FAQS/FAQS";
import { Rankpage } from "Containers/Rankpage/Rankpage";
import { AnnouncementMainPage } from "Containers/Announcements/Announcements";
import { RankItems } from "Pages/RankItems/RankItems";
import { BaseAdminViews } from "Containers/Admins/BaseAdminViews";
import { SearchFilter } from "Pages/SearchFilter/SearchFilter";

function App() {
  return (
    <BrowserRouter>
      <TokenProvider>
        <Switch>
          <ProtectedRoute
            path={BASE_DASHBOARD_PATH}
            component={() => <Dashboard />}
          />
          <ProtectedRoute path="/rankpage" component={() => <Rankpage />} />
          <ProtectedRoute
            path="/pickems"
            component={() => (
              <PickemFormCTX>
                <Pickems />
              </PickemFormCTX>
            )}
          />
          <ProtectedRoute path="/widget" component={() => <Widget />} />
          <ProtectedRoute
            path={BASE_USER_PROFILES_PATH}
            component={() => <UserProfiles />}
          />
          <ProtectedRoute
            path={BASE_EXPERTS_PATH}
            component={() => <Experts />}
          />
          <ProtectedRoute
            path={BASE_LEGAL_DOCS_PATH}
            component={() => <LegalDocs />}
          />
          <ProtectedRoute
            path={BASE_FAQS_PATH}
            component={() => <FAQSMainPage />}
          />
          <ProtectedRoute
            path={BASE_ANNOUNCEMENTS_PATH}
            component={() => <AnnouncementMainPage />}
          />
          <ProtectedRoute
            path={BASE_RANKITEMS_PATH}
            component={() => <RankItems />}
          />
          <ProtectedRoute
            path={BASE_ADMIN_PATH}
            component={() => <BaseAdminViews />}
          />
          <ProtectedRoute
            path={BASE_SEARCH_FILTER_PATH}
            component={() => <SearchFilter />}
          />

          <Route exact path="/poll/:id" render={() => <PublicWidget />} />
          <Route path="/" render={() => <LoginMainPage />} />
          <Redirect from="*" to="/" />
        </Switch>
      </TokenProvider>
    </BrowserRouter>
  );
}

export default App;
