import { Button } from "Components/Common/Button/Button";
import { Tabs } from "Components/Common/Tabs/Tabs";
import { useLocalStorage } from "CustomHooks/LocalStorageHook";
import { useState } from "react";
import { AdminRankItems } from "./AdminRankItems";
import { ReportedRankItems } from "./ReportedRankItems";
import { UserRankItems } from "./UserRankItems";

const USER_ITEMS = "User Items";
export const ADMIN_ITEMS = "Admin Items";
const REPORTED_ITEMS = "Reported Items";

export const RankItemsMainView = () => {
  const [activeTab, setActiveTab] = useLocalStorage(
    "rankItems_current_tab",
    USER_ITEMS
  );
  const tabs = [
    { name: "User Items" },
    { name: "Admin Items" },
    { name: "Reported Items" },
  ];
  const [addRankItem, setAddRankItem] = useState(false);
 
  const handleTabClick = (tabName) => {
    if (tabName === USER_ITEMS) {
      setActiveTab(USER_ITEMS);
    } else if (tabName === ADMIN_ITEMS) {
      setActiveTab(ADMIN_ITEMS);
    } else {
      setActiveTab(REPORTED_ITEMS);
    }
  };

  return (
    <>
      <span className="text-lg font-medium">RankItems</span>
      <div className="flex justify-between">
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onTab={(tabName) => handleTabClick(tabName)}
        />
        <Button
          btnText="Create an Item"
          className="w-36 h-8 bg-blue-400 text-white"
          isDisabled={false}
          isLoading={false}
          onClick={() => {
            setAddRankItem(true);
          }}
        />
      </div>
      <Divider activeTab={activeTab} />
      <br />
      {activeTab === USER_ITEMS ? (
        <UserRankItems
          addRankItem={addRankItem}
          setAddRankItem={setAddRankItem}
          setActiveTab={setActiveTab}
        />
      ) : activeTab === ADMIN_ITEMS ? (
        <AdminRankItems
          addRankItem={addRankItem}
          setAddRankItem={setAddRankItem}
        />
      ) : (
        <ReportedRankItems
          addRankItem={addRankItem}
          setAddRankItem={setAddRankItem}
          setActiveTab={setActiveTab}
        />
      )}
    </>
  );
};
const Divider = () => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full  border-t border-coolGray" />
      </div>
    </div>
  );
};
