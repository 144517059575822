import React, { useState, useEffect } from "react";
import ClassNames from "Helpers/Common";
import "./InputField.css";

export const InputField = ({
  id,
  label,
  type = "text",
  placeholder = "",
  isRequired = false,
  maxLength,
  onBlur,
  isDisabled = false,
  onChange,
  value,
  children,
}) => {
  const [fieldValue, setFieldValue] = useState("");
  const [fieldError, setFieldError] = useState("");

  useEffect(() => {
    if (value) {
      setFieldValue(value);
    }
  }, [value]);

  return (
    <div className="InputField_MainWrapper">
      {label && (
        <label htmlFor={id} className="sapphire block text-sm font-semibold">
          {label}
        </label>
      )}
      <div
        className={ClassNames(
          "InputField_FieldWrapper border rounded-md",
          fieldError
            ? "border-red-500 focus:ring-red-500 focus:border-red-500"
            : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
        )}
      >
        <input
          id={id}
          name={id}
          type={type}
          value={fieldValue}
          placeholder={placeholder}
          maxLength={maxLength}
          required={isRequired}
          disabled={isDisabled}
          className={
            "appearance-none block w-full placeholder-gray-400 focus:outline-none sm:text-sm"
          }
          onChange={({ target }) => {
            setFieldError(false);
            setFieldValue(target.value);
            onChange?.(target.value);
          }}
          onBlur={() => {
            if (fieldValue) {
              onBlur?.(fieldValue, setFieldError);
            } else if (isRequired && !fieldValue) {
              onBlur?.("", setFieldError);
              setFieldError(`${label || placeholder} is required`);
            }
          }}
        />
        {children}

      </div>
      {fieldError && (
        <div className="InputField_ErrorWrapper text-sm text-red-600">
          {fieldError}
        </div>
      )}
    </div>
  );
};
