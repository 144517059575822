import React, { useEffect, useState } from "react";
import { TableHeading } from "./TableHeading";
import Table from "Components/Common/Table/Table";
import { Button } from "Components/Common/Button/Button";
import { GetReportedUsers } from "Services/API/Users.service";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { SideView } from "Components/Common/SidePannel/SidePannel";
import { PaginationBtns } from "Components/Common/Button/PaginationBtns";
import "./Profile.css";
import { formatDate } from "Helpers/DateFormaater";

const HEADINGS = [
  "Name",
  "Username",
  "Reported By",
  "Phone Number",
  "Date of Birth",
  "Gender",
  " ",
];
const reportedUsersFormatter = (data) => {
  return data.map((reportedUsers) => {
    return {
      id: reportedUsers.id,
      email: reportedUsers.email,
      gender: reportedUsers.gender,
      DOB: formatDate(reportedUsers.date_of_birth, "MM/DD/YYYY") || "",
      userName: reportedUsers.username?.replaceAll("null", ""),
      userImage: reportedUsers.profile_image_url,
      name: (
        reportedUsers.first_name +
        " " +
        reportedUsers.last_name
      )?.replaceAll("null", ""),
      reportedBy: {
        id: reportedUsers.reported_by.id,
        name: reportedUsers.reported_by.username?.replaceAll("null", ""),
        email: reportedUsers.reported_by.email,
      },
      phoneNumber:reportedUsers?.phone||""
    };
  });
};

export const ReportedUsers = () => {
  const [isSideView, setIsSideView] = useState(false);
  const [reportedUsers, setReportedUsers] = useState([]);
  const [selectedUserDetails, setSelectedUserDetails] = useState({
    name: "",
    email: "",
    userName: "",
    reportedBy: "",
    userImage: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(7);

  useEffect(() => {
    setIsLoading(true);
    GetReportedUsers(pageNumber, perPage)
      .then((res) => {
        setReportedUsers(reportedUsersFormatter(res.data));
        setHasNextPage(res?.pagination?.hasNext);
        setHasPreviousPage(res?.pagination?.hasPrevious);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("[GetReportedUsers][API_ERROR] - ", err);
        setIsLoading(false);
      });
    return () => {
      setReportedUsers([]);
    };
  }, [pageNumber]);
  return (
    <>
      <div
        className={`flex flex-col gap-4 ${
          isSideView ? "Table" : "Orignal_Table_Width"
        }`}
      >
        <Table
          heading={<TableHeading TableHeader={HEADINGS} />}
          body={
            reportedUsers?.length > 0 &&
            reportedUsers.map((user, index) => (
              <tr key={index}>
                <td className="px-6 py-4 cursor-pointer">
                  <div className="text-sm bali_hai font-bold">
                    {user.name}
                  </div>
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  <div className="text-sm text-gray-secondary">{user.userName}</div>
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  <div className="text-sm text-gray-secondary">{user.reportedBy.name}</div>
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  <div className="text-sm text-gray-secondary">{user.phoneNumber}</div>
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  <div className="text-sm text-gray-secondary">{user.DOB}</div>
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  <div className="text-sm text-gray-secondary">{user.gender}</div>
                </td>
                <td className="px-4 py-4 text-left text-sm font-medium ">
                  <span
                    onClick={() => {
                      setSelectedUserDetails({
                        name: user.name,
                        email: user.email,
                        reportedBy: user.reportedBy.name,
                        userName: user.userName,
                        userImage: user.userImage,
                      });
                      setIsSideView((x) => !x);
                    }}
                    className="cursor-pointer text-blue-dark"
                  >
                    {isSideView ? "" : "View"}
                  </span>
                </td>
              </tr>
            ))
          }
        />
        {!isLoading &&reportedUsers?.length <= 0 &&<div className="flex bg-white w-full justify-center items-center h-96">No Reported Rankpages found at the moment</div>}

        {isLoading && <Spinner />}
        {!isLoading && reportedUsers.length > 0 && (
          <PaginationBtns
            isNextDisabled={!hasNextPage}
            isPrevDisabled={!hasPreviousPage}
            onNext={() => {
              if (hasNextPage) {
                setPageNumber(pageNumber + 1);
              }
            }}
            onPrevious={() => {
              if (hasPreviousPage) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
        )}
      </div>
      {isSideView && (
        <SideView
          setIsSideView={setIsSideView}
          heading={selectedUserDetails.name}
          subHeading={selectedUserDetails.email}
          profileImage={selectedUserDetails.userImage}
        >
          <div className="flex flex-col justify gap-6 pt-4">
            <div className="grid grid-cols-2 gap-5">
              <div className="text-gray-400">
                UserName{" "}
                <div className="text-black mt-1">
                  {selectedUserDetails.userName}
                </div>
              </div>
              <div className="text-gray-400">
                Reported By{" "}
                <div className="text-black mt-1">
                  {selectedUserDetails.reportedBy}
                </div>
              </div>
            </div>
            <div className="text-gray-400">
              Block User
              <div className="text-black mt-1">
                User will not be able to login into their account if they are
                blocked.
              </div>
            </div>
            <Button
              btnText="Block"
              className="w-24 py-2 bg-red-400 text-white"
              isDisabled={false}
              isLoading={false}
              onClick={() => {}}
            />
          </div>
        </SideView>
      )}
    </>
  );
};
