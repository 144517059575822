import  { useState, useContext, createContext } from "react";
import { FORGOT_PASSWORD, LOGIN } from "Constants/LocalStorage";
import { useHistory } from "react-router";

const GlobalContext = createContext();
export const INIT_TOKEN = {
  login: "",
  accessToken: "",
  refreshToken: "",
  accessTokenExpiresIn: "",
  refreshTokenExpiresIn: "",
  role:""
};
const INIT_FORGOT_PASSWORD = {
  otp: "",
  resetToken: "",
};
const TokenProvider = ({ children }) => {
  let history = useHistory();
  const [tokenDetails, setToken] = useState(INIT_TOKEN);
  const [forgotPassword, setForgotPassword] = useState(INIT_FORGOT_PASSWORD);
  
  const setActiveView = (view) => {
    history.push(view);
  };
  const setTokenDetails = ({
    login=true,
    accessToken,
    refreshToken,
    accessTokenExpiresIn,
    refreshTokenExpiresIn,
    role
  }) => {
    const token = {
      login: login,
      accessToken: accessToken,
      refreshToken: refreshToken,
      accessTokenExpiresIn: accessTokenExpiresIn,
      refreshTokenExpiresIn: refreshTokenExpiresIn,
      role:role
    };
    localStorage.setItem(LOGIN, JSON.stringify(token));
    setToken(token);
  };
  const getToken = () => {
    const token = JSON.parse(localStorage.getItem(LOGIN));
    if (token) {
      return token.accessToken;
    }
    if (tokenDetails) {
      return tokenDetails.idToken;
    }
    return;
  };
  const getAdminRole = () => {
    const token = JSON.parse(localStorage.getItem(LOGIN));
    if (token) {
      return token.role;
    }
    if (tokenDetails) {
      return tokenDetails.role;
    }
    return;
  };
  const resetCredentials = () => {
    setToken(INIT_TOKEN);
    localStorage.setItem(LOGIN, JSON.stringify(INIT_TOKEN));
  };
  const setForgotPasswordDetails = ({ otp, resetToken }) => {
    const forgotPasswordDetails = {
      otp,
      resetToken,
    };
    localStorage.setItem(
      FORGOT_PASSWORD,
      JSON.stringify(forgotPasswordDetails)
    );
    setForgotPassword(otp, resetToken);
  };
  const getForgotPasswordDetails = () => {
    const forgotPasswordDetails = JSON.parse(
      localStorage.getItem(FORGOT_PASSWORD)
    );
    if (forgotPassword.resetToken) {
      return forgotPassword;
    }
    if (forgotPasswordDetails) {
      return forgotPasswordDetails;
    }
    return;
  };
  const resetForgotPasswordDetails = () => {
    localStorage.setItem(FORGOT_PASSWORD, JSON.stringify(INIT_FORGOT_PASSWORD));
    setForgotPassword(INIT_FORGOT_PASSWORD);
  };
  const ForgotPasswordDetials = getForgotPasswordDetails();
  return (
    <GlobalContext.Provider
      value={{
        getToken,
        getAdminRole,
        setTokenDetails,
        resetCredentials,
        setActiveView,
        setForgotPasswordDetails,
        ForgotPasswordDetials,
        resetForgotPasswordDetails,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export { GlobalContext, TokenProvider };
