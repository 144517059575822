import { getLocalStorage } from "Components/Common/LocalStorage/LocalStoarage";
import { Dividers } from "Components/PicekmCreation/Dividers";
import { ReactComponent as AppLogo } from "Assets/Icons/WidgetLogo.svg";
import { ReactComponent as DefaultImage } from "Assets/Icons/Profile.svg";
import { Button } from "Components/Common/Button/Button";
import "./Widget.css";

export const Preview = () => {
  const widgetData = getLocalStorage("CREATE_WIDGET");
  return (
    <div className="flex flex-col gap-3 h-96">
      {" "}
      <Dividers text="Code Snippet" />
      <span>{widgetData.code}</span>
      <Dividers text="Widget Preview" />
      <div class="h-96 w-96 border-2 border-gray-200 rounded-lg overflow-y-auto">
        <div className="flex items-center justify-between px-4 p-2 bg_solitude">
          <div className="flex flex-col gap-1">
            {" "}
            <span className="sapphire text-base font-medium">
              {widgetData.title}
            </span>
            <span className="raven text-sm font-normal">
              Drag items to reorder
            </span>
          </div>
          <AppLogo className="h-8 w-8 " />
        </div>
        <div className="flex flex-col gap-3 px-2 p-2">
          {widgetData.ordered_items.map((item, index) => (
            <div
              key={index}
              className="flex w-80 bg-white border border-gray-200 shadow-md rounded-md py-2 px-2 items-center gap-3"
            >
              <span>{index + 1}</span>
              <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                {item.image_url ? (
                  <img src={item.image_url} />
                ) : (
                  <DefaultImage />
                )}
              </span>
              <span className="truncate text-xs font-medium">{item.title}</span>
            </div>
          ))}
          <Button
            btnText="Submit"
            className={`w-80 py-2 rounded-2xl bg-blue-400 text-white`}
            isDisabled={false}
            isLoading={false}
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};
