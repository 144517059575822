import { useEffect, useState } from "react";
import Autocomplete from "react-autocomplete";
import { RankedItem } from "../RankedItem/RankedItem";
import "./Ap.css";

function AutoComplete(props) {
  const [placeHolder, setPlaceHolder] = useState("Search");
  const [searchList, setSearchList] = useState([]);
  const themes = "light";

  useEffect(() => {
    // This useEffect is to remove the duplicate suggestions from the list
    const ids = props.list.map((o) => o.id);
    setSearchList(
      props.list.filter(({ id }, index) => !ids.includes(id, index + 1)),
    );
  }, [props.list]);
  return (
    <div className="auto" style={{ width: "100%" }}>
      <Autocomplete
        items={searchList ?? "No results found"}
        getItemValue={(item) => item.title}
        shouldItemRender={(item, value) =>
          item.title.toLowerCase().indexOf(value.toLowerCase()) > -1
        }
        renderItem={(item, isHighlighted) => {
          if (isHighlighted) {
            setPlaceHolder(item.title);
          }
          return (
            <RankedItem
              key={item.id}
              title={item.title}
              imgUrl={item.image_url}
              className={"AutoComplete"}
              imageShape="circle"
            />
          );
        }}
        value={props.value}
        onChange={props.onChange}
        onSelect={props.onSelect}
        inputProps={{ placeholder: placeHolder }}
        isItemSelectable={() => true}
        menuStyle={{
          position: "absolute",
          overflow: "auto",
          height: "370px",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
          borderRadius: "3px",
          boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
          background: `${themes === "dark" ? "rgb(17,24,39)" : "white"}`,
          zIndex: "1000",
          cursor: "pointer",
          width: "40rem",
        }}
      />
    </div>
  );
}

export default AutoComplete;
