import { Spinner } from "Components/Common/LoadingSpinner/Spinner";

/**
 * A self made wrapper around rendering component inspired by react suspense
 * to minimize and simplify the rendering of loader animation and actual content
 *
 * Note: Do not confuse this with the <Suspense /> component provided by react
 * @param {Object} props {
 *  isLoading: boolean,
 *  children: some jsx
 * }
 * @returns JSX Component
 */
export function CustomSuspense({ isLoading, children }) {
  if (isLoading) {
    return <Spinner />;
  } else return children;
}
