import Axios from "Services/Auth/AxiosInterceptors";
import { API_BASE } from "Config/Secrets";

/**
 * @description  An API for getting all of the users
 * @param {Number} pageNo : Current page no of the pagination
 * @returns list of the users
 */
export const GetUsers = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/user/all?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetUsers][API_RESPONSE]`, result);
  return result;
};
/**
 * @description  An API for getting the reported users
 * @param {Number} pageNo : Current page no of the pagination
 * @returns list of the reported users
 */
export const GetReportedUsers = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/user/reported?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetReportedUsers][API_RESPONSE]`, result);
  return result;
};

export const GetFeedback = async (pageNo = 1, perPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `${API_BASE}admin/user/feedback?page=${pageNo}&perPage=${perPage}`
  );
  console.log(`[GetFeedback][API_RESPONSE]`, result);
  return result;
};
