import React from "react";
import { AppLayout } from "Layouts/AppLayout/AppLayout";
import { Dashboard as MainPage } from "Components/Dashboard/Dashboard";
export const Dashboard = () => {
  return (
    <AppLayout>
     <MainPage />
    </AppLayout>
  );
};
