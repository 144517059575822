import { ReactComponent as XIcon } from "Assets/Icons/Cross.svg";
import { Button } from "Components/Common/Button/Button";
import ModalBasic from "Components/Common/ModalBasic";
import ClassNames from "Helpers/Common";
import { SelectInterests } from "../Interests/SelectInterests";
import "./TheAddInterestsModal.scss";

export const TheAddInterestsModal = ({
  isOpen,
  selectedInterests,
  clearInitialSelection,
  onSelect,
  onClose,
  onSubmit,
}) => {
  return (
    <ModalBasic
      open={isOpen}
      setOpen={onClose}
      className="TheAddInterestsModal"
    >
      <div className="ModalHeading">
        <XIcon className="h-6 w-6 dark:text-gray-400" aria-hidden="true" onClick={()=>onClose?.()}/>
        <span>Add Interests</span>
      </div>
      <div className="ModalBody">
        <SelectInterests
          clearInitialSelection={clearInitialSelection}
          interests={selectedInterests}
          onSelectInterest={(newInterest) => {
            const isDuplicate = selectedInterests
              .map((selectedInterest) => selectedInterest.id)
              .includes(newInterest.id);
            if (!isDuplicate) {
              onSelect([...selectedInterests, newInterest]);
            }
          }}
          onChange={({ mainInterest, subInterest: newSubInterest }) => {
            const updatedInterests = selectedInterests.map((interest) => {
              //Find the selected category
              if (interest.id === mainInterest.id) {
                //Add selected prop to it
                interest.subInterests =
                  interest.subInterests.length > 0 &&
                  interest.subInterests.map((subInterest) => {
                    return {
                      ...subInterest,
                      // if subcategory is not selected, selected prop is false
                      selected:
                        subInterest.id === newSubInterest.id
                          ? !subInterest.selected
                          : subInterest.selected,
                    };
                  });
              }
              return interest;
            });
            onSelect(updatedInterests);
          }}
        />
      </div>
      <div className="ModalFooter">
        <Button
          btnText="Continue"
          onClick={() => onSubmit()}
          isDisabled={Object.keys(selectedInterests).length === 0}
          className={ClassNames(
            "chooseInterest_ContinueBtn w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none",
            Object.keys(selectedInterests).length === 0
              ? "bg-gray-300 text-gray-400 border  border-gray-300"
              : "text-white bg-blue-dark "
          )}
        />
      </div>
    </ModalBasic>
  );
};
