import React from "react";
import { RankpagesMain } from "Components/Rankpages/RankpageMain";
import { AppLayout } from "Layouts/AppLayout/AppLayout";

export const ViewRankpages = () => {
  return (
    <div>
      <AppLayout>
        <RankpagesMain />
      </AppLayout>
    </div>
  );
};
