import { useEffect, useState } from "react";
import { Footer } from "./Footer";
import { HEADING, BTN_TEXT } from "./Common";
import { useGlobalContext } from "Context/Global";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { SendLogInReq } from "Services/API/Login.service";
import { InputField } from "Components/Common/InputField/InputField";
import { BASE_DASHBOARD_PATH, BASE_FORGOT_PASSWORD_PATH } from "Constants/View";
import "./Login.css";

const tokenFormatter = (token) => {
  const formattedTokenesult = {
    accessToken: token.access_token,
    refreshToken: token.refresh_token,
    accessTokenExpiresIn: token.expires_in,
    refreshTokenExpiresIn: token.refresh_expires_in,
    role: token?.user_data?.role,
  };

  return formattedTokenesult;
};
export const Login = () => {
  const { setActiveView, setTokenDetails } = useGlobalContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  useEffect(() => {
    setIsDisabled(!(email && password));
  }, [email, password]);
  const handleSubmit = () => {
    if (email && password) {
      setIsLoading(true);
      SendLogInReq({
        username: email,
        password: password,
        device: "Web",
      })
        .then((res) => {
          setIsLoading(false);
          setTokenDetails(tokenFormatter(res));
          setActiveView(BASE_DASHBOARD_PATH);
        })
        .catch((err) => {
          setIsDisabled(true);
          setIsLoading(false);
          setApiError(err?.response?.data?.errors || err?.message);
          console.error(
            "[SendLogInReq][API_ERROR]  - ",
            err?.response?.data?.errors || err?.message
          );
        });
    }
  };
  return (
    <div className="Login_Card flex flex-col gap-3">
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      <div className="border-2 border-gray-200 rounded-md flex flex-col gap-4 2xl:gap-12">
        <div className="Login_Content  flex flex-col gap-6 p-6">
          <span className={`flex justify-center ${HEADING}`}>Sign In</span>
          <div className="lg:space-y-4 xl:space-y-6 2xl:space-y-8 2xl:mt-7 ">
            <InputField
              id="email"
              type="email"
              placeholder="Email"
              isRequired={true}
              onChange={(fieldValue) => setEmail(fieldValue.trim())}
              onBlur={(fieldValue, setInputFieldError) => {
                if (
                  !(fieldValue.includes("@") && fieldValue.includes(".com"))
                ) {
                  setEmail("");
                  setInputFieldError("EMAIL_INVALID");
                  setIsDisabled(true);
                } else if (fieldValue) {
                  setEmail(fieldValue.trim());
                }
              }}
            />

            <InputField
              id="password"
              placeholder="Password"
              type="password"
              isRequired={true}
              onChange={(fieldValue) => {
                setPassword(fieldValue);
              }}
            />

            <span className="flex items-center justify-end">
              <span
                onClick={() => {
                  setActiveView(BASE_FORGOT_PASSWORD_PATH);
                }}
                className="text-xs font-normal text-gray-400 hover:text-indigo-500 cursor-pointer"
              >
                Forgot password?
              </span>
            </span>
            <Button
              btnText="Sign In"
              className={`w-full py-2  ${BTN_TEXT} ${
                !isDisabled ? "bg-blue-400 text-white" : ""
              }`}
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={() => handleSubmit()}
            />
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};
